import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row,Spinner, UncontrolledTooltip } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory,
{ PaginationProvider, SizePerPageDropdownStandalone, PaginationTotalStandalone, PaginationListStandalone }
  from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { useDispatch, useSelector } from "react-redux";

import { getList } from './service.js'
import { Helmet } from "react-helmet";

const { SearchBar } = Search;

const PartnerList = () => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [loading,setLoading]  = useState(false)

  let history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((s) => s.authentication.user)

  useEffect(() => {
    loadTable()
  }, [])

  const loadTable = () => {
    setLoading(true)
    getList(dispatch).then(setRowData).finally(()=>setLoading(false))
  }

  const handleOnSelect = (row, isSelect) => {
    const newSelection = isSelect ?
      [...selectedRow, row.PartnerId] : selectedRow.filter(x => x !== row.PartnerId)
    setSelectedRow(newSelection)
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.PartnerId);
    isSelect ? setSelectedRow(ids) : setSelectedRow([])
  }

  const handlePartnerDelete = () => {
    console.log("delete entries", selectedRow)
  }

/*   const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/partners/edit_partner/${row.PartnerId}`)
    },
  };*/

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedRow,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const columns = [{
    dataField: 'PartnerId',
    text: 'ID',
    sort: true,
    searchable: true,
    headerStyle: { width: '10%' },
  }, {
    dataField: 'EntityName',
    text: 'Partner Name',
    sort: true,
    classes: 'common-table-column-style',
    headerStyle: { width: '20%' },
    searchable: true
  }, {
    dataField: 'FirstName',
    text: 'First Name',
    sort: true,
    classes: 'common-table-column-style',
    headerStyle: { width: '15%' },
    searchable: true
  }, {
    dataField: 'LastName',
    text: 'Last Name',
    sort: true,
    classes: 'common-table-column-style',
    headerStyle: { width: '15%' },
    searchable: true
  }, {
    dataField: 'Email',
    sort: true,
    text: 'Contact Email',
    classes: 'common-table-column-style',
    headerStyle: { width: '20%' },
    searchable: true
  },
  {
    sort: false,
    text: 'Actions',
    classes: 'common-table-column-style',
    searchable: false,
    headerStyle: { width: "20%" },
    formatter: function (cell, row) {
      return (
        <div style={{ display: "flex", width: "100%", opacity: "0.8" }}>
          <Button
            outline
            className="common-btn-outlined"
            color="primary"
            type="button"
            style={{ maxWidth: "30%" }}
            id="editPartner"
            onClick={() =>
              history.push(`/partners/edit_partner/${row.PartnerId}`)
            }
          >
            <i class="fas fa-edit" />
            <UncontrolledTooltip target="editPartner" autohide={false}>
              Edit Partner
            </UncontrolledTooltip>
          </Button>

          <Button
            outline
            className="common-btn-outlined"
            color="primary"
            type="button"
            id="viewCustomer"
            style={{ maxWidth: "30%" }}
            onClick={() =>
              history.push("/partners/list_customers/" + row.PartnerId)
            }
          >
            <i class="fa fa-users" aria-hidden="true" />
            <UncontrolledTooltip target="viewCustomer" autohide={false}>
              View Customer
            </UncontrolledTooltip>
          </Button>
          {user.role === "admin" && (
            <Button
              outline
              className="common-btn-outlined"
              color="primary"
              type="button"
              style={{ maxWidth: "30%" }}
              id="viewPartner"
              onClick={() =>
                history.push(`/Endpoints/ListFromPartner/${row.PartnerId}`)
              }
            >
              <i class="fa fa-laptop" aria-hidden="true" />
              <UncontrolledTooltip target="viewPartner" autohide={false}>
                View Endpoints
              </UncontrolledTooltip>
            </Button>
          )}
        </div>
      );
    }
  }];

  const paginationOptions = {
    custom: true,
    withFirstAndLast: true,
    totalSize: rowData.length
  };

  return (
    <Container className="container-main" fluid>
      <div className="common-headers">Partners</div>
      <Helmet>
      <title>
        {"Partners"}
      </title>
    </Helmet>
      <Row>
        <div className="col">
            <ToolkitProvider
              keyField="PartnerId"
              data={rowData}
              columns={columns}
              search
            >
              {props => (
                <PaginationProvider
                  pagination={paginationFactory(paginationOptions)}
                >
                  {
                    ({
                      paginationProps,
                      paginationTableProps
                    }) => (
                      <div>
                        <Row style={{ marginBottom: 6 }}>
                          <Col xs="5" className="local-text" style={{display:'flex', alignItems:'center'}}>
                            Entries per page :
                            <SizePerPageDropdownStandalone
                              className="common-page-size-selector ml-2"
                              {...paginationProps}
                            />
                            {selectedRow.length > 0 &&
                              <Button
                                className="common-btn-delete"
                                onClick={handlePartnerDelete}
                                outline
                              >
                                <i className="far fa-trash-alt" />
                              </Button>
                            }
                          </Col>
                          <Col className="d-flex justify-content-end">
                            <SearchBar
                              className="table-search-input"
                              {...props.searchProps}
                            />
                            <Button
                              color="primary"
                              className="ml-2 common-btn"
                              onClick={() => history.push("/partners/add_partner")}
                            >
                              Add New Partner
                            </Button>
                          </Col>
                        </Row>
                        <BootstrapTable
                          hover
                          bordered={false}
                          //rowEvents={rowEvents}
                          noDataIndication={loading ? <Spinner type="grow" color="primary" /> : "No Data"}
                          headerWrapperClasses="table-header-light"
                          bodyClasses="clickable-table-row"
                          {...props.baseProps}
                          {...paginationTableProps}
                        />
                        <Row style={{marginTop:10}}>
                          <Col>
                            <PaginationTotalStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col>
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                </PaginationProvider>
              )}
            </ToolkitProvider>
        </div>
      </Row>
    </Container>
  )
}

export default PartnerList;