import React from 'react'
import { Button } from 'reactstrap'

export default function TButton({ isLoading = false, ...props }) {

  return (
    <Button {...props}>
      {isLoading && <span className="spinner-border spinner-border-sm" style={{ marginRight: 5 }} />} {props.children}
    </Button>
  )
}