import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  Container
} from "reactstrap";
import { alertActions } from "redux/_actions";
import PartnerForm from "./form";

import { addPartner } from './service'

const PartnerAdd = () => {
  let history = useHistory();
  const dispatch = useDispatch();

  const savePartner = async (partnerData) => {
    const r = await addPartner(partnerData, dispatch)
    if (r) {
      history.push('/partners')
    }
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.push('/partners')}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Add Partner
        </div>
        <Row>
          <Col xs="5">
            <Card className="shadow">
              <CardBody>
                <PartnerForm shareFormData={savePartner} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default PartnerAdd;