

export const getSideBarClass = (theme) => {
    return theme === 'dark' ? 'navbar-dark bg-dark' : 'navbar-light bg-white' 
}

const getGlobalStyles = (component) => {
  const styling = component === 'sidebar' ? getSideBarClass() : ''
  return styling;
}

export default getGlobalStyles