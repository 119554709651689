import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

export default function Landing() {
  const user = useSelector(store => store.authentication.user) || {}
  const { role } = user;
  switch (role) {
    case 'admin': return <Redirect to = "/partners" />
    case 'parter': return <Redirect to = "/customers" />
    default: return <Redirect to = "/endpoints" />
  }
}