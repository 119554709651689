import { useState } from "react";
import validator from "validator";
import AlertMessage from "components/Alert";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import WebAPI from 'services/WebAPI'
import { Helmet } from "react-helmet";

import {
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback
} from "reactstrap";
import Button from "components/Inputs/TButton";
import { useDispatch } from "react-redux";
import { alertActions } from "redux/_actions";

export default function ChangePassword() {

  const dispatch = useDispatch()

  const [formState, setFormState] = useState({
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  })

  const [isLoading, setIsLoading] = useState(false)

  const [invalidFormState, setInvalidFormState] = useState({
    OldPassword: false,
    NewPassword: false,
    ConfirmPassword: false,
  })

  const handleFormValueChange = ({ target: { id, value } }) => {
    invalidFormState[id] = false
    formState[id] = value
    setFormState({ ...formState })
    setInvalidFormState({ ...invalidFormState })
  }

  const handleSave = (e) => {
    e.preventDefault()
    let flag = false
    Object.entries(formState).forEach(([a, b]) => {
      if (validator.isEmpty(b)) {
        flag = true
        invalidFormState[a] = true
        setInvalidFormState({ ...invalidFormState })
      }
    })

    if (formState['ConfirmPassword'] !== formState['NewPassword']) {
      flag = true
      invalidFormState['ConfirmPassword'] = true
      setInvalidFormState({ ...invalidFormState })
    }

    if (!flag) {
      setIsLoading(true)
      WebAPI
        .post('Preferences/ChangePassword', formState)
        .then(result => {
          const { status, message } = result.data
          if (status)
            dispatch(alertActions.success(message))
          else
            dispatch(alertActions.error(message))
        })
        .catch(() => dispatch(alertActions.error('Error sending request.')))
        .finally(() => setIsLoading(false))
    }

  }

  return (
    <Container className="container-main container-fluid" style={{ marginLeft: 0 }}>
      <div className="common-headers">
        Change Password
      </div>
      <Helmet>
      <title>
        {"Change Password"}
      </title>
    </Helmet>
      <Row>
        <Col md={6}>
          <Card className="shadow">
            <CardBody>
              <AlertMessage />
              <Form role="form" onSubmit={handleSave}>
                <FormGroup>
                  <Label for="OldPassword">Old Password</Label>
                  <Input
                    id="OldPassword"
                    value={formState.OldPassword}
                    invalid={invalidFormState.OldPassword}
                    onChange={handleFormValueChange}
                    type="password"
                  />
                  <FormFeedback>Please enter a current password</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="NewPassword">New Passsword</Label>
                  <Input
                    id="NewPassword"
                    value={formState.NewPassword}
                    invalid={invalidFormState.NewPassword}
                    onChange={handleFormValueChange}
                    type="password"
                  />
                  <FormFeedback>Please enter a password</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Label for="ConfirmPassword">Confirm Password</Label>
                  <Input
                    id="ConfirmPassword"
                    value={formState.ConfirmPassword}
                    invalid={invalidFormState.ConfirmPassword}
                    onChange={handleFormValueChange}
                    type="password"
                  />
                  <FormFeedback>Password not matching</FormFeedback>
                </FormGroup>
                <FormGroup>
                  <Button type="submit" color="primary" isLoading={isLoading}>Change</Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )

}