import { useSelector } from "react-redux";
import WebAPI from 'services/WebAPI'
import { Container, Row, Col, Card, CardBody, Button, ButtonGroup } from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";


const SettingsBlock = styled.div`
box-sizing: border-box;
border-radius: 3px;
border:1px solid ${props => props.theme === 'dark' ? '#4E5054' : '#E8E8E8'};
background-color: ${props => props.theme === 'dark' ? '#2F3032' : '#FFFFFF'};
height:80px;
margin-bottom:10px;
`

const Settings = ({ button, iconSvg, title, subtitle }) => {
  const { theme = 'white' } = useSelector(store => store.authentication.user)
  return (
    <SettingsBlock theme={theme}>
      <div style={{ width: 60, height: 60, borderRadius: 6, background: theme === 'dark' ? 'rgba(30, 141, 204, 0.2)' : '#DFF3FF', margin: 10, padding: 13, display: 'inline-block' }}>
        {iconSvg}
      </div>
      <div style={{ display: 'inline-block' }}>
        <div style={{ position: "absolute" }}>
          <span style={{ color: theme==='dark'?'#1E8DCC':'#2D6DA4', fontWeight: 'bold', fontSize: '.8rem', display: 'block', marginTop: -20 }}>{title}</span>
          <span style={{ color: theme==='dark'?'#ffffff':'#777777', fontWeight: 'normal', fontSize: '.8rem', display: 'block' }}>{subtitle}</span>
        </div>
      </div>
      <div style={{ display: 'inline-block', float: "right", margin: 18 }}>
        {button}
      </div>
    </SettingsBlock>
  )
}

const ModeSwitch = () => {


  const { theme = 'white' } = useSelector(store => store.authentication.user)

  const changeTheme = (theme) => {
    WebAPI
      .post('Preferences/Theme', { Theme: theme })
      .then(() => window.location.reload())
      .catch(() => console.log('error'))
  }

  return (
    <ButtonGroup>
      <Button color="primary" outline={theme !== 'dark'} onClick={() => changeTheme("dark")} >Dark Mode</Button>
      <Button color="primary" outline={theme !== 'white'} onClick={() => changeTheme("white")} >White Light</Button>
    </ButtonGroup>
  )

}

export default function Preferences() {

  
  const history = useHistory()
  const { theme = 'white', role } = useSelector(store => store.authentication.user)
  return (
    <Container className="container-main container-fluid" style={{ marginLeft: 0 }}>
      <div className="common-headers">
        Preferences
      </div>
      <Helmet>
        <title>
          {"Preferences"}
        </title>
      </Helmet>
      <Row>
        <Col md={8}>
          <Card className=" shadow">
            <CardBody>
              <Settings
                button={<Button color="primary" onClick={() => history.push('/preferences/change_2FA')}>Configure</Button>}
                iconSvg={(
                  <svg width="33" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M31.3664 20.9643L28.5375 19.3308C28.823 17.7901 28.823 16.2097 28.5375 14.669L31.3664 13.0354C31.6918 12.8495 31.8379 12.4643 31.7316 12.1058C30.9945 9.74169 29.7394 7.60341 28.0992 5.82372C27.8469 5.55145 27.4351 5.48505 27.1164 5.67099L24.2875 7.30458C23.0988 6.28192 21.7308 5.49169 20.25 4.97372V1.71317C20.25 1.3413 19.991 1.01591 19.6258 0.936219C17.1887 0.391688 14.6918 0.418251 12.3742 0.936219C12.009 1.01591 11.75 1.3413 11.75 1.71317V4.98036C10.2758 5.50497 8.9078 6.2952 7.71249 7.31122L4.89023 5.67763C4.56484 5.49169 4.15976 5.55145 3.90742 5.83036C2.26718 7.60341 1.01211 9.74169 0.274997 12.1124C0.162107 12.471 0.314841 12.8561 0.640232 13.0421L3.46914 14.6757C3.18359 16.2163 3.18359 17.7968 3.46914 19.3374L0.640232 20.971C0.314841 21.1569 0.168747 21.5421 0.274997 21.9007C1.01211 24.2647 2.26718 26.403 3.90742 28.1827C4.15976 28.455 4.57148 28.5214 4.89023 28.3354L7.71913 26.7018C8.90781 27.7245 10.2758 28.5147 11.7566 29.0327V32.2999C11.7566 32.6718 12.0156 32.9972 12.3809 33.0768C14.818 33.6214 17.3148 33.5948 19.6324 33.0768C19.9976 32.9972 20.2566 32.6718 20.2566 32.2999V29.0327C21.7308 28.5081 23.0988 27.7179 24.2941 26.7018L27.123 28.3354C27.4484 28.5214 27.8535 28.4616 28.1058 28.1827C29.7461 26.4097 31.0012 24.2714 31.7383 21.9007C31.8379 21.5354 31.6918 21.1503 31.3664 20.9643V20.9643ZM16 22.3124C13.0715 22.3124 10.6875 19.9284 10.6875 16.9999C10.6875 14.0714 13.0715 11.6874 16 11.6874C18.9285 11.6874 21.3125 14.0714 21.3125 16.9999C21.3125 19.9284 18.9285 22.3124 16 22.3124Z" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"} />
                  </svg>
                )}
                title="Two Factor Authentication"
                subtitle="Configure authenticator app"
              />
              <Settings
                button={<Button color="primary" onClick={() => history.push('/preferences/change_password')}>Change</Button>}
                iconSvg={(
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M24 13H10C7.79086 13 6 14.7909 6 17V27C6 29.2091 7.79086 31 10 31H24C26.2091 31 28 29.2091 28 27V17C28 14.7909 26.2091 13 24 13Z" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"} />
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 7.46154C10 5.74783 10.7375 4.10431 12.0503 2.89254C13.363 1.68077 15.1435 1 17 1C18.8565 1 20.637 1.68077 21.9497 2.89254C23.2625 4.10431 24 5.74783 24 7.46154V13H22V7.46154C22 6.23746 21.4732 5.06352 20.5355 4.19797C19.5979 3.33242 18.3261 2.84615 17 2.84615C15.6739 2.84615 14.4021 3.33242 13.4645 4.19797C12.5268 5.06352 12 6.23746 12 7.46154V13H10V7.46154Z" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"} />
                  </svg>

                )}
                title="Change Password"
                subtitle="Update your password here"
              />

              <Settings
                button={<Button color="primary" onClick={() => history.push('/preferences/logo_upload')}>Update</Button>}
                iconSvg={(
                  <svg width="34" height="34" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M 14 13 l 4 5 H 6 l 4 -4 l 1.79 1.78 L 14 13 Z m -6.01 -2.99 A 2 2 0 0 0 8 6 a 2 2 0 0 0 -0.01 4.01 Z M 22 5 v 14 a 3 3 0 0 1 -3 2.99 H 5 c -1.64 0 -3 -1.36 -3 -3 V 5 c 0 -1.64 1.36 -3 3 -3 h 14 c 1.65 0 3 1.36 3 3 Z m -2.01 0 a 1 1 0 0 0 -1 -1 H 5 a 1 1 0 0 0 -1 1 v 14 a 1 1 0 0 0 1 1 h 7 v -0.01 h 7 a 1 1 0 0 0 1 -1 V 5" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"} />
                  </svg>
                )}
                title="Change Logo"
                subtitle="Update your Logo here"
              />

              <Settings
                button={<ModeSwitch />}
                iconSvg={(
                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M30.8084 4.00244C30.5371 3.98443 30.2686 4.06657 30.0535 4.23337C27.6023 6.13182 19.896 12.1732 14.9839 16.7678C13.5128 18.1442 12.4586 19.5735 11.7845 20.6332C11.3535 21.3076 11.3297 22.1097 11.6279 22.8092C11.1828 22.6535 10.7072 22.5714 10.2225 22.5714C8.5154 22.5714 7.0594 24.2405 6.02577 25.4254C5.72405 25.7713 5.45833 26.0759 5.23285 26.2857C4.93347 26.5643 4.36464 26.8429 3.79582 27.1214C2.46856 27.7714 1.1413 28.4214 3.23697 29.0714C6.23078 30 8.22666 30 10.2225 30C11.2812 30 12.2965 29.6087 13.0451 28.9121C13.7937 28.2155 14.2143 27.2708 14.2143 26.2857C14.2143 25.6176 14.0208 24.968 13.6627 24.4018C14.2242 24.4859 14.8071 24.374 15.3132 24.0351C16.3498 23.3449 17.7332 22.3028 19.1166 20.9147C23.8787 16.1383 29.8041 8.2688 31.6376 5.78967C31.7975 5.57399 31.8743 5.30759 31.8539 5.03955C31.8335 4.77152 31.7172 4.51992 31.5265 4.33113C31.3351 4.13734 31.0797 4.02044 30.8084 4.00244Z" fill={theme==='dark'?"#1E8DCC":"#2D6DA4"} />
                  </svg>
                )}
                title="Appearance"
                subtitle="Change the contrast of your interface"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )

}