import { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory, Link } from "react-router-dom";
import { Card, Col, Container, Row, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Input } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import Button from 'components/Inputs/TButton'

import AlertMessage from 'components/Alert'
import { alertActions } from 'redux/_actions'

import WebAPI from 'services/WebAPI'

import { generatePassword } from 'utils/functions'
import axios from "axios";
import semver from "semver";


function DetailsLine({ title, value }) {
  return (
    <Row>
      <Col xs="3">
        <p className="card-sub-head">{title}</p>
      </Col>
      <Col xs="9">
        <p className="card-sub-value">{value}</p>
      </Col>
    </Row>
  )
}

const PopConfirm = ({ onOk, setMove }) => {
  const [modal, SetModal] = useState(false);

  const handleConfirm = () =>{
    if (onOk) onOk()
    SetModal(false)
  }

  return (
    <>
      <Button onClick={() => SetModal(!modal)} color="danger">
        <i className="far fa-trash-alt" />
      </Button>
      <Modal isOpen={modal} toggle={() => SetModal(false)}>
        <ModalHeader
          className="confirm-modal-header"
          toggle={() => SetModal(false)}
        >
          Are you sure to delete this customer?
        </ModalHeader>
        <ModalBody className="confirm-modal-body">
          As a result of this action, all endpoints and users associated with
          the customer will be deleted from the system. The automatic uninstall
          command for Canuari will also be sent to the endpoint. If any endpoints need to be moved to another customer, click Move 
        </ModalBody>
        <ModalFooter className="confirm-modal-footer">
          <Button
            outline
            className="common-btn-outlined"
            color={"primary"}
            type="button"
            size="sm"
            onClick={() => {
              setMove(true)
              SetModal(false)
            }}
          >
            Move
          </Button>
          <Button color="danger" size="sm" onClick={handleConfirm}>
            <i className="far fa-trash-alt" style={{ marginRight: 5 }} />
            Continue
          </Button>{" "}
          <Button size="sm" outline onClick={() => SetModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const CustomerDetails = () => {
  const [licenseData, setLicenseData] = useState([]);
  const [customer, setCustomer] = useState({});
  const [ devices, setDevices ] = useState([])
  const [ customerList, setCustomerList ] = useState([])
  const [ manageDevice, setManageDevice ] =  useState(false)
  const [ move, setMove ] = useState(false)
  const [ company, setCompany ] = useState(null)
  const [moveToCustomer, setMoveToCustomer ] = useState(null)
  const [selectedEndpoint,setSelectedEndpoint] = useState([])
  const [totals, setTotals] = useState({ servers: 0, desktops: 0 })
  const [consolePassword, setConsolePassword] = useState(null)
  const [download, setDownload] = useState(false);
  const [url, setUrl] = useState("");

  let history = useHistory();
  const { id } = useParams();

  const dispatch = useDispatch()

  useEffect(() => {
    fetchCustomerDetails()
    fetchCustomerDevices()
    loadCustomers()
  }, [id]);

  const loadCustomers = () => {
    setLoading(true)
    setCustomerList([])
    WebAPI
      .get(`/Customers/List?active=true`)
      .then(({ data: res }) => {
        if (res.status)
          setCustomerList(res.data?.filter((i)=>i.Id!==parseInt(id)))
      })
      .finally(() => setLoading(false))
  }

  const deviceColumns = [
    {
      dataField: "DeviceId",
      text: "ID",
      sort: true,
      searchable: true,
      headerStyle: { width: "70px", padding: "15px 5px" },
    },
    {
      dataField: "HostName",
      text: "Hostname",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "OnlineStatus",
      text: "Status",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      formatter: function (cell, row) {
        return (
          <span
            style={{
              color: cell === "Offline" ? "#e4535a" : "#1E8DCC",
              fontWeight: theme === "white" ? "normal" : "bold",
            }}
          >
            {cell}
          </span>
        );
      },
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "DeviceType",
      text: "Type",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px", width: 80 },
    },
    {
      dataField: "ActivationStatus",
      text: "Activation",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      formatter: function (cell, row) {
        return (
          <span
            style={{
              color: cell === "Not Available" ? "#e4535a" : "#1E8DCC",
              fontWeight: theme === "white" ? "normal" : "bold",
            }}
          >
            {cell}
          </span>
        );
      },
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "Version",
      text: "Version",
      classes: "common-table-column-style",
      sort: true,
      sortFunc: (a, b, order) => {
        a = a === "" ? "0.0.0" : a;
        b = b === "" ? "0.0.0" : b;
        if (order === "asc") {
          return semver.compare(a, b) === 1 ? 1 : -1;
        } else {
          return semver.compare(a, b) === 1 ? -1 : 1;
          // desc
        }
      },
      searchable: true,
      headerStyle: { padding: "15px 5px", width: 100 },
    },
    {
      dataField: "LicenseType",
      text: "License",
      classes: "common-table-column-style",
      sort: true,
      searchable: true,
      headerStyle: { padding: "15px 5px", width: 100 },
    },
    
  ];

  const fetchCustomerDevices= async()=>{
    setLoading(true)
    WebAPI
      .get('/Endpoints/ListFromCustomer/' + id)
      .then(({ data: res }) => {
        if (res.status) {
          setDevices(res.data)
        }
      })
      .finally(()=>setLoading(false))
  }
  
  const ENDPOINT_KEY = "DeviceId";

  const handleOnSelect = (row, isSelect) => {
    const endpoint = row[ENDPOINT_KEY];
    if (isSelect) {
      setSelectedEndpoint([...selectedEndpoint, endpoint]);
    } else {
      setSelectedEndpoint(
        selectedEndpoint.filter((x) => x !== row[ENDPOINT_KEY])
      );
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    const newEP = devices
      .filter((i) => !selectedEndpoint.includes(i[ENDPOINT_KEY]))
      .map((x) => x[ENDPOINT_KEY]);
    if (isSelect) {
      setSelectedEndpoint([...selectedEndpoint, ...newEP]);
    } else {
      setSelectedEndpoint([]);
    }
  };

  const fetchCustomerDetails = () => {
    dispatch(alertActions.clear())
    WebAPI
      .get(`/Customers/Details/${id}`)
      .then(({ data: res }) => {
        if (res.status) {
          setCustomer(res.data)
          setConsolePassword(res.consolePassword)
        }
        else{
          dispatch(alertActions.error(res.message === "Not allowed" ? "Customer not found" : res.message))
          setCustomer({})
          setConsolePassword(null)
        }
      })
      .then(() => {
        fetchLicenseList()
      })
  }

  const [loading, setLoading] = useState(false)

  const fetchLicenseList = () => {
    setLoading(true)
    WebAPI
      .get(`/Licenses/ListByCustomer/${id}`)
      .then(({ data: res }) => {
        if (res.status) {
          setLicenseData(res.data)
          totals.servers = res.data.reduce((prev, cur) => prev + cur.Server, 0)
          totals.desktops = res.data.reduce((prev, cur) => prev + cur.Desktop, 0)
        }
      })
      .finally(() => setLoading(false))
  }

  const handleMove = async() => {
    const req={CustomerId: parseInt(moveToCustomer), DeviceIdList:selectedEndpoint}
    WebAPI
      .post("/Customers/SwapCustomerEndpoints",req)
      .then(({ data: res }) => {
        if (res.status) {
          dispatch(alertActions.success("Sucessfully moved"))
          setMove(false)
          setManageDevice(false)
          setSelectedEndpoint([])
          setCompany(null)
          setMoveToCustomer(null)
          fetchCustomerDevices()
          fetchCustomerDetails()
          fetchLicenseList()
          setTimeout(() => {
            dispatch(alertActions.clear())
          }, 2500);
        }
      })
      .catch((err)=>{
        console.log('err', err)
      })
  }

  const [isSwitching, setSwitching] = useState(false)
  const switchState = (state) => {
    if (isSwitching)
      return;
    setSwitching(true)
    WebAPI
      .post(`/Customers/SwitchActive/${id}?isActive=${state ? 'true' : 'false'}`)
      .then(({ data: res }) => {
        if (res.status) {
          fetchCustomerDetails()
        }
      })
      .finally(() => setSwitching(false))
  }

  const sendConsolePassword = (password) => {
    if (isSwitching)
      return;
    setSwitching(true)
    WebAPI
      .post(`/Customers/SetConsolePassword/${id}`, { Password: password })
      .then(({ data: res }) => {
        if (res.status) {
          fetchCustomerDetails()
        }
      })
      .finally(() => setSwitching(false))
  }

  function generateConsolePassword() {
    setConsolePassword(null)
    sendConsolePassword(generatePassword(8))
  }

  function unsetConsolePassword() {
    setConsolePassword(null)
    sendConsolePassword("")
  }

  function handleDelete() {
    WebAPI.post(`/Customers/DeleteCustomer/${id}`).then(({ data: res }) => {
      if (res.status) {
        history.replace('/customers')
      }
    });
  }

  const prepareDownload = async (key) => {
    try {
      setDownload(true);
      setLoading(true);
      const resp = await axios.get(
        `https://installer.canauri.com/prepare_msi?c=${id}&key=${key}`
      );
      if (resp.data.status) setUrl(`https://installer.canauri.com${resp.data.url}`);
    } catch (err) {
      console.log("err", err);
    }
    setLoading(false);
  };

  const { role = "partner", theme = "white" } = useSelector(s => s.authentication.user) || {}

  const columnActions = (cell, row, rowIndex) => {
    return (
      <div style={{ display: "flex" }}>
        <Button
          outline
          size="sm"
          className="common-btn-outlined"
          color={theme === "white" ? "primary" : "secondary"}
          onClick={() =>
            history.push(`/customers/endpoints_under_license/${row.Key}`)
          }
        >
          <i class="fa fa-microchip" style={{ marginRight: 3 }} />
          Devices
        </Button>
        {(role === "admin" || role === "partner") && (
          <>
            <Button
              outline
              size="sm"
              className="common-btn-outlined"
              color="primary"
              onClick={() => prepareDownload(row.Key)}
            >
              <i className="fa fa-download" style={{ marginRight: 3 }} />
              Download
            </Button>
          </>
        )}
      </div>
    )
  }

  const columns = [
    {
      dataField: 'Key',
      text: 'Key',
      headerStyle: { width: '250px' },
    },
    {
      dataField: 'Server',
      text: 'Active Servers',
    }, {
      dataField: 'Desktop',
      text: 'Active Desktops',
    }, {
      dataField: 'ExpiryDate',
      text: 'Expiration Date',
      formatter: (cellContent) => new Date(cellContent).toLocaleDateString()
    }, {
      dataField: 'Type',
      text: 'Type'
    },
    {
      text: 'Actions',
      formatter: columnActions,
      headerStyle: { width: '300px' },
    }];

  return (
    <Container className="container-main" fluid>
      <div className="common-headers">
        <Button
          className="common-btn-back mr-2"
          outline
          onClick={() => history.go(-1)}
        >
          <i className="fas fa-chevron-left" />
        </Button>
        {customer.FirstName} {customer.LastName}
      </div>
      <AlertMessage />
      <Row>
        <Col xs="6">
          <div className="sub-header">Customer Details</div>
        </Col>
        <Col xs="6" style={{ display: "flex", justifyContent: "flex-end" }}>
          <PopConfirm
            onOk={handleDelete}
            setMove={() => setManageDevice(true)}
          />
          <Button
            color="primary"
            className="edit-btn common-btn"
            onClick={() => history.push("/customers/edit_customer/" + id)}
          >
            Edit Details
          </Button>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: "20px" }}>
          <Card>
            <Row>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                  padding: "10px 10px 0px 0px",
                }}
              >
                <Button
                  outline
                  className="common-btn-outlined"
                  color={theme === "white" ? "primary" : "secondary"}
                  type="button"
                  size="sm"
                  onClick={() =>
                    history.push(`/customers/list_endpoints/${id}`)
                  }
                >
                  Devices
                </Button>
                <Button
                  outline
                  className="common-btn-outlined"
                  color={theme === "white" ? "primary" : "secondary"}
                  type="button"
                  size="sm"
                  onClick={() =>
                    history.push("/customers/notification_settings/" + id)
                  }
                >
                  Notification Settings
                </Button>

                <Button
                  outline
                  className="mr-3 common-btn-outlined"
                  color={theme === "white" ? "primary" : "secondary"}
                  type="button"
                  size="sm"
                  onClick={() =>
                    history.push("/customers/customer_settings/" + id)
                  }
                >
                  Agent Default Settings
                </Button>
                <Button
                  className="mr-3"
                  outline={!customer.IsActive}
                  color={customer.IsActive ? "danger" : "primary"}
                  type="button"
                  size="sm"
                  onClick={() => switchState(!customer.IsActive)}
                  isLoading={isSwitching}
                >
                  {customer.IsActive ? "Deactivate" : "Activate"}
                </Button>
              </div>
            </Row>
            <Row style={{ padding: 10 }}>
              <Col xs="12">
                <DetailsLine
                  title="Name"
                  value={`${customer.FirstName} ${customer.LastName}`}
                />
                <DetailsLine
                  title="Partner Name"
                  value={customer.Partner?.EntityName}
                />
                <DetailsLine title="Email" value={customer.UserEmail} />
                <DetailsLine
                  title="Partner Contact"
                  value={customer.Partner?.Email}
                />
                <DetailsLine title="Company" value={customer.CompanyName} />
                <DetailsLine title="Servers Activated" value={totals.servers} />
                <DetailsLine
                  title="Desktop Activated"
                  value={totals.desktops}
                />
                {false && (
                  <DetailsLine
                    title="Console Password"
                    value={
                      <>
                        <span>
                          {consolePassword ? (
                            <strong>{consolePassword}</strong>
                          ) : (
                            <span style={{ color: "red" }}>
                              {isSwitching ? "<Working...>" : ""}
                            </span>
                          )}
                        </span>
                        <span className="ml-2">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              generateConsolePassword();
                            }}
                          >
                            Generate
                          </a>
                        </span>
                        <span className="ml-2">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              unsetConsolePassword();
                            }}
                          >
                            Remove
                          </a>
                        </span>
                      </>
                    }
                  />
                )}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: "20px" }}>
        <Col xs="6">
          <div className="sub-header">Customer Licenses</div>
        </Col>
        <Col xs="6">
          <Button
            color="primary"
            className="edit-btn"
            onClick={() => history.push(`/customers/new_license/${id}`)}
          >
            New License
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: "20px", marginBottom: 20 }}>
        <Col xs="12">
          <ToolkitProvider
            keyField="PartnerId"
            data={licenseData}
            columns={columns}
          >
            {(props) => (
              <div>
                <BootstrapTable
                  hover
                  bordered={false}
                  noDataIndication={
                    loading ? (
                      <Spinner type="grow" color="primary" />
                    ) : (
                      "No Data"
                    )
                  }
                  headerWrapperClasses="table-header-light"
                  {...props.baseProps}
                  rowStyle={
                    {
                      //backgroundColor: '#fff',
                    }
                  }
                />
              </div>
            )}
          </ToolkitProvider>
        </Col>
      </Row>
      <Modal
        isOpen={download}
        toggle={() => {
          setUrl("");
          setDownload(false);
        }}
      >
        <ModalHeader>Download Installer</ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          {loading && <Spinner />}
          {!loading && url && (
            <a href={url} target="_blank" rel="noreferrer">
              Click here to download!
            </a>
          )}
          {!loading && !url && <p>Something went wrong. Please try again!</p>}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={manageDevice}
        toggle={() => {
          setManageDevice(false);
          setSelectedEndpoint([])
          setMoveToCustomer(null)
        }}
        style={{ minWidth: "70%" }}
      >
        <ModalHeader>Move Endpoints</ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          {loading && <Spinner />}
          {!loading && (
            <div className="col">
              <ToolkitProvider
                keyField="DeviceId"
                data={devices}
                columns={deviceColumns}
              >
                {(props) => (
                  <div>
                    <BootstrapTable
                      hover
                      bordered={false}
                      selectRow={{
                        mode: "checkbox",
                        clickToSelect: true,
                        selected: selectedEndpoint,
                        onSelect: handleOnSelect,
                        onSelectAll: handleSelectAll,
                      }}
                      noDataIndication={
                        loading ? (
                          <Spinner type="grow" color="primary" />
                        ) : (
                          "No Data"
                        )
                      }
                      headerWrapperClasses="table-header-light"
                      {...props.baseProps}
                      rowStyle={
                        {
                          //backgroundColor: '#fff',
                        }
                      }
                    />
                  </div>
                )}
              </ToolkitProvider>
              <Button
                onClick={() => setMove(true)}
                color="primary"
                style={{ float: "right", marginTop: "10px" }}
                disabled={selectedEndpoint?.length === 0}
              >
                Move
              </Button>
            </div>
          )}
        </ModalBody>
      </Modal>

      <Modal
        isOpen={move}
        toggle={() => {
          setMove(false);
          setCompany(null)
          setMoveToCustomer(null)
        }}
      >
        <ModalHeader>Move Endpoints To:</ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          {loading && <Spinner />}
          {!loading && (
            <FormGroup>
              <Label for="exampleSelect">Select Company</Label>
              <Input
                id="exampleSelect"
                name="select"
                value={company}
                type="select"
                style={{
                  width: 200,
                  overflowY: "auto",
                  overflowX: "hidden",
                  minWidth: "100%",
                  maxHeight: "60vh",
                }}
                onChange={(e) => setCompany(e.target.value)}
              >
                <option value={0}>Select</option>
                {[...new Set(customerList.map((i)=>i.CompanyName))].map((i) => (
                  <option value={i}>{i}</option>
                ))}
              </Input>
              <Label for="exampleSelect">Select Customer</Label>
              <Input
                id="exampleSelect"
                name="select"
                value={moveToCustomer}
                disabled={!company}
                type="select"
                style={{
                  width: 200,
                  overflowY: "auto",
                  overflowX: "hidden",
                  minWidth: "100%",
                  maxHeight: "60vh",
                }}
                onChange={(e) => setMoveToCustomer(parseInt(e.target.value))}
              >
                <option value={0}>Select</option>
                {customerList.filter((i)=>i.CompanyName===company).map((i) => (
                  <option value={i.Id}>{i.EntityName}</option>
                ))}
              </Input>
              <Button
                color="warning"
                style={{ marginTop: "10px", float: "right" }}
                onClick={handleMove}
              >
                Move
              </Button>
            </FormGroup>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default CustomerDetails;