import { useState, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import AttackView from "./view";

export default function EndpointTable(props) {
  const [rowData, setRowData] = useState([]);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    const { endpoints } = props;
    setRowData(endpoints);
  }, [props]);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelected(row);
    },
  };

  const columns = [
    {
      dataField: "Device.Hostname",
      text: "Endpoint",
      sort: true,
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "Attack.ProcessName",
      text: "Process",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
  ];

  const paginationOptions = {
    //custom: true,
    withFirstAndLast: true,
    totalSize: rowData?.length,
    sizePerPage: 5,
    hideSizePerPage: true,
  };

  const { loading } = props;

  return (
    <Row>
      <ToolkitProvider
        keyField="id"
        data={rowData || []}
        columns={columns}
        search
        remote
      >
        {(props) => (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <BootstrapTable
                  hover
                  bordered={false}
                  rowEvents={rowEvents}
                  noDataIndication={
                    loading ? (
                      <Spinner type="grow" color="primary" />
                    ) : (
                      "No Data"
                    )
                  }
                  headerWrapperClasses="table-header-light"
                  bodyClasses="clickable-table-row"
                  {...props.baseProps}
                  {...paginationTableProps}
                  rowStyle={
                    {
                      //backgroundColor: '#fff',
                    }
                  }
                />
                <Row className="mt-2">
                  <Col>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                </Row>
              </div>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
      <AttackView attack={selected} onClose={() => setSelected(null)} />
    </Row>
  );
}
