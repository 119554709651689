import TButton from "components/Inputs/TButton";
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { Container, Row, Col, CardBody, Card, FormGroup, Input, Label, Spinner, Alert } from "reactstrap";

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';

import WebAPI from 'services/WebAPI'

import { alertActions } from "redux/_actions";
import AlertMessage from "components/Alert";
import _ from "lodash";

const ENDPOINT_KEY = 'DeviceId'

export default function CopyToEndpoints(props) {

  const [endpoints, setEndpoints] = useState([])

  const columns = [{
    dataField: 'HostName',
    text: 'Hostname',
    style:{whiteSpace:"normal"}
  }, {
    dataField: 'CustomerName',
    text: 'Customer',
    filter: textFilter(),
    style:{whiteSpace:"normal"}
  },
  {
    dataField: 'CompanyName',
    text: 'Company',
    filter: textFilter(),
    style:{whiteSpace:"normal"}
  },
  {
    dataField: 'DeviceType',
    text: 'Type',
    style:{whiteSpace:"normal"}
  }, {
    dataField: 'Version',
    text: 'Version',
    style:{whiteSpace:"normal"}
  }
  ];

  //
  const [selectedEndpoint, setSelectedEndpoint] = useState([])
  const [filteredEndpoint, setFilteredEndpoints] = useState([])

  const dispatch = useDispatch()

  const handleOnSelect = (row, isSelect) => {
    const endpoint = row[ENDPOINT_KEY]
    if (isSelect) {
      setSelectedEndpoint([...selectedEndpoint, endpoint])
    } else {
      setSelectedEndpoint(selectedEndpoint.filter(x => x !== row[ENDPOINT_KEY]))
    }
  }

  const handleSelectAll = (isSelect, rows) => {
    const newEP = filteredEndpoint.map(x => x[ENDPOINT_KEY])
    if (isSelect) {
      setSelectedEndpoint([...selectedEndpoint, ...newEP])
    } else {
      setSelectedEndpoint([])
    }
  }

  const afterFilter = (result, f) => {
    if (_.xor(filteredEndpoint.map(x => x[ENDPOINT_KEY]), result.map(x => x[ENDPOINT_KEY])).length) {
      setFilteredEndpoints(result || [])
    }
  }

  const [scope, setScope] = useState({ Configurations: true, FolderExclustions: true, Whitelist: true })

  const [loading, setLoading] = useState(false)
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    if (loading)
      return
    setLoading(true)
    WebAPI
      .get('/Endpoints/List?minifed=true')
      .then(({ data: res }) => {
        if (res.status)
          setEndpoints(res.data)
          setFilteredEndpoints(res.data)
      })
      .finally(() => setLoading(false))
  }, [])

  const handleSave = () => {
    if (updating)
      return
    setUpdating(true)
    WebAPI
      .post('/Endpoints/CopyToEndpoints',{
        scope,
        config : props.config,
        endpoints :  selectedEndpoint,
      })
      .then(({ data: res }) => {
        dispatch(alertActions.success("Request processed!"))
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 2000);
      })
      .finally(() => setUpdating(false))
  }

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <AlertMessage />
              <Row style={{ alignItems: 'center' }}>
                <Col xs={6} >
                  <FormGroup check inline>
                    <Input checked={scope.Configurations} type="checkbox" onChange={({ target: { checked } }) => { setScope({ ...scope, Configurations: checked }) }} />
                    <Label check>Configuration</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input checked={scope.FolderExclustions} type="checkbox" onChange={({ target: { checked } }) => { setScope({ ...scope, FolderExclustions: checked }) }} />
                    <Label check>Folder Exclustions</Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Input checked={scope.Whitelist} type="checkbox" onChange={({ target: { checked } }) => { setScope({ ...scope, Whitelist: checked }) }} />
                    <Label check>Process Allowlist</Label>
                  </FormGroup>
                </Col>
                <Col>Endpoint Selected : {selectedEndpoint.length}</Col>
                <Col><TButton color="primary" disabled={!selectedEndpoint.length} style={{ float: 'right' }} isLoading={updating} onClick={handleSave}>Apply</TButton></Col>
              </Row>
              <Row className="mt-3">
                <Col>
                  <Card>
                    <CardBody>
                      <BootstrapTable
                        keyField={ENDPOINT_KEY}
                        data={endpoints}
                        columns={columns}
                        pagination={paginationFactory()}
                        noDataIndication={loading ? <Spinner type="grow" color="primary" /> : "No Data"}
                        selectRow={{
                          mode: 'checkbox',
                          clickToSelect: true,
                          selected: selectedEndpoint,
                          onSelect: handleOnSelect,
                          onSelectAll: handleSelectAll
                        }}
                        filter={filterFactory({afterFilter})}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>

            </CardBody>
          </Card>
        </Col>
      </Row>

    </Container>
  )
}