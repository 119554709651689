import Client from 'services/WebAPI'

async function login(username, password, rememberUser = false) {
    const { data } = await Client.post('Login/Login', { username, password, rememberUser })
    return data
}

async function submit2FA(code, rememberUser = false, rememberMachine = false) {
    const { data } = await Client.post('Login/TwoFALogin', { code, rememberUser, rememberMachine })
    return data
}

async function logout() {
    await Client.get('Login/Logout')
    localStorage.removeItem('user');
}

async function getUser() {
    try {
        const { data } = await Client.get('Login/GetUser');
        return data.user
    }
    catch {}
    return false
}

export const userService = {
    login,
    logout,
    getUser,
    submit2FA
};