import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { alertActions } from 'redux/_actions/'

import WebAPI from 'services/WebAPI'
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  Container
} from "reactstrap";
import UserForm from "./form";

const UserEdit = () => {
  let history = useHistory();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getUser(id)
  }, [id]);

  const getUser = (id) => {
    WebAPI
      .get(`/Users/GetDetails/${id}`)
      .then(({ data: res }) => {
        if (res.status)
          setUser(res.data)
        else{
          setUser({})
          dispatch(alertActions.error(res.message))
        }
      })
  }
  const saveUser = (data) => {

    if (loading)
      return
    setLoading(true)
    if(data.PartnerId === null)
      delete(data.PartnerId)
    if(data.CustomerId === null)
      delete(data.CustomerId)
    WebAPI
      .post(`/Users/Edit/${id}`, data)
      .then(({ data: res }) => {
        if (res.status) {
          history.push('/users/user_details/' + id)
        } else {
          dispatch(alertActions.error(res.message))
        }
      })
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.push(`/users/user_details/${id}`)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Edit User
        </div>
        <Row>
          <Col xs="6">
            <Card className="shadow">
              <CardBody>
                <UserForm loading={loading} user={user} editMode={true} shareFormData={saveUser} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default UserEdit;