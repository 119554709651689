import { useEffect, useState } from "react";
import {
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";
import Button from 'components/Inputs/TButton'
import validator from 'validator';
import Alert from "components/Alert";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const PopConfirm = ({ onOk }) => {
  const [modal, SetModal] = useState(false);

  const handleConfirm = () =>{
    if (onOk) onOk()
    SetModal(false)
  }

  return (
    <>
      <Button onClick={()=>SetModal(!modal)} color="danger" >
        <i className="far fa-trash-alt" />
      </Button>
      <Modal isOpen={modal} toggle={()=>SetModal(false)}>
        <ModalHeader
          className="confirm-modal-header"
          toggle={()=>SetModal(false)}
        >
          Are you sure to delete this partner?
        </ModalHeader>
        <ModalBody className="confirm-modal-body">
          As a result of this action all customers, customer endpoints, and user accounts associated with this partner will be deleted from the system. Please change the partner mapping of customer if you wish to keep the customer account.
        </ModalBody>
        <ModalFooter className="confirm-modal-footer">
          <Button color="danger" size="sm" onClick={handleConfirm}>
            <i className="far fa-trash-alt" style={{ marginRight: 5 }} />
            Continue
          </Button>{" "}
          <Button size="sm" outline onClick={()=>SetModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const PartnerForm = (props) => {
  const [formState, setFormState] = useState({
    EntityName: '',
    FirstName: '',
    LastName: '',
    Email: '',
  })

  useEffect(() => {
    if (props.partner)
      setFormState(props.partner)
  }, [props.partner])

  const [invalidFormState, setInvalidFormState] = useState({
    EntityName: false,
    FirstName: false,
    LastName: false,
    Email: false,
  });

  const user = useSelector((s) => s.authentication.user)
  const history = useHistory()
  const {id}= useParams()

  const handleSave = (e) => {
    let flag = 0
    e.preventDefault();
    Object.entries(formState).forEach(([a, b]) => {
      if (a !=='PartnerId' && validator.isEmpty(b)) {
        flag = 1
        invalidFormState[a] = true
        setInvalidFormState({ ...invalidFormState })
      }
    });

    if (flag === 0) props.shareFormData(formState)
  }

  const handleFormValueChange = ({ target: { id, value } }) => {
    invalidFormState[id] = false
    formState[id] = value
    setFormState({ ...formState })
    setInvalidFormState({ ...invalidFormState })
  }

  const handleDelete = () => {
    if(props.deletePartner){
      props.deletePartner()
    }
  }

  return (
    <Form role="form" style={{maxWidth:"100%"}} onSubmit={handleSave}>
      <FormGroup>
        <Alert />
      </FormGroup>
      <FormGroup>
        <Label for="EntityName">Partner Name</Label>
        <Input
          id="EntityName"
          value={formState.EntityName}
          invalid={invalidFormState.EntityName}
          onChange={handleFormValueChange}
          type="text"
        />
        <FormFeedback>Please enter a partner name</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="FirstName">Contact First Name</Label>
        <Input
          id="FirstName"
          value={formState.FirstName}
          invalid={invalidFormState.FirstName}
          onChange={handleFormValueChange}
          type="text"
        />
        <FormFeedback>Please enter a contact first name</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="LastName">Contact Last Name</Label>
        <Input
          id="LastName"
          value={formState.LastName}
          invalid={invalidFormState.LastName}
          onChange={handleFormValueChange}
          type="text"
        />
        <FormFeedback>Please enter a contact last name</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="Email">Contact Email</Label>
        <Input
          type="Email"
          id="Email"
          value={formState.Email}
          invalid={invalidFormState.Email}
          onChange={handleFormValueChange}
        />
        <FormFeedback>Please enter a valid Email</FormFeedback>
      </FormGroup>
      <div style={{ marginTop:30, display: "flex", justifyContent: "space-between", whiteSpace:"nowrap" }}>
        {props.editMode && (
          <div style={{ display: 'flex' }}>
            <PopConfirm onOk={handleDelete} />
            <Button
              outline
              className="common-btn-outlined"
              color="primary"
              type="button"
              id="viewCustomer"
              onClick={props.openPartnerCustomers}
            >
              <i class="fa fa-users" aria-hidden="true" />
              <UncontrolledTooltip target="viewCustomer" autohide={false}>
                View Customer
              </UncontrolledTooltip> 
            </Button>
            {user.role==="admin" && (<Button
              outline
              className="common-btn-outlined"
              color="primary"
              type="button"
              id="viewEndpoint"
              onClick={()=>history.push(`/Endpoints/ListFromPartner/${id}`)}
            >
              <i class="fa fa-laptop" aria-hidden="true" />
              <UncontrolledTooltip target="viewEndpoint" autohide={false}>
                View Endpoints
              </UncontrolledTooltip> 
            </Button>)}
          </div>
        )}
        <Button className="common-btn" type="submit">
          {props.editMode ? "Save" : "Create"}
        </Button>
      </div>
    </Form>
  );
};

export default PartnerForm;