import { useState, useEffect } from "react";
import { Container, Row, Spinner } from "reactstrap";
import WebAPI from "services/WebAPI";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";
import EndpointsTable from "./table";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const COLORS02 = {
  Healthy: "#44b840",
  Unhealthy: "#e33b43",
};
const COLORS04 = {
  Latest: "#44b840",
  Previous: "#c77f04",
  Old: "#c7c240",
  "Very old": "#e33b43",
};
const COLORS05 = {
  Healthy: "#44b840",
  Unhealthy: "#e33b43",
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Visualization = (props) => {
  const {
    loading = false,
    recentAlerts = [],
    versionHealth = [],
    workstationDetails = [],
    serverHealth = [],
    serverDetails = [],
    workstationHealth = [],
  } = props;

  const data01 =
    recentAlerts.map((i) => ({
      endpoints: i.Device.Hostname,
      process: i.Attack.ProcessName,
    })) || [];

  const data02 = [
    {
      name: "Healthy",
      value: serverHealth?.healthyCount,
    },
    {
      name: "Unhealthy",
      value: serverHealth?.unHealthyCount,
    },
  ];

  const data03 = [
    { name: "Offline", value: serverDetails?.offlineServerData || 0 },
    { name: "Failed Updates", value: serverDetails?.failedServerCount || 0 },
    { name: "Firewall Status", value: serverDetails?.firewallStatus || 0 },
  ];

  const data04 = [
    {
      name: "Latest",
      value:
        versionHealth.filter((i) => i.HealthColor === "Green")[0]?.DeviceList
          ?.length || 0,
      version: [
        ...new Set(
          versionHealth
            .filter((i) => i.HealthColor === "Green")[0]
            ?.DeviceList.map((i) => i.Version)
        ),
      ].join(", "),
    },
    {
      name: "Previous",
      value:
        versionHealth.filter((i) => i.HealthColor === "Yellow")[0]?.DeviceList
          ?.length || 0,
      version: [
        ...new Set(
          versionHealth
            .filter((i) => i.HealthColor === "Amber")[0]
            ?.DeviceList.map((i) => i.Version)
        ),
      ].join(", "),
    },
    {
      name: "Old",
      value:
        versionHealth.filter((i) => i.HealthColor === "Amber")[0]?.DeviceList
          ?.length || 0,
      version: [
        ...new Set(
          versionHealth
            .filter((i) => i.HealthColor === "Yellow")[0]
            ?.DeviceList.map((i) => i.Version)
        ),
      ].join(", "),
    },
    {
      name: "Very old",
      value:
        versionHealth.filter((i) => i.HealthColor === "Red")[0]?.DeviceList
          ?.length || 0,
      version: [
        ...new Set(
          versionHealth
            .filter((i) => i.HealthColor === "Red")[0]
            ?.DeviceList.map((i) => i.Version)
        ),
      ].join(", "),
    },
  ];

  function CustomTooltip({ payload, label, active }) {
    if (active) {
      return (
        <div style={{ backgroundColor: "rgba(255, 255, 255)",color: "black", padding: 10 }}>
          <strong>Count: {payload[0]?.value}</strong>
          <div>Version: {payload[0]?.payload?.version}</div>
        </div>
      );
    }

    return null;
  }

  function CustomTooltipIssues({ payload, label, active }) {
    if (active) {
      return (
        <div style={{ backgroundColor: "rgba(255, 255, 255)", color: "black", padding: 10 }}>
          <div>{payload[0]?.name}</div>
        </div>
      );
    }

    return null;
  }

  const data05 = [
    {
      name: "Healthy",
      value: workstationHealth?.healthyCount,
    },
    {
      name: "Unhealthy",
      value: workstationHealth?.unHealthyCount,
    },
  ];

  const data06 = [
    { name: "Offline", value: workstationDetails?.offlineWorkstationData || 0 },
    {
      name: "Failed Updates",
      value: workstationDetails?.failedEndpointsCount || 0,
    },
    {
      name: "Firewall Status",
      value: workstationDetails?.firewallOffCount || 0,
    },
  ];

  const serverMap = (event, version) => {
    switch (event) {
      case "Offline": {
        window.open("/#/endpoints?device=Server&status=Offline");
        break;
      }
      case "Firewall Status": {
        window.open("/#/endpoints?device=Server&firewall=false");
        break;
      }
      case "Version": {
        window.open(`/#/endpoints?version=${version.split(', ').join()}`);
        break;
      }
      case "Healthy": {
        window.open("/#/endpoints?device=Server&health=true");
        break;
      }
      case "Unhealthy": {
        window.open("/#/endpoints?device=Server&health=false");
        break;
      }
      case "Failed Updates": {
        window.open("/#/endpoints?device=Server&failedUpdates=true");
        break;
      }
      default:
        break;
    }
  };

  const workstationMap = (event) => {
    switch (event) {
      case "Offline": {
        window.open("/#/endpoints?device=Desktop&status=Offline");
        break;
      }
      case "Firewall Status": {
        window.open("/#/endpoints?device=Desktop&firewall=false");
        break;
      }
      case "Healthy": {
        window.open("/#/endpoints?device=Desktop&health=true");
        break;
      }
      case "Unhealthy": {
        window.open("/#/endpoints?device=Desktop&health=false");
        break;
      }
      case "Failed Updates": {
        window.open("/#/endpoints?device=Desktop&failedUpdates=true");
        break;
      }
      default:
        break;
    }
  };

  return (
    <>
      <Container className="container-main" fluid>
          <Row>
            <div>
              <div
                style={{
                  display: "flex",
                  height: 450,
                  width: "100%",
                  marginTop: 10,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    borderStyle: "solid",
                    marginRight: 10,
                    borderColor: "rgba(110, 109, 109, 0.3)",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5 style={{padding:15}}>Most Recent Alerts</h5>
                    <ResponsiveContainer style={{padding:"0px 25px 0px"}}>
                      <EndpointsTable loading={loading} endpoints={recentAlerts} />
                    </ResponsiveContainer>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    borderStyle: "solid",
                    paddingBottom: 20,
                    marginRight: 10,
                    borderColor: "rgba(110, 109, 109, 0.3)",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5 style={{ padding: 15 }}>Server Health</h5>
                    {serverHealth.length===0 &&(<div style={{textAlign:"center"}}> <Spinner type="grow" color="primary"/> </div>)}
                    <ResponsiveContainer style={{paddingBottom:10}}>
                      {data02.filter((i)=>i.value!==0).length===0 && (<div style={{textAlign:"center"}}>No Data</div>)}
                      <PieChart>
                        <Legend
                          //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={data02.filter((i)=>i.value!==0).length===0 ? [] : data02}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          //outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                          onClick={(e)=>serverMap(e?.payload?.payload?.name)}
                        >
                          {(data02.filter((i)=>i.value!==0).length===0 ? [] : data02).map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS02[entry.name]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    borderStyle: "solid",
                    paddingBottom: 15,
                    borderColor: "rgba(110, 109, 109, 0.3)",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5 style={{ padding: 15 }}>Server Issues</h5>
                    {serverDetails.length===0 &&(<div style={{textAlign:"center"}}> <Spinner type="grow" color="primary"/> </div>)}
                    <ResponsiveContainer style={{paddingBottom:10}}>
                    {data03.filter((i)=>i.value!==0).length===0 && (<div style={{textAlign:"center"}}>No Issues</div>)}
                      <PieChart>
                        <Legend
                          //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={data03.filter((i)=>i.value!==0).length===0 ? [] : data03}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          //outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                          onClick={(e)=>serverMap(e?.payload?.payload?.name)}
                        >
                          {(data03.filter((i)=>i.value!==0).length===0 ? [] : data03).map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={<CustomTooltipIssues />}/>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
              {/* secondRow */}
              <div
                style={{
                  display: "flex",
                  height: 450,
                  width: "100%",
                  marginTop: 10,
                  overflow: "hidden",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    borderStyle: "solid",
                    paddingBottom: 15,
                    marginRight: 10,
                    borderColor: "rgba(110, 109, 109, 0.3)",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5 style={{padding:15}}>Version Health</h5>
                    {versionHealth.length===0 &&(<div style={{textAlign:"center"}}> <Spinner type="grow" color="primary"/> </div>)}
                    <ResponsiveContainer style={{paddingBottom:10}}>
                    {data04.filter((i)=>i.value!==0).length===0 && (<div style={{textAlign:"center"}}>No Data</div>)}
                      <PieChart>
                        <Legend
                          //payload={data04}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={data04.filter((i)=>i.value!==0).length===0 ? [] : data04}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          //outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                          onClick={(e)=>serverMap("Version",e?.payload?.payload?.version)}
                        >
                          {(data04.filter((i)=>i.value!==0).length===0 ? [] : data04).map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS04[entry.name]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={<CustomTooltip />} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    borderStyle: "solid",
                    paddingBottom: 20,
                    marginRight: 10,
                    borderColor: "rgba(110, 109, 109, 0.3)",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5 style={{ padding: 15 }}>Workstation Health</h5>
                    {workstationHealth.length===0 &&(<div style={{textAlign:"center"}}> <Spinner type="grow" color="primary"/> </div>)}
                    <ResponsiveContainer style={{paddingBottom:10}}>
                    {data05.filter((i)=>i.value!==0).length===0 && (<div style={{textAlign:"center"}}>No Data</div>)}
                      <PieChart>
                        <Legend
                          //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={data05.filter((i)=>i.value!==0).length===0 ? [] : data05}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          //outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                          onClick={(e)=>workstationMap(e?.payload?.payload?.name)}
                        >
                          {(data05.filter((i)=>i.value!==0).length===0 ? [] : data05).map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS05[entry.name]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    borderStyle: "solid",
                    paddingBottom: 15,
                    borderColor: "rgba(110, 109, 109, 0.3)",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5 style={{ padding: 15 }}>Workstation Issues</h5>
                    {workstationDetails.length===0 &&(<div style={{textAlign:"center"}}> <Spinner type="grow" color="primary"/> </div>)}
                    <ResponsiveContainer style={{paddingBottom:10}}>
                    {data06.filter((i)=>i.value!==0).length===0 && (<div style={{textAlign:"center"}}>No Issues</div>)}
                      <PieChart>
                        <Legend
                          //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={data06.filter((i)=>i.value!==0).length===0 ? [] : data06}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          //outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                          onClick={(e)=>workstationMap(e?.payload?.payload?.name)}
                        >
                          {(data06.filter((i)=>i.value!==0).length===0 ? [] : data06).map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip content={<CustomTooltipIssues />} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </Row>
      </Container>
    </>
  );
};

export default Visualization;
