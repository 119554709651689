import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonGroup,
  Spinner,
} from "reactstrap";

import CustomerReportSettings from './offlineReport'
import LicenseReport from "./licenseReport";
import EndpointSettingReport from "./endpointSetting";

export default function Reports() {
  const [offlineReport, setOfflinereport] = useState(true);
  const [endpointSettings, setEndpointSettings] = useState(false);
  const [licenseReport, setLicenseReport] = useState(false);

  const history= useHistory()

  const clearData = () => {
    setOfflinereport(false);
    setEndpointSettings(false);
    setLicenseReport(false);
  };
  return (
    <Container className="container-main" fluid>
      <div className="common-headers">
      <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.go(-1)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
        Report Settings
      </div>
      <Row>
        <Card className="shadow" style={{ width: "100%" }}>
          <CardBody>
            <Row>
              <Col>
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline={!offlineReport}
                    className={
                      offlineReport ? "reports-btn-grp--cdccdvisited" : ""
                    }
                    onClick={() => {
                      clearData();
                      setOfflinereport(true);
                    }}
                  >
                    Endpoint Offline report
                  </Button>
                  {/* <Button
                    color="primary"
                    outline={!licenseReport}
                    className={
                      licenseReport ? "reports-btn-grp-cdvdfvvisited" : ""
                    }
                    onClick={() => {
                      clearData();
                      setLicenseReport(true);
                    }}
                  >
                    License Report
                  </Button>
                  <Button
                    color="primary"
                    outline={!endpointSettings}
                    className={
                      endpointSettings ? "reports-btn-grp-cdvdfvvisited" : ""
                    }
                    onClick={() => {
                      clearData();
                      setEndpointSettings(true);
                    }}
                  >
                    Endpoint Settings and Issues Report
                  </Button> */}
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>

      {offlineReport&&(
        <CustomerReportSettings />
      )}
      {licenseReport&&(
        <LicenseReport />
      )}
      {endpointSettings&&(
        <EndpointSettingReport />
      )}
    </Container>
  );
}
