import { useState, useEffect } from "react";
import { Container, Row } from "reactstrap";
import WebAPI from "services/WebAPI";
import ReportingTable from "./table";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Cell,
  ResponsiveContainer,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const COLORS1 = ["#FF8042", "#FFBB28", "#00C49F", "#0088FE"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Reporting = () => {
  const [loading, setLoading] = useState(false);
  const [endpoints, setEndpoints] = useState([])

  useEffect(() => {
    fetchdata();
  }, []);

  const fetchdata = async () => {
    setLoading(true);
    try {
      const resp= await WebAPI.get('Report/EndpointServiceNotRunningReport')
      if(resp.data.status)
        setEndpoints(resp.data.result)
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const trayIconData = [
    { name: "Available", value: endpoints.filter(i=>i.TrayIcon!=="Not available").length || 0 },
    { name: "Not Available", value: endpoints.filter(i=>i.TrayIcon==="Not available").length || 0 },
  ];

  const serviceUpdateData = [
    { name: "Available", value: endpoints.filter(i=>i.UpdateService!=="Not available").length || 0 },
    { name: "Not Available", value: endpoints.filter(i=>i.UpdateService==="Not available").length || 0 },
  ];

  return (
    <>
      <Container className="container-main" fluid>
      {!loading && (<div className="common-headers" style={{marginTop:10}}>Endpoint Status Report</div>)}
        {!loading && (
          <Row>
            <div className="col">
              <div
                style={{
                  display: "flex",
                  maxWidth:"100%",
                  height: 400,
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <div style={{ flex: 1 }}>
                  <div style={{ width: "100%",maxWidth:"100%", height: "100%" }}>
                    <h5>Availability of Tray Icon</h5>
                    <ResponsiveContainer>
                      <PieChart>
                        <Legend
                          //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={trayIconData || []}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {trayIconData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ height: "100%", maxWidth:"100%", width: "100%" }}>
                    <h5 style={{ marginLeft: 15 }}>
                      Availability of Service Updates
                    </h5>
                    <ResponsiveContainer>
                      <PieChart>
                        <Legend
                          //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={serviceUpdateData || []}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {serviceUpdateData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS1[index % COLORS1.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        )}
        <Row style={{ marginTop: "2%" }}>
          <ReportingTable
            endpoints={endpoints || []}
            loading={loading}
          />
        </Row>
      </Container>
    </>
  );
};

export default Reporting;
