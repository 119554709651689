import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { alert } from './alert.reducer';
import { endpointFilters } from './endpointfilters.reducer'

const rootReducer = combineReducers({
    authentication,
    alert,
    endpointFilters
});

export default rootReducer;