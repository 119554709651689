
const host = process.env.NODE_ENV==="production" ? '/'
//     : 'https://localhost:5001/'
    : 'https://cs-prod-portal-app-stage.azurewebsites.net/'

const config = {
    apiHost: host,
    apiUrl : host + 'spa_api'
};

export default config;