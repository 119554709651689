import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import { alertActions } from "redux/_actions/";
import WebAPI from "services/WebAPI";
import TButton from "components/Inputs/TButton";
import AlertMessage from "components/Alert";

const moment = require("moment");

export default function Logs({ isOnline, Log }) {
  const [isLog, setLog] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [logLoading, setLogloading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [logData, setlogData] = useState([]);

  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    Log(!isLog)
    if (isLog) loadLogData();
  }, [isLog]);

  useEffect(() => {
    let interval;
    if (isUpdate) {
      const fetchLogdata = async () => {
        if (!logData) setLogloading(true);

        try {
          const res = await WebAPI.get(
            `Logging/FetchDeviceLogs?deviceId=${id}`
          );
          if (res.data.status)
            if (res.data.data && res.data.data.files) {
              if (logData !== res.data.data.files) setUpdate(false);
              setlogData(res.data.data.files?.sort((a,b)=>{return new Date(b.LastModified)-new Date(a.LastModified)}));
              setLogloading(false);
            }
        } catch (error) {
          console.log(error);
        }
      };

      fetchLogdata();
      interval = setInterval(() => {
        fetchLogdata();
      }, 10000);
    }

    return () => clearInterval(interval);
  }, [isUpdate]);

  const handleForceFetch = async () => {
    setLoading(true)
    if (isOnline === "Offline") {
      dispatch(
        alertActions.error(
          "This action cannot be done, this device is Offline..."
        )
      );
      setTimeout(() => {
        dispatch(alertActions.clear());
        setLoading(false)
      }, [5000]);
      return;
    }
    try {
      const resp = await WebAPI.get(
        `Logging/UpdateClientDeviceLogs?deviceId=${id}&isForced=${true}`
      );
      if (resp.data.status) {
        await timeout(30000)
        dispatch(alertActions.success("Force Updated!"));
        setLoading(false)
        setUpdate(true);
      } else dispatch(alertActions.error("Something Went Wrong"));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 3000);
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  };

  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  const handleUpdate = async () => {
    if (isOnline === "Offline") {
      dispatch(
        alertActions.error(
          "This action cannot be done, this device is Offline..."
        )
      );
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, [5000]);
      return;
    }
    try {
      const resp = await WebAPI.get(
        `Logging/UpdateClientDeviceLogs?deviceId=${id}&isForced=${false}`
      );
      if (resp.data.status) {
        dispatch(alertActions.success("Updated!"));
        setUpdate(true);
      } else dispatch(alertActions.error("Something Went Wrong"));
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 3000);
    } catch (err) {
      console.log(err);
    }
  };

  const loadLogData = () => {
    setLogloading(true);
    WebAPI.get(`Logging/FetchDeviceLogs?deviceId=${id}`).then(
      ({ data: res }) => {
        if (res.status) {
          if (res.data && res.data.files) setlogData(res.data.files?.sort((a,b)=>{return new Date(b.LastModified)-new Date(a.LastModified)}));
          setLogloading(false);
        }
      }
    );
  };

  const rowEvents = {
    onClick: async (e, row, rowIndex) => {
      try {
        const req = {
          DeviceId: parseInt(id),
          FileName: row.Name,
        };
        const resp = await WebAPI.post(`Logging/FetchLogs`, req);
        if (resp.data.status) window.open(resp.data.url);
      } catch (error) {
        console.log(error);
        dispatch(alertActions.error("Something went wrong"));
        setTimeout(() => {
          dispatch(alertActions.clear());
        }, 3000);
      }
    },
  };

  const columns = [
    { dataField: "Name", text: "File", sort:true },
    {
      text: "Last Modified",
      sort:true,
      formatter: (cell, row) => {
        return moment(row.LastModified)
          .format("MMMM Do YYYY, h:mm:ss a")
          .toLocaleString();
      },
      sortFunc: (a, b, order, dataField, rowA, rowB) => {
        if (order === 'desc') {
          if (new Date(rowA.LastModified) < new Date(rowB.LastModified)) return 1
          if (new Date(rowA.LastModified) > new Date(rowB.LastModified)) return -1;
          return 0
        }
        else {
          if (new Date(rowA.LastModified) < new Date(rowB.LastModified)) return -1
          if (new Date(rowA.LastModified) > new Date(rowB.LastModified)) return 1;
          return 0
        }
      }
    },
  ];

  return (
    <div>
      <TButton
        outline
        style={{ marginRight: 8 }}
        onClick={() => setLog(true)}
      >
        Log
      </TButton>

      <Modal isOpen={isLog} size="lg" toggle={() => setLog(!isLog)}>
        <div style={{ padding: 10 }}>
          <div className="modal-header">
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setLog(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div>
            <AlertMessage style={{ float: "left" }} />
            <div style={{ display: "flex", float: "right" }}>
              <Button outline size="sm" onClick={handleUpdate}>
                Update
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={handleForceFetch}
                style={{ marginRight: 25 }}
              >{loading && (<Spinner style={{ width: "0.7rem", height: "0.7rem", marginRight:5 }} />)}
                Force Fetch
              </Button>
            </div>
          </div>

          <ModalBody style={{ marginTop: 30 }}>
            <BootstrapTable
              keyField="Name"
              bordered={false}
              columns={columns}
              data={logData}
              rowEvents={rowEvents}
              noDataIndication={
                logLoading ? (
                  <Spinner type="grow" color="primary" />
                ) : (
                  "No Data. Please Update..."
                )
              }
              bodyClasses="clickable-table-row"
              pagination={paginationFactory({
                sizePerPage: 5,
                hideSizePerPage: true,
              })}
              filter={filterFactory()}
            />
          </ModalBody>
        </div>
      </Modal>
    </div>
  );
}
