import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback
} from "reactstrap";

import { alertActions } from 'redux/_actions/'
import WebAPI from 'services/WebAPI'
import AlertMessage from "components/Alert";
import Button from 'components/Inputs/TButton'

const CustomerNotificationSettings = () => {
  let history = useHistory();
  const { id } = useParams();

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id)
      return
    WebAPI
      .get('/Customers/GetNotificationSettings/' + id)
      .then(({ data: res }) => {
        if (res.status) {
          setFormState(res.data)
        }
      })
  }, [id]);


  const updateSettings = (e) => {
    e.preventDefault()
    if (loading)
      return

    setLoading(true)

    const data = formState
    WebAPI
      .post('/Customers/SetNotificationSettings/' + id, data)
      .then(({ data: res }) => {
        if (res.status) {
          dispatch(alertActions.success(res.message))
        } else {
          dispatch(alertActions.error(res.message))
        }
      })
      .catch(() => dispatch(alertActions.error("Connection error")))
      .finally(() => setLoading(false))
  }

  const [formState, setFormState] = useState({
    EmailNotificationActive: true,
    NotificationEmails: "",
  });

  const [invalidFormState, setInvalidFormState] = useState({
    EmailNotificationActive: false,
    NotificationEmails: false,
  });


  const handleFormValueChange = ({ target: { id, value, checked } }) => {
    if (id === 'EmailNotificationActive')
      value = checked
    invalidFormState[id] = false
    formState[id] = value
    setFormState({ ...formState })
    setInvalidFormState({ ...invalidFormState })
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.go(-1)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Edit Notification Settings
        </div>
        <Row>
          <Col xs="8">
            <Card className="shadow">
              <CardBody>
                <Form role="form" onSubmit={updateSettings}>
                  <FormGroup>
                    <AlertMessage />
                  </FormGroup>
                  
                  <FormGroup>
                    <Label for="NotificationEmails">Emails (comma seperated list)</Label>
                    <Input
                      id="NotificationEmails"
                      value={formState.NotificationEmails}
                      invalid={invalidFormState.NotificationEmails}
                      onChange={handleFormValueChange}
                      type="text"
                    />
                    <FormFeedback>Please enter a emails for notification</FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      id="EmailNotificationActive"
                      checked={formState.EmailNotificationActive}
                      onChange={handleFormValueChange}
                      type="checkbox"
                    />
                    {''}
                    <Label for="EmailNotificationActive" check>Email Notification Active</Label>
                  </FormGroup>
                  <Row>
                    <Col>
                      <Button
                        className=" mt-4 common-btn"
                        type="submit"
                        isLoading={loading}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default CustomerNotificationSettings;