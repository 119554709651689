export const userConstants = {
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    LOGIN_2FA: 'USERS_LOGIN_2FA',
    LOGIN_2FA_PROGRESS: 'USERS_LOGIN_2FA_PROGRESS',
    LOGOUT: 'USERS_LOGOUT', 
    LOGIN_PROGRESS : 'USERS_LOGIN_PROGRESS',
    SET_MSG :'USER_LOGIN_SET_MSG',
    CLEAR_MSG :'USER_LOGIN_CLEAR_MSG'
};

export const alertConstants = {
    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR'
};

export const endpointFiltersConstants = {
    CHANGE: 'ENDPOINT_FILTER_CHANGE',
    CLEAR: 'ALERT_CLEAR'
};
