import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  Spinner,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
} from "reactstrap";
import WebAPI from "services/WebAPI";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import _ from "lodash";
import AttackView from "./view";
import SelectSearch from "react-select-search";
import "./style.css"
import moment from "moment";

const AttackList = () => {
  const [attackWholeList, setAttackList] = useState([]);
  const [selected, setSelected] = useState(null);

  const [loading, setLoading] = useState(false);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [device, setDevice] = useState(0);
  const [customer, setCustomer] = useState(0);
  const [customerFilter, setCFilter]= useState('')
  const [endpointFilter, setEFilter] = useState('')
  const [devices, setDevices] = useState([]);
  const [customers, setCustomers] = useState([]);

  const { role = "Partner" } = useSelector(
    (state) => state.authentication.user
  );
  useEffect(() => {
    const loadCustomers = async () => {
      WebAPI.get(`/Customers/List?active=true`).then(({ data: res }) => {
        if (res.status) {
          setCustomers(res.data);
        }
      });
    };

    const loadDevices = async () => {
      setLoading(true);
      WebAPI.get("/Endpoints/List").then(({ data: res }) => {
        if (res.status) {
          setDevices(res.data);
        }
      });
    };
    loadDevices();
    loadCustomers();
  }, []);

  useEffect(() => {
    const loadAttacks = async () => {
      setLoading(true);
      setAttackList([]);
      WebAPI.post("/Attack/FetchAttacks", {
        PageSize: size,
        PageNumber: page,
        CustomerId: customer,
        DeviceId: device,
      })
        .then(({ data: res }) => {
          if (res.status) {
            setTotal(res.result.TotalAttackList);
            setAttackList(res.result.filteredAttackList);
          }
        })
        .finally(() => setLoading(false));
    };
    loadAttacks();
  }, [size, page, device, customer]);

  const onTableChange = (type, { page, sizePerPage }) => {
    setPage(page);
    setSize(sizePerPage);
  };

  const columns = [
    {
      dataField: "Attack.Time",
      text: "Time",
      classes: "common-table-column-style",
      formatter: function (cell, row) {
        return <span>{moment(cell+(cell[cell.length-1]==="Z"?"":"Z")).format("DD/MM/YYYY, hh:mm:ss a")}</span>;
      },
      headerStyle: { padding: "15px", minWidth: 150 },
    },
    {
      dataField: "Attack.ProcessName",
      text: "Process",
      classes: "common-table-column-style",
      headerStyle: { padding: "15px", width: 450 },
    },
    !device && {
      dataField: "Device.Hostname",
      text: "Hostname",
      classes: "common-table-column-style",
      headerStyle: { padding: "15px" },
    },
    {
      dataField: "Attack.User",
      text: "User",
      classes: "common-table-column-style",
      style: { whiteSpace: "normal" },
      headerStyle: { padding: "15px" },
    },
    role !== "customer" &&
      !customer && {
        dataField: "Customer.CompanyName",
        text: "Customer",
        classes: "common-table-column-style",
        style: { whiteSpace: "normal" },
        headerStyle: { padding: "15px" },
      },
  ];

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      setSelected(row);
    },
  };

  const displayTarget = (type) => {
    let _customer = customers?.find((c) => c?.Id === customer);
    switch (type) {
      case "customer":
        return customer === 0
          ? "Customers"
          : `${_customer?.FirstName} ${_customer?.LastName}`;

      default:
        return device === 0
          ? "Endpoints"
          : devices?.find((c) => c?.DeviceId === device)?.HostName;
    }
  };

  console.log("device", device, customer);

  return (
    <>
      <Container className="container-main" fluid>
        <div className="common-headers">Alerts</div>
        <Row style={{ marginBottom: 50 }}>
          <PaginationProvider
            pagination={paginationFactory({
              custom: true,
              page,
              sizePerPage: size,
              totalSize: total,
            })}
          >
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <Row>
                  <Col
                    xs="4"
                    className="local-text"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: 10,
                    }}
                  >
                    Entries per page :
                    <SizePerPageDropdownStandalone
                      className="common-page-size-selector ml-2"
                      {...paginationProps}
                    />
                  </Col>
                  <Col className="d-flex justify-content-end" xs="8">
                    {role !== "customer" && (
                      <div>
                        <SelectSearch
                          options={[
                            { name: "Customers", value: parseInt(0) },
                            ...customers
                              .filter(
                                (i) =>
                                  (customerFilter &&
                                    `${i.FirstName} ${i.LastName} - ${i.CompanyName}`
                                      .toLowerCase()
                                      .includes(
                                        customerFilter.toLowerCase()
                                      )) ||
                                  (!customerFilter && i)
                              )
                              .map((r) => {
                                return {
                                  name: `${r.FirstName} ${r.LastName} - ${r.CompanyName}`,
                                  value: r.Id,
                                };
                              }),
                          ]}
                          value={customer}
                          name="Customer"
                          placeholder="Choose Customer"
                          search
                          getOptions={(val) => setCFilter(val)}
                          onChange={(e) => {
                            setCustomer(parseInt(e));
                            setDevice(0);
                          }}
                        />
                      </div>
                    )}
                    {(customer !== 0 || role === "customer") && (
                      <div style={{marginLeft:"10px"}}>
                        <SelectSearch
                          options={[
                            { name: "Endpoints", value: parseInt(0) },
                            ...devices
                              .filter(
                                (i) =>
                                  (customer
                                    ? i?.CustomerId === customer
                                    : true) &&
                                  ((endpointFilter &&
                                    i.HostName
                                      .toLowerCase()
                                      .includes(
                                        endpointFilter.toLowerCase()
                                      )) ||
                                    (!endpointFilter && i))
                              )
                              .map((r) => {
                                return {
                                  name: r.HostName,
                                  value: r.DeviceId,
                                };
                              }),
                          ]}
                          value={device}
                          name="Endpoint"
                          placeholder="Choose Endpoint"
                          search
                          getOptions={(val) => setEFilter(val)}
                          onChange={(e) => {
                            setDevice(parseInt(e))
                          }}
                        />
                      </div>
                    )}
                  </Col>
                </Row>
                <BootstrapTable
                  remote
                  keyField="id"
                  onTableChange={onTableChange}
                  hover
                  data={attackWholeList}
                  columns={columns}
                  bordered={false}
                  noDataIndication={
                    loading ? (
                      <Spinner type="grow" color="primary" />
                    ) : (
                      "No Data"
                    )
                  }
                  rowEvents={rowEvents}
                  headerWrapperClasses="table-header-light"
                  bodyClasses="clickable-table-row"
                  {...paginationTableProps}
                />
                <Row className="mt-2">
                  <Col>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                  <Col>
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </div>
            )}
          </PaginationProvider>
        </Row>
        <AttackView attack={selected} onClose={() => setSelected(null)} />
      </Container>
    </>
  );
};

export default AttackList;
