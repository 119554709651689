import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { 
  Col, 
  Row, 
  Card, 
  Form,
  Label,
  Input,
  Button,
  CardBody, 
  Container,
  FormGroup,
  FormFeedback
} from "reactstrap";

const ChangePartner = () => {
  const [partnername, ] = useState("partners name");
  let history = useHistory();
  const { id } = useParams();
  
  useEffect(() => {
    console.log("id",id)
  });

  const savePartner = (partnerData) => {
    console.log(partnerData)
  }

  const handlePartnerChange = (e) => {
    console.log("change",e)
  }

  return(
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={()=>history.push('/Partner/PartnerList')}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Change Partner
        </div>
        <Row>
          <Col xs="5">
            <Card className="shadow">
              <CardBody>
                <Form role="form" onSubmit={savePartner}>
                  <FormGroup>
                    <Label for="partnername">Partner Name</Label>
                    <Input 
                      id="partnername" 
                      value={partnername}
                      onChange={handlePartnerChange}
                      type="text" 
                    />
                    <FormFeedback>Please enter a partner name</FormFeedback>
                  </FormGroup>
                </Form>
                <Row>
                  <Col sm={{ offset: 10 }}>
                    <Button 
                      className={"mt-3 common-btn"}
                      type="submit"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default ChangePartner;