
import Configuration from "components/Configuration"

import WebAPI from 'services/WebAPI'
import { useEffect } from 'react'


export default function TestPage(props) {

  useEffect(() => {
    WebAPI
      .get('/Users/GetUsers')
      .then(r=>console.log(r.data))
  }, [])

  return (
    "Testing"
  )
}