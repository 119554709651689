import { userConstants } from '../_constants';

import { isEqual } from 'lodash'

const initialState = { loggedIn: false, firstLoad : true }

export function authentication(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOGIN_PROGRESS:
            return {
                loginProgess: true,
                isWorking: true,
            };
        case userConstants.LOGIN_2FA:
            return {
                loginProgess: true,
                TwoFA: true,
                isWorking: false
            }
        case userConstants.LOGIN_2FA_PROGRESS:
            return {
                loginProgess: true,
                TwoFA: true,
                isWorking: true
            }
        case userConstants.LOGIN_SUCCESS:
            if (!isEqual(state.user, action.user)) {
                return {
                    loggedIn: true,
                    user: action.user
                };
            }
            return state
        case userConstants.LOGOUT:
            if (state.loginProgess)
                return state
            return {}
        case userConstants.LOGIN_FAILURE:
            if (state.loginProgess) {
                if (state.TwoFA)
                    return state
            }
            return {}
        default:
            return state
    }
}