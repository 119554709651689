import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { userActions } from "redux/_actions";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import WebAPI from "services/WebAPI";


const AdminNavbar = (props) => {
  const [partnerLogo, setPLogo]= useState('')
  const [bgColor, setBgColor] = useState('')
  const [partnerFavicon, setPfavicon]= useState('')
  const dispatch = useDispatch()
  const user = useSelector(store => store.authentication.user)
  useEffect(()=>{
    prepareLogo()
    prepareFavicon()
    fetchPartnerLogo()
  },[])

  const prepareLogo= async()=>{
    await fetch(
      require("../../assets/img/brand/white.png").default
    ).then((res) => {
      setPLogo(res.url);
    })
    .catch((err)=>console.log('err', err))
  }

  const prepareFavicon= async()=>{
    await fetch(
      require("../../assets/img/brand/favicon.ico").default
    ).then((res) => {
      setPfavicon(res.url);
    })
    .catch((err)=>console.log('err', err))
  }

  const fetchPartnerLogo=async()=>{
    const resp=await WebAPI.get('Partner/FetchPartnerPreference')
    if(resp.data.status&& resp.data.data && resp.data.data.LogoLargeUrl && resp.data.data.LogoMiniUrl)
    {
      setPfavicon(resp.data.data.LogoMiniUrl)
      setPLogo(resp.data.data.LogoLargeUrl)
      setBgColor(resp.data.data.Color)
    }
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" style={{ backgroundColor: bgColor || "#2E6DA4" }} expand="md" id="navbar-main">
        <Container fluid>
         {/* add favicon Url */}
          {partnerFavicon&&(<Helmet>
          <link rel="shortcut icon" href={partnerFavicon} />
          </Helmet>)}
          <Link
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            <img
              b alt="..."
              className="navbar-brand-img"
              style={{width:182, height:60 /* ,backgroundColor:bgColor||"",padding:5 */}}
              src={partnerLogo}
            />
          </Link>
          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <i className="bi bi-person-circle fa-2x" />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome!</h6>
                  <h6 className="text-overflow mt-1">{user.name}</h6>
                  <h6 className="text-overflow mt-1">Role : {user.role}</h6>
                </DropdownItem>
                <DropdownItem to="/preferences" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Preferences</span>
                </DropdownItem>
                <DropdownItem onClick={() => dispatch(userActions.logout())}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
