import React, { useState } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import Button from "components/Inputs/TButton";
import { Link, Redirect } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";

import WebAPI from "services/WebAPI.js";
import { alertActions } from "redux/_actions";

function ForgotPass(props) {
  const [mail, setMail] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmit] = useState(false);

  const dispatch = useDispatch;

  if (!_.get(props, "location.state.fromLogin"))
    return <Redirect to="/auth/login" />;

  const handleSubmit = () => {
    if (!mail) return;
    setLoading(true);
    WebAPI.post(`ForgotPasswordAPI/ForgotPassword?Email=${mail}`,{Email:mail})
      .then(({ data: res }) => {
        if (res.status) {
          setSubmit(true);
        }
      })
      .finally(() => setLoading(false));
  };

  console.log("props", props);

  if (submitted)
    return (
      <>
        <p style={{ fontSize: 14, marginBottom: 20 }}>
          Please check your email to reset password.
        </p>
        <Link to="/auth/login" replace style={{ fontSize: 14 }}>
          Login
        </Link>
      </>
    );

  return (
    <>
      <FormGroup>
        <Label>Email</Label>
        <Input
          type="text"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
        />
      </FormGroup>
      <FormGroup
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 0,
          marginTop: 20,
        }}
      >
        <Link to="/auth/login" replace style={{ fontSize: 14 }}>
          Back to login
        </Link>
        <Button
          isLoading={loading}
          onClick={handleSubmit}
          type="submit"
          color="primary"
        >
          Submit
        </Button>
      </FormGroup>
    </>
  );
}

export default ForgotPass;
