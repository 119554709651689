import { Redirect, useHistory } from "react-router-dom"

import { useSelector } from 'react-redux'

function Require2FA({ children }) {
  const { twoFactorActive } = useSelector(store => store.authentication.user)
  const history = useHistory()
  return twoFactorActive || history.location.pathname === '/preferences/change_2FA'
    ? children : <Redirect to="/preferences/change_2FA" replace/>;
}

export default Require2FA