import { Container, Row, Col, CardBody, Card, FormGroup, Input } from 'reactstrap'
import AlertMessage from 'components/Alert'
import Button from 'components/Inputs/TButton'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import QRCode from 'qrcode'
import WebAPI from 'services/WebAPI'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet'
import { alertActions, userActions } from 'redux/_actions'
import { Spin } from "antd";


export function TwoFactorSettings() {

  const [isLoading, setIsLoading] = useState(false)

  const [enabled, setEnabled] = useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [QRCodeURL, setQRCode] = useState('')

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    loadTwoFactorStatus()
  }, [])

  const loadTwoFactorStatus = () => {
    WebAPI
      .get('Preferences/GetTwoFactor')
      .then(result => {
        const { data: { status, data } } = result
        const { twoFactorStatus, qrCode } = data
        QRCode.toDataURL(qrCode).then(r => setQRCode(r))
        setEnabled(twoFactorStatus);
      })
  }

  const UpdateQrCode = () => {
    setQRCode('');
    WebAPI.get("Preferences/GetTwoFactor").then((result) => {
      const {
        data: { status, data },
      } = result;
      const { qrCode } = data;
      QRCode.toDataURL(qrCode).then((r) => setQRCode(r));
      
    });
  };

  const onSubmit = () => {
    const submitModel = { Enabled: enabled, VerificationCode: verificationCode }
    setIsLoading(true)
    WebAPI.post('Preferences/SetTwoFactor', submitModel)
      .then(result => {
        const { data: { status, message } } = result
        if(status){
          dispatch(alertActions.success(message))
          userActions.getAndSetUser(dispatch)          
          setTimeout(()=>{
            dispatch(alertActions.clear())
            history.push('/')
          },2000)
        }
        else
          dispatch(alertActions.error(message))
      })
      .catch(() => dispatch(alertActions.error('Network error')))
      .finally(()=>setIsLoading(false))
  }

  return (
    <Container
      className="container-main container-fluid"
      style={{ marginLeft: 0 }}
    >
      <div className="common-headers">Configure Multi-Factor Authenticator</div>
      <Helmet>
        <title>{"Two-Factor Authentication"}</title>
      </Helmet>
      <Row>
        <Col md={8}>
          <Card className="shadow">
            <CardBody>
              <AlertMessage />
              <FormGroup>
                <label>Multi-factor enabled</label>
                <label className="custom-toggle ml-5">
                  <input
                    id="two2faStatus"
                    type="checkbox"
                    checked={enabled}
                    onChange={(e) => {
                      if (e.target.checked) UpdateQrCode();
                      setEnabled(e.target.checked);
                    }}
                  />
                  <span className="custom-toggle-slider rounded-circle" />
                </label>
              </FormGroup>
              {enabled && (
                <div>
                  <h4>
                    <strong>
                      To use an authenticator app go through the following
                      steps:
                    </strong>
                  </h4>
                  <ol class="list">
                    <li>
                      <p>
                        Download a two-factor authenticator app like Microsoft
                        Authenticator for
                        <a href="https://go.microsoft.com/fwlink/?Linkid=825072">
                          Android
                        </a>{" "}
                        and
                        <a href="https://go.microsoft.com/fwlink/?Linkid=825073">
                          iOS
                        </a>{" "}
                        or Google Authenticator for
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl=en">
                          Android
                        </a>{" "}
                        and
                        <a href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8">
                          iOS
                        </a>
                        .
                      </p>
                    </li>
                    <li>
                      <p>
                        Scan the QR Code into your two factor authenticator app.
                        Spaces and casing do not matter.
                      </p>
                      {QRCodeURL ? (
                        <img src={QRCodeURL} />
                      ) : (
                        <Spin size="large" />
                      )}
                    </li>
                    <li>
                      <p>
                        Once you have scanned the QR code or input the key
                        above, your two factor authentication app will provide
                        you with a unique code. Enter the code in the
                        confirmation box below.
                      </p>
                      <FormGroup>
                        <Input
                          value={verificationCode}
                          onChange={({ target: { value } }) =>
                            setVerificationCode(value)
                          }
                        />
                      </FormGroup>
                    </li>
                  </ol>
                </div>
              )}
              <FormGroup>
                <Button
                  color="primary"
                  isLoading={isLoading}
                  onClick={onSubmit}
                >
                  Update
                </Button>
              </FormGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}