import _ from "lodash";
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  Label,
  Input,
  Button,
  Table,
  ModalBody,
} from "reactstrap";
import WebAPI from "services/WebAPI";

export default function Channel({ open, OnClose }) {
  const [row, setRow] = useState([]);
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [prio, setPrio] = useState(null)
  const [isAdd, setAdd] = useState(false);

  useEffect(() => {
    loadChannels();
  }, []);

  const loadChannels = () => {
    setLoading(true);
    WebAPI.get("/UpgradeManager/FetchAllPackageChannels")
      .then(({ data: res }) => {
        if (res.status) setRow(_.orderBy(res.result, 'Priority', 'desc'));
      })
      .finally(() => setLoading(false));
  };

  const addChannel = () => {
    setLoading(true);
    WebAPI.post("/UpgradeManager/AddorUpdatePackageChannel", { Channel: name, Priority: parseInt(prio, 10) })
      .then(({ data: res }) => {
        if (res.status) loadChannels();
      })
      .finally(() => setLoading(false));
    setName("");
    setPrio(null)
  };

  const deleteChannel = ({ PackageChannelId }) => {
    setLoading(true);
    WebAPI.post(
      "/UpgradeManager/DeletePackageChannel?channelId=" + PackageChannelId
    )
      .then(({ data: res }) => {
        if (res.status) loadChannels();
      })
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <Modal isOpen={open} toggle={OnClose}>
        <ModalHeader style={{ paddingBottom: 0 }} toggle={OnClose}>Rollout Channels</ModalHeader>
        <ModalBody style={{ padding: 20 }}>
          <Table>
            <thead>
              <tr>
                <th>Channel Name</th>
                <th>Priority</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {row.map((i) => (
                <tr>
                  <td>{i.Channel}</td>
                  <td>{i.Priority}</td>
                  <td>
                   {i.Channel!=="stable" && (
                    <Button
                      color="danger"
                      disabled={loading}
                      outline
                      size="sm"
                      onClick={() => deleteChannel(i)}
                    >
                      <i class="fas fa-trash" />
                    </Button>
                   )} 
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading ? (
            <span className="spinner-border spinner-border-sm" />
          ) : (
            <div style={{ display: "flex", minWidth: "100%", justifyContent: 'flex-start', alignItems: 'center', padding: 10 }}>
              <Button
                style={{ padding: 10 }}
                outline
                color="primary"
                onClick={() => setAdd(!isAdd)}
              >
                {isAdd ? <i class="fas fa-times" /> : <i class="fas fa-plus" />}
              </Button>
              {isAdd && <>
                <Input
                  placeholder="Channel Name"
                  type="text"
                  value={name}
                  style={{ width: 200, marginRight: 10 }}
                  onChange={(e) => setName(e.target.value)}
                />
                <Input
                  placeholder="Priority"
                  value={prio}
                  type="number"
                  min={1}
                  onChange={(e) => setPrio(e.target.value)}
                />
                <Button
                  style={{ padding: 10, marginLeft: "2%" }}
                  color="primary"
                  disabled={!name || !prio || row.map(r => `${r.Priority}`).includes(prio)}
                  onClick={addChannel}
                >
                  <i class="fas fa-save" />
                </Button>
              </>}
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
