import ListSelector from "components/Configuration/ListSelector"
import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { CardBody, Container, Row, Card, Col, FormGroup, Label, Input, Modal, Spinner, Alert } from "reactstrap"
import Button from "components/Inputs/TButton"
import CopyToEndpoints from "components/Configuration/CopyToEndpoints"
import WebAPI from 'services/WebAPI'
import _ from 'lodash'
import { alertActions } from "redux/_actions";
import AlertMessage from "components/Alert";


export default function Configuration(props) {

  const { endpointSettings = false, globalSettings = false } = props
  const [config, setConfig] = useState({
    Id: 0,
    Email: '',
    FolderExclusion: [],
    FolderProtection: [],
    WhiteList: [],
    UserAutoProtect: false,
    AdvancedProtection: false,
    DriverDetectionMode: false,
    ExtMonitor: false,
    NativeProtection: false,
    AutoProtectShares: false,
    DefaultSettingMode: false,
    LearningMode: false,
    SubFolderAutoProtect:false,
  })

  const [currentConfig, setCurrentConfig] = useState(config)
  const [loading, setLoading] = useState(false)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [channels, setChannels] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    loadSettings()
  }, [props.id])

  const loadSettings = () => {
    if (loading)
      return
    setLoading(true)

    const url = globalSettings ? '/Customers/GetGlobalSettings' : `/${endpointSettings ? 'Endpoints' : 'Customers'}/Settings/${props.id}`
    loadChannels()
    WebAPI
      .get(url)
      .then(({ data: res }) => {
        if (res.status) {
          setConfig(res.data.settings)
          setCurrentConfig(res.data.settings)
        }
      })
      .finally(() => setLoading(false))
  }
  const loadChannels = () => {
    WebAPI.get("/UpgradeManager/FetchAllPackageChannels").then(
      ({ data: res }) => {
        if (res.status) setChannels(_.orderBy(res.result,'Priority',"desc"));
      }
    );
  };

  const [updating, setUpdating] = useState(false)

  const setSettings = () => {
    if (updating || loading)
      return
    setUpdating(true)
    const url = globalSettings ? '/Customers/SetGlobalSettings' : `/${endpointSettings ? 'Endpoints' : 'Customers'}/SetSettings/${props.id}`
    WebAPI
      .post(url, { settings: config, applyToEndpoints: applyToExisiting })
      .then(({ data: res }) => {
        setCurrentConfig(config)
        dispatch(alertActions.success('Settings Updated Successfully.'))
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 3000);
      })
      .finally(() => setUpdating(false))
  }

  useEffect(() => {
    checkAndShowUnsavedChangeWarning()
  }, [config, currentConfig])

  const [applyToExisiting, setApplyToExisiting] = useState(false)
  const [copyModal, setCopyModal] = useState(false)

  const updateConfig = (configKey, newValue) => {
    const newConfig = { ...config }
    newConfig[configKey] = newValue
    setConfig(newConfig)
  }

  const checkAndShowUnsavedChangeWarning = () => {
    if (!_.isEqual(config, currentConfig))
      setUnsavedChanges(true)
    else
      setUnsavedChanges(false)
  }

  const { role = "Partner", theme = "white" } = useSelector((state) => state.authentication.user)

  return (
    <Container fluid>
      <Row>
        <Col>
          <Card>
            <CardBody>
              {!copyModal && <AlertMessage />}
              {loading && (<div className="allign-center" style={{ height: 'calc(100% - 3rem)', width: 'calc(100% - 3rem)', background: 'rgba(46, 109, 164, 0.2)', position: 'absolute', zIndex: 10 }}><Spinner type="border" color="primary" /></div>)}
              <Row style={{ opacity: loading ? 0.15 : 1 }}>
               {globalSettings && ' ' || (
                  <Col>
                    <Row>
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="email"><strong>Fallback email</strong></Label>
                          <Input type="email" size="sm" value={config.Email} onChange={({ target: { value } }) => updateConfig('Email', value)} />
                        </FormGroup>
                        <FormGroup>
                          <Label for="email"><strong>{endpointSettings? 'Upgrade Channel' : 'Default Upgrade Channel'}</strong></Label>
                          <Input
                            id="type"
                            type="select"
                            value={config.PackageChannelType}
                            placeholder="choose "
                            size="sm"
                            onChange={(e) => updateConfig('PackageChannelType', e.target.value)}
                          >{console.log("config",config.PackageChannelType,channels)}
                            {![...channels.map(i => i.Channel), 'inherit'].includes(config.PackageChannelType) && (<option value={config.PackageChannelType}>{config.PackageChannelType}</option>)}
                            {endpointSettings&&<option value='inherit'>inherit</option>}
                            {channels.map((i)=><option value={i.Channel}>{i.Channel}</option>)}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col xs={{ offset: 1, size: 7 }}>
                        <strong> Configuration </strong>
                        <div style={{marginTop:5}} />
                        <FormGroup check inline style={{ minWidth: 160 }}>
                          <Input type="checkbox" checked={config.UserAutoProtect} onChange={({ target: { checked: value } }) => updateConfig('UserAutoProtect', value)} />
                          <Label check>User Auto Protect</Label>
                        </FormGroup>
                        <FormGroup check inline style={{ minWidth: 160 }}>
                          <Input type="checkbox" checked={config.AutoProtectShares} onChange={({ target: { checked: value } }) => updateConfig('AutoProtectShares', value)} />
                          <Label check>Shares Auto Protect</Label>
                        </FormGroup>
                        <FormGroup check inline style={{ minWidth: 160 }}>
                          <Input type="checkbox" checked={config.DefaultSettingMode} onChange={({ target: { checked: value } }) => updateConfig('DefaultSettingMode', value)} />
                          <Label check>Auto Protect System Root</Label>
                        </FormGroup>
                        <FormGroup check inline style={{ minWidth: 160 }}>
                          <Input type="checkbox" checked={config.SubFolderAutoProtect} onChange={({ target: { checked: value } }) => updateConfig('SubFolderAutoProtect', value)} />
                          <Label check>Auto New Sub Folders</Label>
                        </FormGroup>
                        {!endpointSettings && (
                          <FormGroup check inline style={{ minWidth: 160 }}>
                          <Input type="checkbox" checked={config?.SupressPopUp} onChange={({ target: { checked: value } }) => updateConfig('SupressPopUp', value)} />
                          <Label check>Suppress Pop-ups</Label>
                        </FormGroup>
                        )}
                        {
                          role === "admin" && (
                            <>
                              <FormGroup check inline style={{ minWidth: 160 }}>
                                <Input type="checkbox" checked={config.AdvancedProtection} onChange={({ target: { checked: value } }) => updateConfig('AdvancedProtection', value)} />
                                <Label check>Cloud Drive Protection</Label>
                              </FormGroup>
                          </>
                          )
                        }

                        <FormGroup check inline style={{ minWidth: 160 }}>
                          <Input type="checkbox" checked={config.DriverDetectionMode} onChange={({ target: { checked: value } }) => updateConfig('DriverDetectionMode', value)} />
                          <Label check>Driver Detection</Label>
                        </FormGroup>
                        {
                          (role === "admin" || role === "partner") && (
                            <>
                              <FormGroup check inline style={{ minWidth: 160 }}>
                                <Input type="checkbox" disabled={!config.DriverDetectionMode} checked={config.LearningMode} onChange={({ target: { checked: value } }) => updateConfig('LearningMode', value)} />
                                <Label check>Monitor Mode</Label>
                              </FormGroup>
                              <FormGroup check inline style={{ minWidth: 160 }}>
                                <Input type="checkbox" disabled={!config.DriverDetectionMode} checked={config.NativeProtection} onChange={({ target: { checked: value } }) => updateConfig('NativeProtection', value)} />
                                <Label check>Native File Protection</Label>
                              </FormGroup>
                              <FormGroup check inline style={{ minWidth: 160 }}>
                                <Input type="checkbox" disabled={!config.DriverDetectionMode} checked={config.ExtMonitor} onChange={({ target: { checked: value } }) => updateConfig('ExtMonitor', value)} />
                                <Label check>Extension Change Monitor</Label>
                              </FormGroup>
                            </>
                          )
                        }
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
              {unsavedChanges && (
                <Row>
                  <Col>
                    <Alert color="warning">There are unsaved changes in this page! Please save if required.</Alert>
                  </Col>
                </Row>
              )}
              <Row style={{ opacity: loading ? 0.15 : 1, marginTop: 0 }}>
                <Col className="allign-center" style={{ justifyContent: 'flex-end' }} >
                  {endpointSettings && (
                    <Button color="primary" size="" outline onClick={() => setCopyModal(true)}>Copy to Endpoints</Button>
                  ) || (globalSettings && ' ' || (
                    <FormGroup check inline>
                      <Input type="checkbox" value={applyToExisiting} onChange={({ target: { checked: value } }) => setApplyToExisiting(value)} />
                      <Label check>Apply to existing endpoints</Label>
                    </FormGroup>
                  )
                    )}
                  <Button color="primary" style={{ float: 'right' }} onClick={setSettings} isLoading={updating}>Save</Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row style={{ marginTop: 25, marginBottom:25 }}>
        {endpointSettings && (
          <Col xs={endpointSettings ? 4 : 6}>
            <ListSelector title="Folder Protection" list={config.FolderProtection} onUpdate={v => updateConfig('FolderProtection', v)} />
          </Col>
        )}
        <Col xs={endpointSettings ? 4 : 6}>
          <ListSelector title="Folder Exclusion" list={config.FolderExclusion} onUpdate={v => updateConfig('FolderExclusion', v)} isGlobal={globalSettings} />
        </Col>
        <Col xs={endpointSettings ? 4 : 6}>
          <ListSelector title="Process Allow List" list={config.WhiteList} onUpdate={v => updateConfig('WhiteList', v)} isGlobal={globalSettings} />
        </Col>
      </Row>


      <Modal
        isOpen={copyModal}
        toggle={() => setCopyModal(false)}
        size="xl"
        backdrop="static"
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              dispatch(alertActions.clear())
              setCopyModal(false)
            }}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <CopyToEndpoints
            config={config}
          />
        </div>
      </Modal>
    </Container>
  )
}