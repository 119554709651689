import { Button, Row, Col, Input, Card, CardHeader, CardBody, Modal, FormGroup, Label, UncontrolledTooltip } from "reactstrap";

import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { useState } from "react";

import _ from 'lodash'
import paginationFactory,
{ PaginationProvider, SizePerPageDropdownStandalone, PaginationListStandalone }
  from 'react-bootstrap-table2-paginator';


function SimpleAddModal(props) {
  const [ addValue, setAddValue ] = useState('')
  const [ error, setError ] = useState(null)

  const handleAddButton = () => {
    if (addValue.length < 2)
      setError('Invalid input')
    else {
      const { onUpdate } = props
      if(onUpdate){
        onUpdate(addValue)      
      }
    }
  }
  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={props.isOpen}
      toggle={() => props.setOpen(false)}
    >
      <div className="modal-header" style={{ borderBottom: '.1px solid #8898aa' }}>
        <h4 className="modal-title" id="modal-title-default">
          Add path to list
        </h4>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => props.setOpen(false)}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Row>
          <Col>
            <FormGroup>
              <Label>Path</Label>
              <Input value={addValue} onChange={({ target: { value } }) => setAddValue(value)} />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <div className="modal-footer">
        <Button color="primary" type="button" onClick={handleAddButton} >
          Add
        </Button>
      </div>
    </Modal>
  )
}

const ExclusionInfo = ({ type }) => {
  switch (type) {
    case "Folder Exclusion":
      return <><i class="fas fa-info-circle" id="folder_exclusion_tooltip" style={{ marginLeft: 10, fontSize: 15, opacity: 0.5 }} />
        <UncontrolledTooltip
          placement="right"
          target="folder_exclusion_tooltip"
          className="custom-info-tooltip"
          autohide={false}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
            <i class="fas fa-info-circle" style={{ marginRight: 10, fontSize: 16 }} />
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Example Folder Exclusions</p>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <p style={{ margin: 0, fontSize: 13 }}>False Positive Generated</p>
            <ul style={{ opacity: 0.6, marginBottom: 3, fontSize: 12 }}>
              <li>C:\Users\Cashier.runelite\repository2</li>
            </ul>
            <p style={{ margin: 0, fontSize: 13 }}>Options to deal with false positive</p>
            <ol style={{ opacity: 0.6, margin: 0, fontSize: 12 }}>
              <li>  \repository2\ - Protect any folder named repository2 but exclude all subfolders </li>
              <li>  repository2 – Exclude every folder path containing folder repository2</li>
              <li>  C:\Users\Cashier.runelite\repository2 - exclude only repository2 and all subfolders by exact path</li>
            </ol>
          </div>
        </UncontrolledTooltip></>

    case "Process Allow List":
      return <><i class="fas fa-info-circle" id="process_allow_list_tooltip" style={{ marginLeft: 10, fontSize: 15, opacity: 0.5 }} />
        <UncontrolledTooltip
          placement="right"
          target="process_allow_list_tooltip"
          className="custom-info-tooltip"
          autohide={false}
        >
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
            <i class="fas fa-info-circle" style={{ marginRight: 10, fontSize: 16 }} />
            <p style={{ marginBottom: 0, fontWeight: 'bold' }}>Example Process Allow list</p>
          </div>
          <div style={{ paddingLeft: 10 }}>
            <p style={{ margin: 0, fontSize: 13 }}>False Positive Generated</p>
            <ul style={{ opacity: 0.6, marginBottom: 3, fontSize: 12 }}>
              <li>C:\Users\Cashier.runelite\repository2.exe</li>
            </ul>
            <p style={{ margin: 0, fontSize: 13 }}>Options to deal with false positive</p>
            <ol style={{ opacity: 0.6, margin: 0, fontSize: 12 }}>
              <li>  repository2 - Exclude the process containing repository2 </li>
              <li>  C:\Users\Cashier.runelite\repository2.exe - Exclude process only by exact path</li>
            </ol>
          </div>
        </UncontrolledTooltip></>

    default:
      return null
  }
}

export default function ListSelector(props) {

  const [modalShown, setModalShown] = useState(false)

  const columns = [
    {
      dataField: 'path', text: 'Path', style: { whiteSpace: 'normal' }, headerStyle: { padding: "10px", display: 'flex', alignItems: 'center', justifyContent:'space-evenly' }, filter: textFilter({
        style: {
          marginTop: 5,
          marginLeft: 10,
          width:'100%',
        },
        placeholder: 'Search'
      })
    },
  ]

  const paginationOptions = {
    custom: true,
    withFirstAndLast: true,
    totalSize: props.list ? props.list.length : 0,
    paginationSize: 3,
  };

  let tableRef = null;

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    headerColumnStyle: {
      paddingBottom:25
    }
  }

  // Methods
  const handleDelete = () => {
    const selected = tableRef.selectionContext.selected
    const list = props.list
    const newList = _.difference(list, selected)
    if (props.onUpdate)
      props.onUpdate(newList)
  }

  const handleAdd = (listToAdd = []) => {
    const { list } = props
    if (!Array.isArray(listToAdd))
      listToAdd = [listToAdd,]
    const newList = _.union(list, listToAdd)
    if (props.onUpdate)
      props.onUpdate(newList)
    setModalShown(false)
  }

  return (
    <div style={{ padding: 5 }}>
      <Row>
        <Col>
          <Card style={{ height:'100%'}}>
            <CardHeader>
              <Row>
                <Col style={{ display: 'flex', alignItems: 'center' }}><h3>{props.title}</h3>
                  {props.isGlobal && <ExclusionInfo type={props.title} />}
                </Col>
                <Col><Button color="primary" size="sm" style={{ float: 'right' }} onClick={() => setModalShown(true)}>Add</Button></Col>
              </Row>
            </CardHeader>
            <CardBody>
              <ToolkitProvider
                keyField="path"
                data={props.list?.map(r => ({ path: r })) || []}
                columns={columns}
              >
                {
                  props => (
                    <PaginationProvider
                      pagination={paginationFactory(paginationOptions)}
                    >
                      {
                        ({
                          paginationProps,
                          paginationTableProps
                        }) => (
                          <div>
                            <Row style={{marginBottom:5}}>
                              <Col xs="10" className="local-text" style={{ display: 'flex', alignItems: 'center' }}>
                                <SizePerPageDropdownStandalone
                                  // className="common-page-size-selector ml-2"
                                  {...paginationProps}
                                />
                              </Col>
                              <Col xs={2}>
                                <Button outline onClick={() => handleDelete()} style={{ margin: '5px 0px', float: "right" }}>
                                  <i class="far fa-trash-alt" />
                                </Button>
                              </Col>
                            </Row>
                            <BootstrapTable
                              ref={n => tableRef = n}
                              selectRow={selectRow}
                              {...props.baseProps}
                              {...paginationTableProps}
                              noDataIndication="No Data"
                              filter={filterFactory()}
                            />
                            <Row className="mt-2">
                              <Col>
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </div>
                        )}
                    </PaginationProvider>
                  )
                }

              </ToolkitProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <SimpleAddModal
        isOpen={modalShown}
        setOpen={setModalShown}
        onUpdate={handleAdd}
      />
    </div>
  )
}