import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
} from "reactstrap";

import WebAPI from "services/WebAPI";
import AlertMessage from "components/Alert";
import Button from "components/Inputs/TButton";
import { alertActions } from "redux/_actions";

const intial_state = {
  serverReportId: 0,
  desktopReportId: 0,
  reportActive: true,
  NotificationEmails: "",
  serverCycle: "daily",
  desktopCycle: "weekly",
  serverWeeklyDay: 1,
  desktopWeeklyDay: 1,
  serverMonthlydate: 1,
  desktopMonthlydate: 1,
  reportTime: "10:00",
};

const CustomerReportSettings = () => {
  let history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const { role = "partner" } = useSelector(
    (state) => state.authentication.user
  );

  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);

  const [formState, setFormState] = useState({ ...intial_state });

  useEffect(() => {
    Dates();
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const resp = await WebAPI.get(
        // `/Report/FetchPartnerScheduledReports?PartnerId=${id}`
        "/Report/FetchScheduledReports"
      );
      if (resp.data.status && resp.data.data.length !== 0) {
        const { data: _data } = resp.data;
        let data = _data;
        if (role === "admin") {
          data = _data.filter((i) => i.PartnerId === 0);
        }
        if (data.length !== 0) {
          const server = data.filter(
            (i) => i.ReportType === "OfflineServer"
          )[0];
          const desktop = data.filter(
            (i) => i.ReportType === "OfflineDesktop"
          )[0];
          const newData = {
            serverReportId: server?.ReportId,
            desktopReportId: desktop?.ReportId,
            reportActive: data[0]?.ToggleEmailReport,
            NotificationEmails: data[0]?.NotificationEmailList,
            serverCycle: server?.Interval,
            desktopCycle: desktop?.Interval,
            serverWeeklyDay:
              server?.Interval === "weekly"
                ? new Date(server?.DefaultTime).getDay()
                : 1,
            desktopWeeklyDay:
              desktop?.Interval === "weekly"
                ? new Date(desktop?.DefaultTime).getDay()
                : 1,
            serverMonthlydate:
              server?.Interval === "monthly"
                ? new Date(server?.DefaultTime).getDate()
                : 1,
            desktopMonthlydate:
              desktop?.Interval === "monthly"
                ? new Date(desktop?.DefaultTime).getDate()
                : 1,
            reportTime: `${
              (new Date(server?.DefaultTime).getHours() < 10 ? "0" : "") +
              new Date(server?.DefaultTime).getHours()
            }:${
              (new Date(server?.DefaultTime).getMinutes() < 10 ? "0" : "") +
              new Date(server?.DefaultTime).getMinutes()
            }`,
          };
          setFormState(newData);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const Dates = () => {
    let date = [];
    for (let i = 1; i < 29; i += 1) {
      date.push(i);
    }
    setDates(date);
  };

  const [invalidFormState, setInvalidFormState] = useState({
    reportActive: false,
    NotificationEmails: false,
  });

  const handleFormValueChange = ({ target: { id, value, checked } }) => {
    if (id === "reportActive") value = checked;
    invalidFormState[id] = false;
    formState[id] = value;
    setFormState({ ...formState });
    setInvalidFormState({ ...invalidFormState });
  };

  const prepareTime = (type) => {
    let time = new Date();
    const d = new Date();
    d.setDate(d.getDate() - d.getDay());
    const t = formState.reportTime.split(":");
    switch (type) {
      case "server": {
        if (formState.serverCycle === "daily") {
          d.setHours(t[0], t[1]);
          time = d;
          break;
        } else if (formState.serverCycle === "weekly") {
          d.setDate(d.getDate() + parseInt(formState.serverWeeklyDay));
          d.setHours(t[0], t[1]);
          time = d;
          break;
        } else if (formState.serverCycle === "monthly") {
          time = new Date(
            d.getFullYear(),
            d.getMonth(),
            formState.serverMonthlydate,
            t[0],
            t[1]
          );
        }
        break;
      }
      case "desktop": {
        if (formState.desktopCycle === "daily") {
          d.setHours(t[0], t[1]);
          time = d;
        } else if (formState.desktopCycle === "weekly") {
          d.setDate(d.getDate() + parseInt(formState.desktopWeeklyDay));
          d.setHours(t[0], t[1]);
          time = d;
        } else if (formState.desktopCycle === "monthly") {
          time = new Date(
            d.getFullYear(),
            d.getMonth(),
            formState.desktopMonthlydate,
            t[0],
            t[1]
          );
        }
        break;
      }
      default:
        return;
    }
    return time;
  };

  const handleSave = async () => {
    try {
      let serverReq = {
        ReportId: formState.serverReportId,
        ReportType: "OfflineServer",
        Interval: formState.serverCycle,
        //PartnerId: parseInt(id,10),
        DefaultTime: new Date(prepareTime("server")).toISOString(),
        NotificationEmailList: formState.NotificationEmails,
        ToggleEmailReport: formState.reportActive,
      };
      let desktopReq = {
        ReportId: formState.desktopReportId,
        ReportType: "OfflineDesktop",
        Interval: formState.desktopCycle,
        //PartnerId: parseInt(id,10),
        DefaultTime: new Date(prepareTime("desktop")).toISOString(),
        NotificationEmailList: formState.NotificationEmails,
        ToggleEmailReport: formState.reportActive,
      };

      if (role === "admin") {
        serverReq = { ...serverReq, ...{ PartnerId: 0 } };
        desktopReq = { ...desktopReq, ...{ PartnerId: 0 } };
      }
      const serverResp = await WebAPI.post(
        "/Report/SaveScheduledReports",
        serverReq
      );

      if (serverResp?.data?.status) {
        try {
          const desktopResp = await WebAPI.post(
            "/Report/SaveScheduledReports",
            desktopReq
          );

          if (desktopResp?.data?.status) {
            dispatch(alertActions.success("Successfully Updated"));
            fetchData();
            setTimeout(() => {
              dispatch(alertActions.clear());
            }, 2500);
          }
        } catch (error) {
          console.log("errord", error);
        }
      }
    } catch (err) {
      console.log("errs", err);
    }
  };

  return (
    <>
      <Container className="container-modal" fluid>
        <Row>
          <Col xs="8">
            <Card className="shadow">
              <CardBody>
                <Form role="form">
                  <FormGroup>
                    <AlertMessage />
                  </FormGroup>
                  <FormGroup>
                    <Label for="NotificationEmails">
                      Emails (comma seperated list)
                    </Label>
                    <Input
                      id="NotificationEmails"
                      value={formState.NotificationEmails}
                      invalid={invalidFormState.NotificationEmails}
                      onChange={handleFormValueChange}
                      type="text"
                    />
                    <FormFeedback>
                      Please enter a emails for reports
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      id="reportActive"
                      checked={formState.reportActive}
                      onChange={handleFormValueChange}
                      type="checkbox"
                    />
                    {""}
                    <Label for="reportActive" check>
                      Enabled
                    </Label>
                  </FormGroup>
                  <Row style={{ marginTop: 15 }}>
                    <Col xs={4}>
                      <FormGroup>
                        <Label for="serverCycle">
                          Report cycle for Servers
                        </Label>
                        <Input
                          id="serverCycle"
                          value={formState.serverCycle}
                          onChange={handleFormValueChange}
                          type="select"
                        >
                          {" "}
                          <option value={"daily"}>Daily</option>
                          <option value={"weekly"}>Weekly</option>
                          <option value={"monthly"}>Monthly</option>
                        </Input>
                        <FormFeedback>
                          Please select a cycle period for servers
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {formState.serverCycle === "weekly" && (
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="serverWeeklyDay">Select a day</Label>
                          <Input
                            id="serverWeeklyDay"
                            value={formState.serverWeeklyDay}
                            onChange={handleFormValueChange}
                            type="select"
                          >
                            {" "}
                            <option value={1}>Monday</option>
                            <option value={2}>Tuesday</option>
                            <option value={3}>Wednesday</option>
                            <option value={4}>Thursday</option>
                            <option value={5}>Friday</option>
                            <option value={6}>Saturday</option>
                            <option value={0}>Sunday</option>
                          </Input>
                          <FormFeedback>
                            Please select a cycle period for servers
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {formState.serverCycle === "monthly" && (
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="serverMonthlydate">Select a date</Label>
                          <Input
                            id="serverMonthlydate"
                            value={formState.serverMonthlydate}
                            onChange={handleFormValueChange}
                            type="select"
                          >
                            {dates.map((i) => (
                              <option value={i}>{i}</option>
                            ))}
                          </Input>
                          <FormFeedback>
                            Please select a date for server monthly cycle
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <FormGroup>
                        <Label for="desktopCycle">
                          Report cycle for Desktops
                        </Label>
                        <Input
                          id="desktopCycle"
                          value={formState.desktopCycle}
                          onChange={handleFormValueChange}
                          type="select"
                        >
                          {" "}
                          <option value={"daily"}>Daily</option>
                          <option value={"weekly"}>Weekly</option>
                          <option value={"monthly"}>Monthly</option>
                        </Input>
                        <FormFeedback>
                          Please select a cycle period for Desktops
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {formState.desktopCycle === "weekly" && (
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="desktopWeeklyDay">Select a day</Label>
                          <Input
                            id="desktopWeeklyDay"
                            value={formState.desktopWeeklyDay}
                            onChange={handleFormValueChange}
                            type="select"
                          >
                            {" "}
                            <option value={1}>Monday</option>
                            <option value={2}>Tuesday</option>
                            <option value={3}>Wednesday</option>
                            <option value={4}>Thursday</option>
                            <option value={5}>Friday</option>
                            <option value={6}>Saturday</option>
                            <option value={0}>Sunday</option>
                          </Input>
                          <FormFeedback>
                            Please select a day for desktop weekly cycle
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {formState.desktopCycle === "monthly" && (
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="desktopMonthlydate">Select a date</Label>
                          <Input
                            id="desktopMonthlydate"
                            value={formState.desktopMonthlydate}
                            onChange={handleFormValueChange}
                            type="select"
                          >
                            {dates.map((i) => (
                              <option key={i} value={i}>
                                {i}
                              </option>
                            ))}
                          </Input>
                          <FormFeedback>
                            Please select a date for Desktop monthly cycle
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <FormGroup>
                        <Label for="reportTime">Time</Label>
                        <Input
                          type="time"
                          name="time"
                          id="reportTime"
                          value={formState.reportTime}
                          onChange={handleFormValueChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button
                        className=" mt-4 common-btn"
                        isLoading={loading}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerReportSettings;
