import React from "react";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { useLocation } from "react-router-dom";
import styles from "./styles.module.scss";

function BreadCrumbNavigation() {
  const { pathname } = useLocation();
  const _path = pathname.split("/").splice(1);

  // filtering id from url
  const special = [
    /^add_/,
    /^edit_/,
    /^list_/,
    /^new_/,
    /_details$/,
    /_settings$/,
    /endpoints_under_license/,
  ];
  const to_render = [];
  _path.every((path, i) => {
    to_render.push(path);
    return !special.map((r) => path.match(r)).filter((n) => n).length;
  });

  return (
    <div>
      <Breadcrumb
        tag="div"
        listTag="div"
        style={{ float: "right", marginRight: 30, marginTop: "1rem" }}
        listClassName={styles.breadcrumb_wrapper}
      >
        <BreadcrumbItem tag="a" href="/#/">
          Home
        </BreadcrumbItem>
        {to_render.map((path, i) => (
          <BreadcrumbItem
            href={
              to_render.length === i + 1
                ? null
                : `/#/${_path.slice(0, i + 1).join("/")}`
            }
            tag="a"
            active={to_render.length === i + 1}
            style={{ textTransform: "capitalize" }}
          >
            {path.replace(/_/g, " ")}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default BreadCrumbNavigation;
