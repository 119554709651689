/*eslint-disable*/
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import _ from 'lodash'
import { Helmet } from "react-helmet";

// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button
} from "reactstrap";

import { getSideBarClass } from "../../utils/getGlobalStyles";
import menuBarItems from "utils/getMenuBar";

import routes from "routes.js";


const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState(localStorage.getItem('menuCollapse') === 'true' ? true : false);

  const { theme, role } = useSelector(state => state.authentication.user) || { theme: "white" }
  const [activeItem, setActive] = useState(null)
  const [title,setTitle]= useState('Canauri Portal')

  const { location } = props
  useEffect(()=>{
    getCurrentActive()
  },[location])

  const handleToggle = () => {
    setCollapseOpen(!collapseOpen)
    localStorage.setItem('menuCollapse', !collapseOpen ? 'true' : 'false')
  }

  const LinksForMenu = (routes) => {
    const children = []
    children.push(
      <NavItem key="collapse" className="collapse-link" >
        <NavLink
          onClick={(e) => {
            e.preventDefault()
            handleToggle()
          }
          }
          style={{ cursor: 'pointer' }}
        >
          <svg width="47" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={collapseOpen ? {} : { transform: 'scaleX(-1)' }}>
            <path fillRule="evenodd" clipRule="evenodd" d="M10.4424 2.05752C10.5006 2.11558 10.5468 2.18455 10.5783 2.26048C10.6098 2.33641 10.626 2.41781 10.626 2.50002C10.626 2.58223 10.6098 2.66363 10.5783 2.73956C10.5468 2.81549 10.5006 2.88446 10.4424 2.94252L3.38365 10L10.4424 17.0575C10.5598 17.1749 10.6257 17.3341 10.6257 17.5C10.6257 17.666 10.5598 17.8252 10.4424 17.9425C10.325 18.0599 10.1659 18.1258 9.9999 18.1258C9.83393 18.1258 9.67475 18.0599 9.5574 17.9425L2.0574 10.4425C1.99919 10.3845 1.95301 10.3155 1.92151 10.2396C1.89 10.1636 1.87378 10.0822 1.87378 10C1.87378 9.91781 1.89 9.83641 1.92151 9.76048C1.95301 9.68455 1.99919 9.61558 2.0574 9.55752L9.5574 2.05752C9.61545 1.99931 9.68442 1.95314 9.76035 1.92163C9.83629 1.89012 9.91769 1.8739 9.9999 1.8739C10.0821 1.8739 10.1635 1.89012 10.2394 1.92163C10.3154 1.95314 10.3843 1.99931 10.4424 2.05752Z" fill="#CBCBCB" />
            <path fillRule="evenodd" clipRule="evenodd" d="M15.4424 2.05752C15.5006 2.11558 15.5468 2.18455 15.5783 2.26048C15.6098 2.33641 15.626 2.41781 15.626 2.50002C15.626 2.58223 15.6098 2.66363 15.5783 2.73956C15.5468 2.81549 15.5006 2.88446 15.4424 2.94252L8.38365 10L15.4424 17.0575C15.5005 17.1156 15.5466 17.1846 15.5781 17.2605C15.6095 17.3365 15.6257 17.4178 15.6257 17.5C15.6257 17.5822 15.6095 17.6636 15.5781 17.7395C15.5466 17.8154 15.5005 17.8844 15.4424 17.9425C15.3843 18.0006 15.3153 18.0467 15.2394 18.0782C15.1635 18.1096 15.0821 18.1258 14.9999 18.1258C14.9177 18.1258 14.8363 18.1096 14.7604 18.0782C14.6845 18.0467 14.6155 18.0006 14.5574 17.9425L7.0574 10.4425C6.99919 10.3845 6.95301 10.3155 6.92151 10.2396C6.89 10.1636 6.87378 10.0822 6.87378 10C6.87378 9.91781 6.89 9.83641 6.92151 9.76048C6.95301 9.68455 6.99919 9.61558 7.0574 9.55752L14.5574 2.05752C14.6155 1.99931 14.6844 1.95314 14.7604 1.92163C14.8363 1.89012 14.9177 1.8739 14.9999 1.8739C15.0821 1.8739 15.1635 1.89012 15.2394 1.92163C15.3154 1.95314 15.3843 1.99931 15.4424 2.05752Z" fill="#CBCBCB" />
          </svg>
          {collapseOpen ? "Collapse" : ""}
        </NavLink>
      </NavItem>
    )
    children.push(...createLinks(routes))
    children.push(
      <NavItem key="collapse">
        <NavLink
          href="https://www.canauri.com/msp-partners/materials/"
          target="_blank"
          style={{ height: 50 }}
        >
          <svg width="47" height="20" fill="#CBCBCB" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
          </svg>
          {collapseOpen ? "Resources" : ""}
        </NavLink>
      </NavItem>
    )

    return children
  }

  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      return (<div>
        <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
        <NavItem key={key}>
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            activeClassName="active"
            style={{ height: 50 }}
            target={props.target}
            active={activeItem===prop.name}
            onClick={(e)=>setTitle(prop.name)}
          >
            {prop.icon}
            {collapseOpen ? prop.name : ""}
          </NavLink>
        </NavItem>
        </div>
      );
    });
  };
  
  /* need to change this logic - (added parent field in routes page) */

  const getCurrentActive = () => {
    const { location: { pathname } } = props
    const all_matches = []
    routes.forEach(({ path, parent }) => {
      if (pathname.includes(path.split(':')[0])) {
        all_matches.push({ path, parent })
      }
    });

    if (all_matches.length) {
      const best_match = _.maxBy(all_matches, (({ path }) => path.length))
      setActive(best_match.parent ? best_match.parent : null)
    }
    else {
      setActive(null)
    }
  }

  /* -------------------------- */

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className={'navbar-vertical fixed-left cs-custom-sidebar ' + getSideBarClass(theme)}
      expand={collapseOpen ? "md" : "false"}
      expanded={collapseOpen}
      id="sidenav-main"
    >
      <Container fluid>
        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={logo.imgAlt}
              className="navbar-brand-img"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        ) : null}
        <Nav navbar>
          {LinksForMenu(menuBarItems[role])}
        </Nav>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
