import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux'
import { useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  Container
} from "reactstrap";
import CustomerForm from "./form";

import { alertActions } from 'redux/_actions/'
import WebAPI from 'services/WebAPI'

const CustomerEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [customer, setCustomer] = useState({})
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id)
      return
    dispatch(alertActions.clear())
    WebAPI
      .get('/Customers/Details/' + id)
      .then(({ data: res }) => {
        if (res.status) {
          const { Partner, CustomerId,IsActive, ...d } = res.data
          setCustomer(d)
        }
        else{
          setCustomer({})
          dispatch(alertActions.error(res.message))
        }
      })
  }, [id]);


  const updateCustomer = (data) => {
    if (loading)
      return
    setLoading(true)
    WebAPI
      .post('/Customers/Update/' + id, data)
      .then(({ data: res }) => {
        if (res.status) {
          history.push('/customers/customer_details/' + id)
        } else {
          dispatch(alertActions.error(res.message))
        }
      })
      .catch(() => dispatch(alertActions.error("Connection error")))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.go(-1)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Edit Customer
        </div>
        <Row>
          <Col xs="6">
            <Card className="shadow">
              <CardBody>
                <CustomerForm shareFormData={updateCustomer} loading={loading} customer={customer} editMode />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default CustomerEdit;