
var menuBarItems = {
  "admin": [
    {
      path: "/dashboard",
      name: "Dashboard",
      icon: <svg xmlns="http://www.w3.org/2000/svg"  width="47" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"> 
      <path d="M0 0h24v24H0z" /> 
      <path d="M4 4h6v8h-6z" stroke="#CBCBCB"/> 
      <path d="M4 16h6v4h-6z" stroke="#CBCBCB"/> 
      <path d="M14 12h6v8h-6z" stroke="#CBCBCB"/> 
      <path d="M14 12h6v8h-6z" stroke="#CBCBCB"/> 
      <path d="M14 4h6v4h-6z" stroke="#CBCBCB"/> </svg>,    
      layout: "/admin"
    },
    {
      path: "/partners",
      name: "Partners",
      icon: <svg width="47" height="20" viewBox="0 0 27 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.6495 3.67009C16.0557 3.67009 16.466 3.77901 16.7668 3.99191L18.9796 5.99004C19.5355 6.38399 20.3994 7.08238 20.9011 7.54092L22.9109 9.38176L26.4553 5.33481L20.9476 0.13962L19.6216 1.42198C19.6216 1.42198 19.178 1.3232 18.8697 1.17547L16.7923 0.179304C16.5398 0.0582231 16.2288 0 15.912 0C15.456 0 14.9863 0.120791 14.6568 0.350208L11.4434 2.57109C10.8849 2.96041 10.7611 3.72165 11.1688 4.26333L11.3593 4.51708C11.6206 4.86381 12.048 5.05326 12.4754 5.05326C12.7159 5.05326 12.956 4.99358 13.1668 4.86816L14.6915 3.90907C14.961 3.74917 15.3035 3.67009 15.6495 3.67009Z" fill="#CBCBCB" />
      <path d="M22.2924 10.8663L18.9291 7.78623C18.6369 7.55218 17.9852 7.06061 17.9852 7.06061L15.8773 5.15807C15.8326 5.14156 15.7509 5.12215 15.6498 5.12215C15.5446 5.12215 15.4752 5.14301 15.4573 5.15083L13.9287 6.11108C13.4952 6.36917 12.9925 6.50503 12.4763 6.50503C11.5663 6.50503 10.7068 6.0853 10.1765 5.38083L9.98659 5.12853C9.55096 4.54977 9.37459 3.84037 9.49011 3.1304C9.60504 2.421 9.99746 1.80111 10.5986 1.38254L10.9446 1.14357C10.4454 1.0836 9.89135 1.13574 9.48629 1.30665C9.48629 1.30665 8.80727 1.60066 8.58975 1.69133C8.08504 1.90249 7.37751 1.89931 7.37751 1.89931L5.71699 0.375366L0 5.97117L2.14994 8.10196L2.63818 7.59765C3.14759 7.07104 3.86453 6.76862 4.60528 6.76891C5.30782 6.76891 5.9739 7.03135 6.48096 7.50785L6.7123 7.7254C7.01007 8.00522 7.22994 8.33544 7.37045 8.69086C7.81461 8.80325 8.22408 9.02571 8.56388 9.34493L8.79404 9.5616C9.31874 10.0535 9.61798 10.7185 9.63561 11.4346C9.79493 11.5313 9.94573 11.6455 10.0833 11.7755L10.3161 11.9942C10.6421 12.3007 10.874 12.668 11.0104 13.0619C11.4222 13.1801 11.802 13.3939 12.1183 13.6911L12.3538 13.9115C13.3003 14.8014 13.4508 16.2089 12.787 17.263L12.9634 17.4252C13.4643 17.8846 14.2556 17.8562 14.7218 17.3624L14.9396 17.1306C15.3958 16.6466 15.3729 15.8923 14.9002 15.4311L16.5128 16.9053C17.0146 17.3638 17.8059 17.3348 18.2721 16.8404L18.4899 16.6095C18.9529 16.1185 18.9255 15.3457 18.4326 14.8854L18.8471 15.2654C19.3485 15.7243 20.1396 15.6953 20.6052 15.2014L20.8233 14.9703C21.2889 14.4758 21.2595 13.696 20.7583 13.2366L20.4082 12.9168C20.9115 13.3499 21.681 13.3166 22.1378 12.8311L22.3568 12.5996C22.8233 12.1049 22.7945 11.3251 22.2924 10.8663Z" fill="#CBCBCB" />
      <path d="M11.3372 14.9619L11.1044 14.7438C10.8645 14.5184 10.5553 14.4063 10.2461 14.4063C9.91773 14.4063 9.58939 14.5332 9.34571 14.7849L9.34159 14.7782C9.81309 14.2904 9.79457 13.5101 9.29926 13.0452L9.06705 12.8267C8.82807 12.6025 8.52177 12.4916 8.2146 12.4916C7.90389 12.4916 7.59437 12.6049 7.35421 12.8285L7.82041 12.3471C8.29308 11.859 8.27456 11.0786 7.77838 10.6134L7.54645 10.395C7.30718 10.1702 6.99912 10.059 6.69136 10.059C6.37154 10.059 6.0529 10.1786 5.81098 10.4158C6.19958 9.9263 6.16108 9.21401 5.69546 8.77632L5.46413 8.55878C5.22397 8.33342 4.91415 8.22131 4.60521 8.22131C4.27599 8.22102 3.94824 8.34819 3.70455 8.59991L1.36796 11.014C0.895882 11.5015 0.9144 12.2819 1.41117 12.7468L1.64222 12.9646C1.8809 13.1885 2.1872 13.2986 2.49349 13.2986C2.77597 13.2986 3.05787 13.2051 3.28685 13.0205L2.67103 13.6561C2.19954 14.1444 2.21835 14.9242 2.71307 15.3897L2.94617 15.6078C3.18603 15.8332 3.49585 15.9453 3.8042 15.9453C4.13342 15.9453 4.46147 15.8184 4.70486 15.5667L5.18546 15.0699C4.75777 15.5595 4.78628 16.3039 5.26601 16.7544L5.49911 16.9728C5.73867 17.1978 6.0482 17.3099 6.35743 17.3099C6.68607 17.3099 7.01441 17.1831 7.2578 16.9313L8.03029 16.1437C7.55792 16.6321 7.57614 17.4119 8.07203 17.8774L8.30484 18.0955C8.54382 18.3209 8.85335 18.433 9.16258 18.433C9.49121 18.433 9.81955 18.3061 10.0629 18.0544L11.3792 16.695C11.8513 16.2075 11.8322 15.4274 11.3372 14.9619Z" fill="#CBCBCB" />
      </svg>,    
      layout: "/admin"
    },
    {
      path: "/customers",
      name: "Customers",
      icon: <svg width="47" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6322 13.756C11.1362 13.756 11.5322 14.1641 11.5322 14.656C11.5322 15.16 11.1362 15.556 10.6322 15.556H4.44006C3.93604 15.556 3.54004 15.16 3.54004 14.656C3.54004 14.1641 3.93604 13.756 4.44006 13.756H10.6322Z" fill="#CBCBCB" />
      <path d="M12.1321 10.8281C12.1321 11.32 11.7361 11.7281 11.2321 11.7281H4.44006C3.93604 11.7281 3.54004 11.32 3.54004 10.8281C3.54004 10.3362 3.93604 9.9281 4.44006 9.9281H11.2321C11.7361 9.9281 12.1321 10.336 12.1321 10.8281Z" fill="#CBCBCB" />
      <path d="M6.42006 6.1001C6.91198 6.1001 7.32008 6.4961 7.32008 7.00012C7.32008 7.49204 6.91198 7.90014 6.42006 7.90014H4.44006C3.93604 7.90014 3.54004 7.49204 3.54004 7.00012C3.54004 6.4961 3.93604 6.1001 4.44006 6.1001H6.42006Z" fill="#CBCBCB" />
      <path d="M11.7 6.10004H13.1281L10.2001 3.17206V4.60008C10.2001 5.42794 10.872 6.10004 11.7 6.10004ZM8.40004 1.90002H3.40802C2.5201 1.90002 1.80004 2.62008 1.80004 3.508V16.492C1.80004 17.3799 2.5201 18.0999 3.40802 18.0999H10.344C10.776 18.0999 11.136 18.4 11.22 18.82C11.2321 18.8801 11.244 18.9399 11.244 19C11.244 19.4919 10.8359 19.9 10.344 19.9H3.40802C1.52394 19.9 0 18.376 0 16.492V3.508C0 1.62392 1.52416 0.0999756 3.40802 0.0999756H9.30006C9.36012 0.0999756 9.41996 0.0999755 9.48002 0.112076C9.54008 0.124176 9.59992 0.148156 9.6481 0.172136C9.69606 0.184236 9.74402 0.208216 9.7801 0.244076C9.84016 0.280156 9.88812 0.316016 9.93608 0.363976L15.9361 6.36404C15.9962 6.4241 16.056 6.49604 16.0802 6.56798C16.0923 6.58008 16.1042 6.60406 16.1042 6.61594C16.1282 6.65202 16.1403 6.68788 16.1522 6.72396C16.1643 6.74794 16.1762 6.78402 16.1883 6.81988C16.2004 6.87994 16.2004 6.93978 16.2004 6.99984V9.45988C16.2004 9.89196 15.9003 10.2519 15.4803 10.3359C15.4202 10.348 15.3604 10.3599 15.3003 10.3599C14.8084 10.3599 14.4003 9.9518 14.4003 9.45988V7.89986H11.7C9.87602 7.89986 8.40004 6.42388 8.40004 4.59986V1.90002Z" fill="#CBCBCB" />
      </svg>,        
      layout: "/admin"
    },
    {
      path: "/endpoints",
      name: "Endpoints",
      icon: <svg width="47" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2024 1H1.79501C1.35674 1 1 1.34794 1 1.77389V15.1182C1 15.5441 1.35801 15.8921 1.79501 15.8921H9.31557L8.15872 17.8398H6.69356C6.36485 17.8398 6.09603 18.1011 6.09603 18.4205C6.09603 18.7387 6.36485 19 6.69356 19H17.3064C17.6351 19 17.904 18.7387 17.904 18.4193C17.904 18.1011 17.6351 17.8398 17.3064 17.8398H15.8413L14.6844 15.8921H22.2037C22.642 15.8921 23 15.5441 23 15.1182V1.77389C23 1.34794 22.642 1 22.2037 1H22.2024ZM21.8049 2.16021H2.19252V13.4013H21.8049V2.16021Z" fill="#CBCBCB" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25"/>
      </svg>,    
      layout: "/admin",
    },  
    {
      path: "/alerts",
      name: "Alerts",
      icon: <svg width="47" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#CBCBCB" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" d="M21.438 5.089a1.002 1.002 0 0 0-.959.015c-.684.389-1.355.577-2.053.577-2.035 0-3.952-1.629-5.722-3.39-.375-.373-1.063-.373-1.438 0C9.592 3.959 7.598 5.663 5.51 5.663c-.69 0-1.351-.184-2.018-.561-.298-.166-.658-.171-.96-.012s-.501.461-.528.801c-.011.129-.944 12.872 9.683 16.041a.99.99 0 0 0 .286.042H12c.097 0 .192-.014.285-.041 10.657-3.17 9.695-15.916 9.684-16.044a1 1 0 0 0-.531-.8zm-9.452 14.842c-6.979-2.255-7.934-9.412-8.014-12.477.505.14 1.019.209 1.537.209 2.492 0 4.65-1.567 6.476-3.283 1.893 1.788 3.983 3.301 6.442 3.301.53 0 1.057-.074 1.575-.22-.074 3.065-1.021 10.217-8.016 12.47z"/> 
      </svg>,
      layout: "/admin"
    },
    {
      path: "/reports",
      name: "Reports",
      icon: <svg width="47" height="20" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 1H11.5V2.5H4C3.60218 2.5 3.22064 2.65804 2.93934 2.93934C2.65804 3.22064 2.5 3.60218 2.5 4V19C2.5 19.3978 2.65804 19.7794 2.93934 20.0607C3.22064 20.342 3.60218 20.5 4 20.5H16C16.3978 20.5 16.7794 20.342 17.0607 20.0607C17.342 19.7794 17.5 19.3978 17.5 19V8.5H19V19C19 19.7956 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7956 22 16 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7956 1 19V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1V1Z" fill="#CBCBCB" />
      <path d="M4 0.875C3.1712 0.875 2.37634 1.20424 1.79029 1.79029C1.20424 2.37634 0.875 3.1712 0.875 4V19C0.875 19.8288 1.20424 20.6237 1.79029 21.2097C2.37634 21.7958 3.1712 22.125 4 22.125H16C16.8288 22.125 17.6237 21.7958 18.2097 21.2097C18.7958 20.6237 19.125 19.8288 19.125 19V8.5C19.125 8.43096 19.069 8.375 19 8.375H17.5C17.431 8.375 17.375 8.43096 17.375 8.5V19C17.375 19.3647 17.2301 19.7144 16.9723 19.9723C16.7144 20.2301 16.3647 20.375 16 20.375H4C3.63533 20.375 3.28559 20.2301 3.02773 19.9723C2.76987 19.7144 2.625 19.3647 2.625 19V4C2.625 3.63533 2.76987 3.28559 3.02773 3.02773C3.28559 2.76987 3.63533 2.625 4 2.625H11.5C11.569 2.625 11.625 2.56904 11.625 2.5V1C11.625 0.930964 11.569 0.875 11.5 0.875H4Z" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.5 6.25V1L19 8.5H13.75C13.1533 8.5 12.581 8.26295 12.159 7.84099C11.7371 7.41903 11.5 6.84674 11.5 6.25Z" fill="#CBCBCB" />
      <path d="M11.5884 0.911612C11.5526 0.875862 11.4989 0.865167 11.4522 0.884515C11.4055 0.903863 11.375 0.949442 11.375 1V6.25C11.375 6.87989 11.6252 7.48398 12.0706 7.92938C12.516 8.37478 13.1201 8.625 13.75 8.625H19C19.0506 8.625 19.0961 8.59454 19.1155 8.54784C19.1348 8.50113 19.1241 8.44736 19.0884 8.41161L11.5884 0.911612Z" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,        
      layout: "/admin",
    },
   /*  {
      path: "/reporting",
      name: "Reporting",
      icon: <svg fill="#000000" width="47" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" id="memory-chart-bar">
      <path d="M2 2H4V18H20V20H2V2M6 16V8H10V16H6M11 16V4H15V16H11M16 16V11H20V16H16Z" fill="#CBCBCB"/>
      </svg>,        
      layout: "/admin",
    }, */
    {
      path: "/users",
      name: "Users",
      icon: <svg width="47" height="20" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0004 3.7124C9.40664 3.7124 8.32373 4.95487 8.32373 6.48801C8.32373 8.0207 9.40664 9.26384 11.0004 9.26384C12.5936 9.26384 13.6767 8.0207 13.6767 6.48801C13.6769 4.95487 12.5938 3.7124 11.0004 3.7124Z" fill="#CBCBCB"/>
      <path d="M11.0004 9.90087C10.9932 9.90087 10.9864 9.89954 10.9788 9.89954V9.90198C8.39809 9.92443 6.3125 11.9418 6.3125 14.4303C6.34828 15.1616 6.60806 15.9816 7.03051 16.4503C7.43541 16.9241 14.8171 16.8892 14.9704 16.4503C15.3929 15.9819 15.6522 15.1616 15.6882 14.4303C15.6882 11.9418 13.6029 9.92443 11.0219 9.90198V9.89954C11.015 9.89954 11.0075 9.90087 11.0004 9.90087Z" fill="#CBCBCB"/>
      <path d="M17.5063 5.99843C19.0997 5.99843 20.1824 4.75508 20.1824 3.22238C20.1824 1.68969 19.0997 0.446777 17.5063 0.446777C15.9125 0.446777 14.8298 1.68969 14.8298 3.22238C14.8298 4.75508 15.9125 5.99843 17.5063 5.99843Z" fill="#CBCBCB"/>
      <path d="M4.68868 0.446777C3.09487 0.446777 2.01196 1.68969 2.01196 3.22238C2.01196 4.75508 3.09487 5.99843 4.68868 5.99843C6.28182 5.99843 7.36472 4.75508 7.36472 3.22238C7.36472 1.68969 6.28182 0.446777 4.68868 0.446777Z" fill="#CBCBCB"/>
      <path d="M17.5278 6.6365V6.63428C17.521 6.63428 17.5138 6.63517 17.5061 6.63517C17.499 6.63517 17.4918 6.63428 17.4847 6.63428V6.6365C16.7956 6.64205 16.1425 6.79161 15.5545 7.05295C15.402 8.10408 14.8276 8.97321 14 9.48566C15.8131 10.1168 17.1854 11.6561 17.5243 13.5271C19.4654 13.5255 21.3986 13.4073 21.4759 13.1848C21.8984 12.7162 22.1577 11.8961 22.1939 11.1648C22.1944 8.67654 20.1088 6.65872 17.5278 6.6365Z" fill="#CBCBCB"/>
      <path d="M4.66964 13.5271C5.00898 11.6564 6.38123 10.1168 8.19437 9.48566C7.36702 8.97321 6.79434 8.10408 6.64168 7.05384C6.053 6.79183 5.39921 6.64205 4.70964 6.6365V6.63428C4.70253 6.63428 4.6952 6.63517 4.68808 6.63517C4.68097 6.63517 4.67386 6.63428 4.66631 6.63428V6.6365C2.08537 6.65872 0 8.67654 0 11.1648C0.0362229 11.8961 0.295561 12.7159 0.718235 13.1848C0.916683 13.4171 2.79161 13.5268 4.66964 13.5271Z" fill="#CBCBCB"/>
      </svg>,        
      layout: "/admin",
    },
    {
      path: "/upgrade_manager",
      name:"Upgrade Manager",
      icon: <svg width="47" height="20" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.9251 8.04771L10.9763 7.93371C11.0622 7.97232 11.1395 8.02859 11.2041 8.09877L10.9251 8.04771ZM10.9251 8.04771L10.9763 7.9337C10.8904 7.89506 10.7979 7.875 10.7042 7.875C10.6104 7.875 10.5179 7.89506 10.432 7.9337L10.4833 8.04771C10.4132 8.0792 10.3496 8.12536 10.2961 8.18354L10.2042 8.09877L10.2041 8.09886C10.2041 8.09887 10.2041 8.09888 10.2041 8.09889L6.74592 11.8471C6.68125 11.9172 6.63044 11.9999 6.59592 12.0902C6.56141 12.1805 6.54376 12.2769 6.54376 12.3742C6.54376 12.4714 6.56141 12.5679 6.59592 12.6582C6.63044 12.7485 6.68125 12.8311 6.74592 12.9012C6.8106 12.9713 6.88795 13.0275 6.97382 13.0661C7.05973 13.1047 7.1522 13.1247 7.24587 13.1247C7.33953 13.1247 7.43201 13.1047 7.51792 13.0661C7.60379 13.0275 7.68113 12.9713 7.74582 12.9012L7.74583 12.9012M10.9251 8.04771L7.74583 12.9012M7.74583 12.9012L10.7042 9.69357M7.74583 12.9012L10.7042 9.69357M10.7042 9.69357L13.6624 12.9011C13.6624 12.9011 13.6624 12.9012 13.6625 12.9012L10.7042 9.69357ZM14.6623 11.847L11.2042 8.09889L14.6624 11.8471C14.6623 11.847 14.6623 11.847 14.6623 11.847Z" fill="#CBCBCB" stroke="#CBCBCB" stroke-width="0.25"/>
      <path d="M9.92708 21.9317C10.1394 22.058 10.4191 22.125 10.7044 22.125C10.9896 22.125 11.2693 22.058 11.4817 21.9317C11.6925 21.8063 11.8383 21.6193 11.8383 21.4L11.8383 10.6C11.8383 10.3807 11.6925 10.1937 11.4817 10.0683C11.2693 9.94203 10.9896 9.875 10.7044 9.875C10.4191 9.875 10.1394 9.94203 9.92708 10.0683C9.71621 10.1937 9.57048 10.3807 9.57048 10.6L9.57048 21.4C9.57048 21.6193 9.71621 21.8063 9.92708 21.9317Z" fill="#CBCBCB" stroke="#CBCBCB" stroke-width="0.25"/>
      <path d="M20.6066 0.322998H1.81096C0.905983 0.322998 0.186646 1.036 0.186646 1.933V17.067C0.186646 17.964 0.929188 18.677 1.81096 18.677H7.74731C7.7009 18.539 7.6777 18.378 7.6777 18.217V16.63H2.36786C2.29825 16.63 2.25184 16.584 2.25184 16.515V5.015H20.1889V16.515C20.1889 16.584 20.1425 16.63 20.0729 16.63H13.731V18.24C13.731 18.401 13.7078 18.539 13.6614 18.7H20.5834C21.4883 18.7 22.2077 17.987 22.2077 17.09V1.933C22.2309 1.036 21.4883 0.322998 20.6066 0.322998ZM2.90157 3.359C2.50709 3.359 2.20543 3.06 2.20543 2.669C2.20543 2.278 2.50709 1.979 2.90157 1.979C3.27284 1.979 3.5977 2.278 3.5977 2.669C3.5977 3.06 3.27284 3.359 2.90157 3.359ZM5.01317 3.359C4.6419 3.359 4.31704 3.06 4.31704 2.669C4.31704 2.278 4.6419 1.979 5.01317 1.979C5.40765 1.979 5.7093 2.278 5.7093 2.669C5.7093 3.06 5.40765 3.359 5.01317 3.359ZM7.14798 3.359C6.7535 3.359 6.45185 3.06 6.45185 2.669C6.45185 2.278 6.7535 1.979 7.14798 1.979C7.54245 1.979 7.84411 2.278 7.84411 2.669C7.84411 3.06 7.54245 3.359 7.14798 3.359Z" fill="#CBCBCB"/>
      </svg>,
      layout: "/admin"
    }
  ],
  "partner": [
    /* {
      path: "/dashboard",
      name: "Dashboard",
      icon: <svg xmlns="http://www.w3.org/2000/svg"  width="47" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none"> 
      <path d="M0 0h24v24H0z" /> 
      <path d="M4 4h6v8h-6z" stroke="#CBCBCB"/> 
      <path d="M4 16h6v4h-6z" stroke="#CBCBCB"/> 
      <path d="M14 12h6v8h-6z" stroke="#CBCBCB"/> 
      <path d="M14 12h6v8h-6z" stroke="#CBCBCB"/> 
      <path d="M14 4h6v4h-6z" stroke="#CBCBCB"/> </svg>,    
      layout: "/admin"
    }, */
    {
      path: "/customers",
      name: "Customers",
      icon: <svg width="47" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6322 13.756C11.1362 13.756 11.5322 14.1641 11.5322 14.656C11.5322 15.16 11.1362 15.556 10.6322 15.556H4.44006C3.93604 15.556 3.54004 15.16 3.54004 14.656C3.54004 14.1641 3.93604 13.756 4.44006 13.756H10.6322Z" fill="#CBCBCB" />
      <path d="M12.1321 10.8281C12.1321 11.32 11.7361 11.7281 11.2321 11.7281H4.44006C3.93604 11.7281 3.54004 11.32 3.54004 10.8281C3.54004 10.3362 3.93604 9.9281 4.44006 9.9281H11.2321C11.7361 9.9281 12.1321 10.336 12.1321 10.8281Z" fill="#CBCBCB" />
      <path d="M6.42006 6.1001C6.91198 6.1001 7.32008 6.4961 7.32008 7.00012C7.32008 7.49204 6.91198 7.90014 6.42006 7.90014H4.44006C3.93604 7.90014 3.54004 7.49204 3.54004 7.00012C3.54004 6.4961 3.93604 6.1001 4.44006 6.1001H6.42006Z" fill="#CBCBCB" />
      <path d="M11.7 6.10004H13.1281L10.2001 3.17206V4.60008C10.2001 5.42794 10.872 6.10004 11.7 6.10004ZM8.40004 1.90002H3.40802C2.5201 1.90002 1.80004 2.62008 1.80004 3.508V16.492C1.80004 17.3799 2.5201 18.0999 3.40802 18.0999H10.344C10.776 18.0999 11.136 18.4 11.22 18.82C11.2321 18.8801 11.244 18.9399 11.244 19C11.244 19.4919 10.8359 19.9 10.344 19.9H3.40802C1.52394 19.9 0 18.376 0 16.492V3.508C0 1.62392 1.52416 0.0999756 3.40802 0.0999756H9.30006C9.36012 0.0999756 9.41996 0.0999755 9.48002 0.112076C9.54008 0.124176 9.59992 0.148156 9.6481 0.172136C9.69606 0.184236 9.74402 0.208216 9.7801 0.244076C9.84016 0.280156 9.88812 0.316016 9.93608 0.363976L15.9361 6.36404C15.9962 6.4241 16.056 6.49604 16.0802 6.56798C16.0923 6.58008 16.1042 6.60406 16.1042 6.61594C16.1282 6.65202 16.1403 6.68788 16.1522 6.72396C16.1643 6.74794 16.1762 6.78402 16.1883 6.81988C16.2004 6.87994 16.2004 6.93978 16.2004 6.99984V9.45988C16.2004 9.89196 15.9003 10.2519 15.4803 10.3359C15.4202 10.348 15.3604 10.3599 15.3003 10.3599C14.8084 10.3599 14.4003 9.9518 14.4003 9.45988V7.89986H11.7C9.87602 7.89986 8.40004 6.42388 8.40004 4.59986V1.90002Z" fill="#CBCBCB" />
      </svg>,        
      layout: "/admin"
    },
    {
      path: "/endpoints",
      name: "Endpoints",
      icon: <svg width="47" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2024 1H1.79501C1.35674 1 1 1.34794 1 1.77389V15.1182C1 15.5441 1.35801 15.8921 1.79501 15.8921H9.31557L8.15872 17.8398H6.69356C6.36485 17.8398 6.09603 18.1011 6.09603 18.4205C6.09603 18.7387 6.36485 19 6.69356 19H17.3064C17.6351 19 17.904 18.7387 17.904 18.4193C17.904 18.1011 17.6351 17.8398 17.3064 17.8398H15.8413L14.6844 15.8921H22.2037C22.642 15.8921 23 15.5441 23 15.1182V1.77389C23 1.34794 22.642 1 22.2037 1H22.2024ZM21.8049 2.16021H2.19252V13.4013H21.8049V2.16021Z" fill="#CBCBCB"  stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25"/>
      </svg>,    
      layout: "/admin",
    },  
    {
      path: "/alerts",
      name: "Alerts",
      icon: <svg width="47" height="20" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg"><path fill="#CBCBCB" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" d="M21.438 5.089a1.002 1.002 0 0 0-.959.015c-.684.389-1.355.577-2.053.577-2.035 0-3.952-1.629-5.722-3.39-.375-.373-1.063-.373-1.438 0C9.592 3.959 7.598 5.663 5.51 5.663c-.69 0-1.351-.184-2.018-.561-.298-.166-.658-.171-.96-.012s-.501.461-.528.801c-.011.129-.944 12.872 9.683 16.041a.99.99 0 0 0 .286.042H12c.097 0 .192-.014.285-.041 10.657-3.17 9.695-15.916 9.684-16.044a1 1 0 0 0-.531-.8zm-9.452 14.842c-6.979-2.255-7.934-9.412-8.014-12.477.505.14 1.019.209 1.537.209 2.492 0 4.65-1.567 6.476-3.283 1.893 1.788 3.983 3.301 6.442 3.301.53 0 1.057-.074 1.575-.22-.074 3.065-1.021 10.217-8.016 12.47z"/></svg>,
      layout: "/admin"
    },
    {
      path: "/reports",
      name: "Reports",
      icon: <svg width="47" height="20" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4 1H11.5V2.5H4C3.60218 2.5 3.22064 2.65804 2.93934 2.93934C2.65804 3.22064 2.5 3.60218 2.5 4V19C2.5 19.3978 2.65804 19.7794 2.93934 20.0607C3.22064 20.342 3.60218 20.5 4 20.5H16C16.3978 20.5 16.7794 20.342 17.0607 20.0607C17.342 19.7794 17.5 19.3978 17.5 19V8.5H19V19C19 19.7956 18.6839 20.5587 18.1213 21.1213C17.5587 21.6839 16.7956 22 16 22H4C3.20435 22 2.44129 21.6839 1.87868 21.1213C1.31607 20.5587 1 19.7956 1 19V4C1 3.20435 1.31607 2.44129 1.87868 1.87868C2.44129 1.31607 3.20435 1 4 1V1Z" fill="#CBCBCB" />
      <path d="M4 0.875C3.1712 0.875 2.37634 1.20424 1.79029 1.79029C1.20424 2.37634 0.875 3.1712 0.875 4V19C0.875 19.8288 1.20424 20.6237 1.79029 21.2097C2.37634 21.7958 3.1712 22.125 4 22.125H16C16.8288 22.125 17.6237 21.7958 18.2097 21.2097C18.7958 20.6237 19.125 19.8288 19.125 19V8.5C19.125 8.43096 19.069 8.375 19 8.375H17.5C17.431 8.375 17.375 8.43096 17.375 8.5V19C17.375 19.3647 17.2301 19.7144 16.9723 19.9723C16.7144 20.2301 16.3647 20.375 16 20.375H4C3.63533 20.375 3.28559 20.2301 3.02773 19.9723C2.76987 19.7144 2.625 19.3647 2.625 19V4C2.625 3.63533 2.76987 3.28559 3.02773 3.02773C3.28559 2.76987 3.63533 2.625 4 2.625H11.5C11.569 2.625 11.625 2.56904 11.625 2.5V1C11.625 0.930964 11.569 0.875 11.5 0.875H4Z" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.5 6.25V1L19 8.5H13.75C13.1533 8.5 12.581 8.26295 12.159 7.84099C11.7371 7.41903 11.5 6.84674 11.5 6.25Z" fill="#CBCBCB" />
      <path d="M11.5884 0.911612C11.5526 0.875862 11.4989 0.865167 11.4522 0.884515C11.4055 0.903863 11.375 0.949442 11.375 1V6.25C11.375 6.87989 11.6252 7.48398 12.0706 7.92938C12.516 8.37478 13.1201 8.625 13.75 8.625H19C19.0506 8.625 19.0961 8.59454 19.1155 8.54784C19.1348 8.50113 19.1241 8.44736 19.0884 8.41161L11.5884 0.911612Z" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>,        
      layout: "/admin",
    }, 
   /*  {
      path: "/reporting",
      name: "Reporting",
      icon: <svg fill="#000000" width="47" height="20" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" id="memory-chart-bar">
      <path d="M2 2H4V18H20V20H2V2M6 16V8H10V16H6M11 16V4H15V16H11M16 16V11H20V16H16Z" fill="#CBCBCB"/>
      </svg>,        
      layout: "/admin",
    },  */
    {
      path: "/users",
      name: "Users",
      icon: <svg width="47" height="20" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0004 3.7124C9.40664 3.7124 8.32373 4.95487 8.32373 6.48801C8.32373 8.0207 9.40664 9.26384 11.0004 9.26384C12.5936 9.26384 13.6767 8.0207 13.6767 6.48801C13.6769 4.95487 12.5938 3.7124 11.0004 3.7124Z" fill="#CBCBCB"/>
      <path d="M11.0004 9.90087C10.9932 9.90087 10.9864 9.89954 10.9788 9.89954V9.90198C8.39809 9.92443 6.3125 11.9418 6.3125 14.4303C6.34828 15.1616 6.60806 15.9816 7.03051 16.4503C7.43541 16.9241 14.8171 16.8892 14.9704 16.4503C15.3929 15.9819 15.6522 15.1616 15.6882 14.4303C15.6882 11.9418 13.6029 9.92443 11.0219 9.90198V9.89954C11.015 9.89954 11.0075 9.90087 11.0004 9.90087Z" fill="#CBCBCB"/>
      <path d="M17.5063 5.99843C19.0997 5.99843 20.1824 4.75508 20.1824 3.22238C20.1824 1.68969 19.0997 0.446777 17.5063 0.446777C15.9125 0.446777 14.8298 1.68969 14.8298 3.22238C14.8298 4.75508 15.9125 5.99843 17.5063 5.99843Z" fill="#CBCBCB"/>
      <path d="M4.68868 0.446777C3.09487 0.446777 2.01196 1.68969 2.01196 3.22238C2.01196 4.75508 3.09487 5.99843 4.68868 5.99843C6.28182 5.99843 7.36472 4.75508 7.36472 3.22238C7.36472 1.68969 6.28182 0.446777 4.68868 0.446777Z" fill="#CBCBCB"/>
      <path d="M17.5278 6.6365V6.63428C17.521 6.63428 17.5138 6.63517 17.5061 6.63517C17.499 6.63517 17.4918 6.63428 17.4847 6.63428V6.6365C16.7956 6.64205 16.1425 6.79161 15.5545 7.05295C15.402 8.10408 14.8276 8.97321 14 9.48566C15.8131 10.1168 17.1854 11.6561 17.5243 13.5271C19.4654 13.5255 21.3986 13.4073 21.4759 13.1848C21.8984 12.7162 22.1577 11.8961 22.1939 11.1648C22.1944 8.67654 20.1088 6.65872 17.5278 6.6365Z" fill="#CBCBCB"/>
      <path d="M4.66964 13.5271C5.00898 11.6564 6.38123 10.1168 8.19437 9.48566C7.36702 8.97321 6.79434 8.10408 6.64168 7.05384C6.053 6.79183 5.39921 6.64205 4.70964 6.6365V6.63428C4.70253 6.63428 4.6952 6.63517 4.68808 6.63517C4.68097 6.63517 4.67386 6.63428 4.66631 6.63428V6.6365C2.08537 6.65872 0 8.67654 0 11.1648C0.0362229 11.8961 0.295561 12.7159 0.718235 13.1848C0.916683 13.4171 2.79161 13.5268 4.66964 13.5271Z" fill="#CBCBCB"/>
      </svg>,        
      layout: "/admin",
    },
    {
      path: "/integrations",
      name:"Integration",
      icon: <svg xmlns="http://www.w3.org/2000/svg" height="20" width="47" viewBox="0 0 640 512">
        <path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z" fill="#CBCBCB"/></svg>,
      layout: "/admin"
    }
  ],
  "customer": [
    {
      path: "/endpoints",
      name: "Endpoints",
      icon: <svg width="47" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M22.2024 1H1.79501C1.35674 1 1 1.34794 1 1.77389V15.1182C1 15.5441 1.35801 15.8921 1.79501 15.8921H9.31557L8.15872 17.8398H6.69356C6.36485 17.8398 6.09603 18.1011 6.09603 18.4205C6.09603 18.7387 6.36485 19 6.69356 19H17.3064C17.6351 19 17.904 18.7387 17.904 18.4193C17.904 18.1011 17.6351 17.8398 17.3064 17.8398H15.8413L14.6844 15.8921H22.2037C22.642 15.8921 23 15.5441 23 15.1182V1.77389C23 1.34794 22.642 1 22.2037 1H22.2024ZM21.8049 2.16021H2.19252V13.4013H21.8049V2.16021Z" fill="#CBCBCB"  stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25"/>
      </svg>,    
      layout: "/admin",
    },  
    {
      path: "/alerts",
      name: "Alerts",
      icon: <svg width="47" height="20" viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg"><path fill="#CBCBCB" stroke="#CBCBCB" strokeOpacity="0.5" strokeWidth="0.25" d="M21.438 5.089a1.002 1.002 0 0 0-.959.015c-.684.389-1.355.577-2.053.577-2.035 0-3.952-1.629-5.722-3.39-.375-.373-1.063-.373-1.438 0C9.592 3.959 7.598 5.663 5.51 5.663c-.69 0-1.351-.184-2.018-.561-.298-.166-.658-.171-.96-.012s-.501.461-.528.801c-.011.129-.944 12.872 9.683 16.041a.99.99 0 0 0 .286.042H12c.097 0 .192-.014.285-.041 10.657-3.17 9.695-15.916 9.684-16.044a1 1 0 0 0-.531-.8zm-9.452 14.842c-6.979-2.255-7.934-9.412-8.014-12.477.505.14 1.019.209 1.537.209 2.492 0 4.65-1.567 6.476-3.283 1.893 1.788 3.983 3.301 6.442 3.301.53 0 1.057-.074 1.575-.22-.074 3.065-1.021 10.217-8.016 12.47z"/></svg>,
      layout: "/admin"
    },
    {
      path: "/users",
      name: "Users",
      icon: <svg width="47" height="20" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.0004 3.7124C9.40664 3.7124 8.32373 4.95487 8.32373 6.48801C8.32373 8.0207 9.40664 9.26384 11.0004 9.26384C12.5936 9.26384 13.6767 8.0207 13.6767 6.48801C13.6769 4.95487 12.5938 3.7124 11.0004 3.7124Z" fill="#CBCBCB"/>
      <path d="M11.0004 9.90087C10.9932 9.90087 10.9864 9.89954 10.9788 9.89954V9.90198C8.39809 9.92443 6.3125 11.9418 6.3125 14.4303C6.34828 15.1616 6.60806 15.9816 7.03051 16.4503C7.43541 16.9241 14.8171 16.8892 14.9704 16.4503C15.3929 15.9819 15.6522 15.1616 15.6882 14.4303C15.6882 11.9418 13.6029 9.92443 11.0219 9.90198V9.89954C11.015 9.89954 11.0075 9.90087 11.0004 9.90087Z" fill="#CBCBCB"/>
      <path d="M17.5063 5.99843C19.0997 5.99843 20.1824 4.75508 20.1824 3.22238C20.1824 1.68969 19.0997 0.446777 17.5063 0.446777C15.9125 0.446777 14.8298 1.68969 14.8298 3.22238C14.8298 4.75508 15.9125 5.99843 17.5063 5.99843Z" fill="#CBCBCB"/>
      <path d="M4.68868 0.446777C3.09487 0.446777 2.01196 1.68969 2.01196 3.22238C2.01196 4.75508 3.09487 5.99843 4.68868 5.99843C6.28182 5.99843 7.36472 4.75508 7.36472 3.22238C7.36472 1.68969 6.28182 0.446777 4.68868 0.446777Z" fill="#CBCBCB"/>
      <path d="M17.5278 6.6365V6.63428C17.521 6.63428 17.5138 6.63517 17.5061 6.63517C17.499 6.63517 17.4918 6.63428 17.4847 6.63428V6.6365C16.7956 6.64205 16.1425 6.79161 15.5545 7.05295C15.402 8.10408 14.8276 8.97321 14 9.48566C15.8131 10.1168 17.1854 11.6561 17.5243 13.5271C19.4654 13.5255 21.3986 13.4073 21.4759 13.1848C21.8984 12.7162 22.1577 11.8961 22.1939 11.1648C22.1944 8.67654 20.1088 6.65872 17.5278 6.6365Z" fill="#CBCBCB"/>
      <path d="M4.66964 13.5271C5.00898 11.6564 6.38123 10.1168 8.19437 9.48566C7.36702 8.97321 6.79434 8.10408 6.64168 7.05384C6.053 6.79183 5.39921 6.64205 4.70964 6.6365V6.63428C4.70253 6.63428 4.6952 6.63517 4.68808 6.63517C4.68097 6.63517 4.67386 6.63428 4.66631 6.63428V6.6365C2.08537 6.65872 0 8.67654 0 11.1648C0.0362229 11.8961 0.295561 12.7159 0.718235 13.1848C0.916683 13.4171 2.79161 13.5268 4.66964 13.5271Z" fill="#CBCBCB"/>
      </svg>,        
      layout: "/admin",
    }
  ]
}

export default menuBarItems;
