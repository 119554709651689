import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, Spinner } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory,
{ PaginationProvider, SizePerPageDropdownStandalone, PaginationTotalStandalone, PaginationListStandalone }
  from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import WebAPI from 'services/WebAPI'

const { SearchBar } = Search;

const UserList = () => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);

  let history = useHistory();

  useEffect(() => {
    if (loading)
      return
    setLoading(true)
    WebAPI
      .get('/Users/GetUsers')
      .then(({ data: res }) => {
        if (res.status)
          setRowData(res.data.map(row => ({
            ...row,
            Type: row.Customer === null ? row.Partner === null ? "Admin" : 'Partner' : 'Customer'
          })))
      })
      .finally(() => setLoading(false))
  }, []);


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/users/user_details/${row.Id}`)
    },
  };


  const columns = [{
    text: 'Type',
    sort: true,
    searchable: true,
    classes: 'common-table-column-style',
    headerStyle: { width: '120px' },
    dataField:'Type'
  }, {
    dataField: 'Email',
    text: 'Contact Email',
    classes: 'common-table-column-style',
    headerStyle: { width: '240px' },
    sort: true,
    searchable: true
  }, {
    text: 'Customer',
    dataField: 'Customer.FirstName',
    classes: 'common-table-column-style',
    formatter: (cellContent, row) => (
      <label> {row.Customer === null ? "" : row.Customer.FirstName + `( ${row.Customer.CompanyName} )`} </label>
    ),
    sort: true,
    searchable: true
  }, {
    text: 'Partner',
    dataField: 'Partner.EntityName',
    classes: 'common-table-column-style',
    sort: true,
    searchable: true
  }, {
    dataField: 'TwoFactorEnabled',
    text: '2FA Enabled',
    classes: 'common-table-column-style',
    formatter: (cellContent, row) => (
      <div className="checkbox disabled">
        <label>
          <input type="checkbox" checked={row.TwoFactorEnabled} disabled />
        </label>
      </div>
    )
  }, {
    dataField: 'LockoutEnabled',
    text: 'Lockout Enabled',
    classes: 'common-table-column-style',
    formatter: (cellContent, row) => (
      <div className="checkbox disabled">
        <label>
          <input type="checkbox" checked={row.LockoutEnabled} disabled />
        </label>
      </div>
    )
  }, {
    dataField: 'AccessFailedCount',
    text: 'Failed Logins',
    classes: 'common-table-column-style',
  }];

  const paginationOptions = {
    custom: true,
    totalSize: rowData.length
  };

  return (
    <Container className="container-main" fluid>
      <div className="common-headers">Users</div>
      <Row>
        <div className="col">
          <ToolkitProvider
            keyField="Id"
            data={rowData}
            columns={columns}
            search
          >
            {props => (
              <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
              >
                {
                  ({
                    paginationProps,
                    paginationTableProps
                  }) => (
                    <div>
                      <Row>
                        <Col xs="5" className="local-text">
                          Entries per page :
                          <SizePerPageDropdownStandalone
                            className="common-page-size-selector ml-2"
                            {...paginationProps}
                          />

                        </Col>
                        <Col className="d-flex justify-content-end">
                          <SearchBar
                            className="table-search-input"
                            {...props.searchProps}
                          />
                          <Button
                            className="ml-2 common-btn"
                            onClick={() => history.push("/users/add_user/")}
                          >
                            Add New User
                          </Button>
                        </Col>
                      </Row>
                      <BootstrapTable
                        hover
                        bordered={false}
                        rowEvents={rowEvents}
                        noDataIndication={loading ? <Spinner type="grow" color="primary" /> : "No Data"}
                        headerWrapperClasses="table-header-light common-table-column-style"
                        bodyClasses="clickable-table-row"
                        {...props.baseProps}
                        {...paginationTableProps}
                        rowStyle={{
                          //backgroundColor: '#fff',
                        }}
                      />
                      <Row className="mt-2">
                        <Col>
                          <PaginationTotalStandalone
                            {...paginationProps}
                          />
                        </Col>
                        <Col>
                          <PaginationListStandalone
                            {...paginationProps}
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
              </PaginationProvider>
            )}
          </ToolkitProvider>
        </div>
      </Row>
    </Container>
  );
};

export default UserList;