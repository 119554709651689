import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  Container
} from "reactstrap";

import AlertMessage from 'components/Alert'
import { alertActions } from 'redux/_actions'

import PartnerForm from "./form";

import { getPartner, editPartner, deletePartner } from './service'

const PartnerEdit = () => {
  let history = useHistory();
  const { id } = useParams();

  const [partner, setPartner] = useState(null)

  useEffect(() => {
    dispatch(alertActions.clear())
    getPartner(id).then(result => {
      if (result) {
        setPartner(result)
      }
      else{
        dispatch(alertActions.error("Partner not found"))
      }
    })
  }, []);

  const dispatch = useDispatch()

  const savePartner = (partnerData) => {
    editPartner(id, partnerData, dispatch)
  }

  const handleDelete = () => {
    deletePartner(id).then((status) => {
      if(status){
        history.replace(`/partner`)
      }
    })
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.go(-1)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Edit Partner
        </div>
        <Row>
          <Col xs="6">
            <Card className="shadow">
              <CardBody>
                <PartnerForm
                  partner={partner}
                  editMode={true}
                  shareFormData={savePartner}
                  deletePartner={handleDelete}
                  openPartnerCustomers={() => history.push('/partners/list_customers/'+id)}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default PartnerEdit;