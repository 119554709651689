import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback
} from "reactstrap";
import validator from 'validator';

import Button from 'components/Inputs/TButton'
import AlertMessage from "components/Alert";

import WebAPI from 'services/WebAPI'

const getUserType = (user) => {
  if (!user.Customer && !user.Partner)
    return 'Administrator'
  if (!user.Customer)
    return 'Partner'
  return 'Customer'
}

const UserForm = (props) => {
  const [allowed, setAllowed] = useState({ roles: [], customers: [], partners: [] })

  const [passwordScore, setPasswordScore] = useState(0)
  const [isVissible, setIsVissible] = useState(false)

  useEffect(() => {
    WebAPI.get('/Users/GetAllowedEntities')
      .then(({ data: res }) => {
        if (res.status) {
          setAllowed({ ...res.data })
        }
      })
  }, [])

  useEffect(() => {
    if (props.editMode) {
      const { user } = props
      setFormState({
        Role: getUserType(user),
        PartnerId: user.Partner?.PartnerId || null,
        CustomerId: user.Customer?.CustomerId || null,
        UserEmail: user.Email
      })
    }
  }, [props.user])
  const [formState, setFormState] = useState({
    Role: "",
    PartnerId: "",
    CustomerId: "",
    UserEmail: "",
    Password: ""
  });
  const [invalidFormState, setInvalidFormState] = useState({
    Role: false,
    PartnerId: false,
    CustomerId: false,
    UserEmail: false,
    Password: false
  });

  const handleSave = (e) => {
    let flag = 0
    e.preventDefault();
    if (!validator.isEmail(formState.UserEmail)) {
      invalidFormState['UserEmail'] = true;
      flag = 1
    }
    if (validator.isEmpty(formState.Role)) {
      invalidFormState['Role'] = true;
      flag = 1
    }
    if (!props.editMode && validator.isEmpty(formState.Password)) {
      invalidFormState['Password'] = true;
      flag = 1
    }
    if (formState.Role === 'Partner' && !formState.PartnerId){
      invalidFormState['PartnerId'] = true;
      flag = 1
    }

    if (formState.Role === 'Customer' && !formState.CustomerId){
      flag = 1
      invalidFormState['CustomerId'] = true;
    }

    setInvalidFormState({ ...invalidFormState })
    if (flag === 0) {
      formState.CustomerId = parseInt(formState.CustomerId) || null
      formState.PartnerId = parseInt(formState.PartnerId) || null
      props.shareFormData(formState)
    }

  }

  const handleFormValueChange = ({ target: { id, value } }) => {
    invalidFormState[id] = false
    formState[id] = value
    setFormState({ ...formState })
    setInvalidFormState({ ...invalidFormState })
  }

  return (
    <Form Role="form" onSubmit={handleSave}>
      <FormGroup>
        <AlertMessage />
      </FormGroup>
      <FormGroup>
        <Label for="Role">Role</Label>
        <Input
          id="Role"
          value={formState.Role}
          invalid={invalidFormState.Role}
          onChange={handleFormValueChange}
          type="select"
        >
          <option>Select</option>
          {allowed.roles.map(r => <option>{r.Name}</option>)}
        </Input>
        <FormFeedback>Please select a user Role</FormFeedback>
      </FormGroup>
      {formState.Role === "Partner" && (
        <FormGroup>
          <Label for="PartnerId">Partner</Label>
          <Input
            id="PartnerId"
            value={formState.PartnerId}
            invalid={invalidFormState.PartnerId}
            onChange={handleFormValueChange}
            type="select"
          >
             <option>Select</option>
            {allowed.partners.map(r => <option value={r.PartnerId}>{r.EntityName}</option>)}
          </Input>
          <FormFeedback>Please select a PartnerId</FormFeedback>
        </FormGroup>
      )}

      {formState.Role === "Customer" && (

        <FormGroup>
          <Label for="CustomerId">Customer</Label>
          <Input
            id="CustomerId"
            value={formState.CustomerId}
            invalid={invalidFormState.CustomerId}
            onChange={handleFormValueChange}
            type="select"
          >
             <option>Select</option>
            {allowed.customers.map(r => <option value={r.CustomerId}>{r.FirstName} {r.LastName} - {r.Company}</option>)}
          </Input>
          <FormFeedback>Please select a CustomerId</FormFeedback>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="UserEmail">Email</Label>
        <Input
          type="UserEmail"
          id="UserEmail"
          value={formState.UserEmail}
          invalid={invalidFormState.UserEmail}
          onChange={handleFormValueChange}
        />
        <FormFeedback>Please enter a valid UserEmail</FormFeedback>
      </FormGroup>
      {
        props.editMode ? null :
          <FormGroup>
            <Label for="Password">Password</Label>
            <div style={{display:"flex", flexDirection: 'row'}}>
              <Input
              id="Password"
              value={formState.Password}
              invalid={invalidFormState.Password}
              onChange={handleFormValueChange}
              type={isVissible ? "text" : "password"}
            />
            <Button style={{marginLeft:10}} outline onClick={()=>setIsVissible(!isVissible)}><i className={isVissible ? "fa fa-eye" : "fa fa-eye-slash"} /></Button>
            </div>
           
            <FormFeedback>Please enter a strong Password. Current Score {passwordScore}</FormFeedback>
          </FormGroup>
      }
      <Row>
        <Button
          className="ml-3 mt-3 common-btn"
          type="submit"
          isLoading={props.loading}
        >
          {props.editMode ? "Save" : "Create"}
        </Button>
      </Row>
    </Form >
  )
};

export default UserForm;