import React from "react";
import { useSelector } from "react-redux";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
import Helmet from "react-helmet";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Alert } from "reactstrap";

import routes from "routes.js";
import BreadCrumbNavigation from "components/Breadcrumb";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const {
    theme = "white",
    twoFactorActive,
    role = "customer",
  } = useSelector((state) => state.authentication.user) || {
    theme: "white",
    role: "customer",
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (
        prop.layout === "/admin" &&
        ((prop.roles && prop.roles.includes(role)) || !prop.roles)
      ) {
        return <Route path={prop.path} component={prop.component} key={key} exact />;
      } else {
        return null;
      }
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <div className={`cs-wrapper`}>
      <Helmet
        bodyAttributes={{
          theme: theme || "white",
        }}
      />
      <AdminNavbar
        {...props}
        brandText={getBrandText(props.location.pathname)}
      />
      <div
        className="main-content"
        style={{ display: "flex", flex: 1, overflowX: "hidden" }}
        ref={mainContent}
      >
        <Sidebar {...props} routes={routes} />
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <BreadCrumbNavigation />
          <div style={{ flex: 1 }}>
            {!twoFactorActive && (
              <div style={{ padding: 10 }}>
                <Alert color="danger">
                  You have not enabled multi-factor authentication for your
                  account. Please enable the 2FA to access the portal
                </Alert>
              </div>
            )}
            <div>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="*" to="/" />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Admin;
