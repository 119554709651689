import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { userActions } from '../../redux/_actions';
import { Input, Label, FormGroup, Alert } from 'reactstrap';
import Button from 'components/Inputs/TButton'
import {Helmet} from 'react-helmet'

import { Link } from 'react-router-dom'


function LoginAlerts() {
  const alertMesssage = useSelector(state => state.alert)
  if (alertMesssage.type) {
    return (<Alert color={alertMesssage.type}>{alertMesssage.message}</Alert>)
  }
  return null
}

function Login() {
  const twoFA = useSelector(state => state.authentication.TwoFA);
  const isLoading = useSelector(state => state.authentication.isWorking)
  const dispatch = useDispatch();

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')
  const [rememberUser, setRememberUser] = useState(false)
  const [rememberMachine, setRememberMachine] = useState(false)



  const cssInput = {
    borderRadius: 0,
    boxShadow: 'none',
    borderColor: '#d2d6de',
    marginBotton: '.5rem'
  }

  function submitLogin(e) {
    e.preventDefault()
    dispatch(userActions.login(username, password, rememberUser))
  }

  function submit2FA(e) {
    e.preventDefault()
    dispatch(userActions.twoFASubmit(code, rememberUser, rememberMachine))
  }

  if (twoFA) {
    return (
      <div>
        <LoginAlerts />
        <form key="2fa_form">
          <FormGroup>
            <Label>Authenticator code</Label>
            <Helmet>
              <title>
                Authentication | Canauri
              </title>
            </Helmet>
            <Input
              type="text"
              value={code}
              style={cssInput}
              onChange={e => setCode(e.target.value)}
            />
          </FormGroup>
          <FormGroup inline check style={{ marginBottom: '1rem' }}>
            <Label check  >
              <Input type="checkbox" onChange={e => setRememberMachine(e.target.checked)} /> Remember this machine
            </Label>
          </FormGroup>
          <FormGroup>
            <Button isLoading={isLoading} onClick={submit2FA} type="submit" color="primary">Submit</Button>
          </FormGroup>
        </form>
      </div>
    )
  } else {
    return (
      <div>
        <LoginAlerts />
        <form key="auth_form">
          <FormGroup>
            <Label>Email</Label>
            <Helmet>
              <title>
                Login | Canauri
              </title>
            </Helmet>
            <Input
              type="text"
              style={cssInput}
              value={username}
              onChange={e => setUsername(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label>Passsword</Label>
            <Input
              type="password"
              style={cssInput}
              value={password}
              onChange={e => setPassword(e.target.value)}
            />
          </FormGroup>

          <FormGroup check style={{ marginBottom: '1rem', display:'flex', justifyContent:"space-between" }}>
            <Label check  >
              <Input type="checkbox" onChange={e => setRememberUser(e.target.checked)} /> Remember Me
            </Label>
            <Link to={{
              pathname: "/auth/forget_pass",
              state: { fromLogin: true }
            }} replace>Forgot Passsword?</Link>
          </FormGroup>

          <FormGroup>
            <Button isLoading={isLoading} onClick={submitLogin} type="submit" color="primary">Login</Button>
          </FormGroup>

        </form>
      </div>
    )
  }
}

export default Login;
