import { useState, useEffect } from "react";
import { Button, Col, Container, Row, Popover } from "reactstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import WebAPI from "services/WebAPI";
import EndpointsTable from "./EndpointTable";

const EndpointListByLicensse = () => {
  const { id } = useParams();

  const [endPointWholeList, setEndPointList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    loadCustomers();
    loadEndpoints();
  }, []);

  const loadEndpoints = () => {
    setLoading(true);
    WebAPI.get("/Endpoints/ListFromLicense/" + id)
      .then(({ data: res }) => {
        if (res.status) {
          setEndPointList(res.data);
        }
      })
      .catch((err) => console.log("err", err))
      .finally(() => setLoading(false));
  };

  const loadCustomers = () => {
    setCustomerList([]);
    WebAPI.get(`/Customers/List?active=true`)
      .then(({ data: res }) => {
        if (res.status) setCustomerList(res.data);
      })
      .catch((err) => console.log("err", err))
  };

  return (
    <>
      <Container className="container-main" fluid>
        <div className="common-headers">Endpoints with License</div>
        <Row>
          <div className="col">
            <EndpointsTable
              reload={loadEndpoints}
              customerList={customerList}
              dashFilter={{ failedUpdates: "false" }}
              loading={loading}
              endpoints={endPointWholeList}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EndpointListByLicensse;
