import { useEffect, useState } from "react";
import AlertMessage from "components/Alert";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
} from "reactstrap";
import WebAPI from "services/WebAPI";
import { Helmet } from "react-helmet";
import { Input, Label, FormGroup } from "reactstrap";
import Button from "components/Inputs/TButton";
import { useDispatch, useSelector } from "react-redux";
import { alertActions } from "redux/_actions";

const FormData = require("form-data");
export default function LogoUpload() {
  const [isLoading, setLoading] = useState(false);
  const [logoData, setLogoData] = useState({});
  const [preview, setPreview] = useState(false);

  const dispatch = useDispatch();
  const data = useSelector((store) => store.authentication.user);

  useEffect(() => {
    fetchPartnerLogo();
  }, []);

  const LogoChanged = (key, value) => {
    setLogoData({ ...logoData, [key]: value });
  };

  const fetchPartnerLogo = async () => {
    try {
      const resp = await WebAPI.get("Partner/FetchPartnerPreference");
      if (
        resp.data.status &&
        resp.data.data &&
        resp.data.data.LogoLargeUrl &&
        resp.data.data.LogoMiniUrl
      ) {
        setLogoData({
          icon: resp.data.data?.LogoMiniUrl,
          logo: resp.data.data?.LogoLargeUrl,
          color: resp.data.data?.Color,
          CompanyName: resp.data.data?.CompanyName,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleUpload = async () => {
    setLoading(true);
    if (!logoData.icon || !logoData.logo) {
      dispatch(alertActions.error("Please select Logo and Icon!"));
      setTimeout(() => {
        dispatch(alertActions.clear());
        setLoading(false);
      }, [3000]);
      return;
    }
    try {
      const formData = {
        CompanyName: logoData.CompanyName,
        Color: logoData.color || "",
      };
      const formDataMiniLogo = new FormData();
      const formDataLargeLogo = new FormData();
      formDataLargeLogo.append("largeLogoFile", logoData.logo);
      formDataMiniLogo.append("miniLogoFile", logoData.icon);
      let respData, respLL, respML;
      respData = await WebAPI.post("Partner/PartnerUpdatepreference", formData);
      if (typeof logoData.icon !== "string")
        respML = await WebAPI.post("Partner/PartnerMiniLogo", formDataMiniLogo);
      if (typeof logoData.logo !== "string")
        respLL = await WebAPI.post(
          "Partner/PartnerLargeLogo",
          formDataLargeLogo
        );
      if (respData.data.status || respLL.data.status || respML.data.status)
        dispatch(alertActions.success("Successfully uploaded"));
      else dispatch(alertActions.error("Something Went Wrong"));
    } catch (error) {
      console.log(error);
      dispatch(alertActions.error("Server Error"));
    } finally {
      setLoading(false);
      setTimeout(() => {
        window.location.reload()
      }, 3000);
    }
  };

  return (
    <div>
      <Container
        className="container-main container-fluid"
        style={{ marginLeft: 0 }}
      >
        <div className="common-headers">Upload Logo</div>
        <Helmet>
          <title>{"Upload Logo"}</title>
        </Helmet>
        <Row>
          <Col xs="9">
            <Card className="shadow">
              <CardBody>
                <AlertMessage />
                <Row style={{ position: "relative" }}>
                  <Col>
                    <FormGroup style={{ marginTop: 20 }}>
                      <Row style={{ marginLeft: 20 }}>
                        <Col xs="4">
                          <Label for="file" style={{ fontSize: 15 }}>
                            {"Enter Company Name"}
                          </Label>
                        </Col>
                        <Col xs="7">
                          <Input
                            id="file"
                            value={logoData?.CompanyName}
                            onChange={(e) =>
                              LogoChanged("CompanyName", e.target.value)
                            }
                          />
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ position: "relative" }}>
                  <Col xs="12" offset={6}>
                    <FormGroup style={{ marginTop: 20 }}>
                      <Row style={{ marginLeft: 35 }}>
                        <Label style={{ fontSize: 15 }}>{"Choose Logo"}</Label>
                        <Button
                          outline
                          size="sm"
                          style={{ marginLeft: 10, padding: 10 }}
                        >
                          <Label style={{ marginTop: 5 }} for="file1">
                            Browse
                          </Label>
                        </Button>
                        <Input
                          id="file1"
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) =>
                            LogoChanged("logo", e.target.files[0])
                          }
                        />
                        <Button outline size="sm">
                          {" "}
                          <Label style={{ marginTop: 5 }} for="color">
                            Select background color
                          </Label>
                        </Button>
                        {logoData.logo && (
                          <Col>
                            <p style={{ fontSize: 12 }}>
                              File Name :{" "}
                              {logoData.logo.name ||
                                logoData.logo.split("/").slice(-1)}{" "}
                              | Color : {logoData.color ? logoData.color : ""}
                            </p>
                          </Col>
                        )}
                      </Row>
                      <Input
                        id="color"
                        type="color"
                        style={{ display: "none" }}
                        onChange={(e) => LogoChanged("color", e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ position: "relative" }}>
                  <Col span={5}>
                    <FormGroup style={{ marginTop: 20 }}>
                      <Row style={{ marginLeft: 40 }}>
                        <Label style={{ fontSize: 15 }}>
                          {"Choose Logo Icon (16px x 16px)"}
                        </Label>
                        <Button outline size="sm" style={{ marginLeft: 20 }}>
                          <Label style={{ marginTop: 5 }} for="file3">
                            Browse
                          </Label>
                        </Button>
                        <Col>
                          {logoData.icon && (
                            <p style={{ fontSize: 13 }}>
                              File Name :{" "}
                              {logoData.icon.name ||
                                logoData.icon.split("/").slice(-1)}
                            </p>
                          )}
                        </Col>
                      </Row>

                      <Input
                        id="file3"
                        type="file"
                        style={{ width: 400, display: "none" }}
                        onChange={(e) => LogoChanged("icon", e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{ position: "relative" }}>
                  <Col sm="2">
                    <Button
                      style={{ marginLeft: 30, marginTop: 10 }}
                      onClick={() => setPreview(!preview)}
                      disabled={!logoData.icon && !logoData.logo}
                    >
                      Preview
                    </Button>
                  </Col>
                  <Col sm="1">
                    <Button
                      style={{ marginTop: 10 }}
                      color="primary"
                      isLoading={isLoading}
                      onClick={handleUpload}
                    >
                      Upload
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      {(logoData.icon || logoData.logo) && (
        <Modal
          isOpen={preview}
          toggle={() => setPreview(!preview)}
          style={{ width: 800 }}
        >
          <ModalBody>
            <Row>
              {logoData.logo && (
                <Col>
                  <h5>Logo :</h5>
                  <img
                    style={{
                      height: 110,
                      width: 320,
                      marginTop: 10,
                      marginBottom: 10,
                      backgroundColor: logoData.color || "",
                      padding: 10,
                      textAlign: "center",
                    }}
                    src={
                      typeof logoData.logo !== "string"
                        ? URL.createObjectURL(logoData.logo)
                        : logoData.logo
                    }
                    alt="Showing Logo"
                  />
                </Col>
              )}
            </Row>
            <Row>
              {logoData.icon && (
                <Col>
                  <h5>Icon :</h5>
                  <img
                    style={{
                      height: 40,
                      width: 40,
                      marginTop: 10,
                      textAlign: "center",
                    }}
                    src={
                      typeof logoData.icon !== "string"
                        ? URL.createObjectURL(logoData.icon)
                        : logoData.icon
                    }
                    alt="Showing Logo icon"
                  />
                </Col>
              )}
            </Row>
          </ModalBody>
        </Modal>
      )}
    </div>
  );
}
