import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import styled from "styled-components";

import routes from "routes.js";
import { Spinner } from "reactstrap";

import Logo from '../assets/img/brand/dark.png'
import { Helmet } from "react-helmet";

const LoginContainer = styled.div`
  width: 360px;
  margin: 7% auto;
  background: #fff;
  padding: 20px;
  border-top: 0;
  color: #666;
`


const Auth = (props) => {

  const [partnerFavicon, setPIcon] = useState("")

  useEffect(()=>{
    prepareFavicon()
  },[])

  const authStore = useSelector(store => store.authentication)

  const isAuthenticated = authStore.loggedIn

  const location = useLocation()
  if (isAuthenticated) {
    const next_url = new URLSearchParams(location.search).get('next_url') || '/'
    return <Redirect to={next_url} />
  }

  const getRoutes = (routes) => {
    const _routes = [];
    routes.forEach((prop, key) => {
      if (prop.layout === "/auth")
        _routes.push(
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
    });
    return _routes;
  };

  const prepareFavicon= async()=>{
    await fetch(
      require("assets/img/brand/favicon.ico").default
    ).then((res) => {
      setPIcon(res.url);
    })
    .catch((err)=>console.log('err', err))
  }

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={partnerFavicon} />
      </Helmet>
      <div style={{ width: '100%', height: '100%', background: 'slategray', position: 'absolute', overflow: 'auto' }}>
        <LoginContainer>
          <img
            alt="..."
            style={{ width: 320, height: 100 }}
            src={Logo}
          />
          <hr />
          {authStore.firstLoad && (
            <center>
              <Spinner />
            </center>
          ) || (
            <Switch>
              {getRoutes(routes)}
            </Switch>
          )}
        </LoginContainer>

      </div>
    </>
  );
};

export default Auth;
