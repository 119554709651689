import { useSelector } from "react-redux"
import { Alert } from "reactstrap"

export default function AlertMessage() {
  const alertMesssage = useSelector(state => state.alert)
  if (alertMesssage.type){
    return (<Alert color={alertMesssage.type}>{alertMesssage.message}</Alert>)
  }
  return null
}

