import { alertActions } from 'redux/_actions'
import API from 'services/WebAPI'

export async function getList() {
  try {
    const response = await API.get('Partner/List')
    const { data, status } = response.data
    if (status)
      return data
  }
  catch { }
  return []
}


export async function getPartner(id) {
  try {
    const response = await API.get(`Partner/Get/${id}`)
    const { data, status } = response.data
    if (status)
      return data

  }
  catch {

  }
  return false
}

export async function editPartner(id, partner, dispatch) {
  try {
    const response = await API.post(`Partner/Edit/${id}`, partner)
    const { data, status, message } = response.data
    if (!status)
      dispatch(alertActions.error(message))
    else 
      dispatch(alertActions.success("Updated"))
    return status
  }
  catch { dispatch(alertActions.error("Error editing partner")) }
  return false
}

export async function deletePartner(id) {
  try {
    const response = await API.post(`Partner/deletepartner/${id}`);
    const { status } = response.data;
    if (status) return true;
  } catch {}
  return false;
}

export async function addPartner(partner, dispatch) {
  try {
    const response = await API.post(`Partner/Add`, partner)
    const { data, status, message } = response.data
    if (status)
      return data
    else
      dispatch(alertActions.error(message))
  }
  catch {
    dispatch(alertActions.error("Error adding new partner"))
  }

  return false
}