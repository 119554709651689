import { useState, useEffect } from "react";
import { Container, FormGroup, Input, Label } from "reactstrap";
import { useDispatch } from "react-redux";
import Button from "components/Inputs/TButton";
import AlertMessage from "components/Alert";
import validator from "validator";

import WebAPI from 'services/WebAPI'
import { alertActions } from "redux/_actions";

export default function Upload(props) {

  const [formData, setFormData] = useState({ type: 'alpha', version: '' })
  const [process, setProcess] = useState(false)

  const [channel, setChannel] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    loadChannels();
  }, []);

  const loadChannels = () => {
    WebAPI.get("/UpgradeManager/FetchAllPackageChannels")
      .then(({ data: res }) => {
        if (res.status) setChannel(res.result);
      })
  };


  const formChanged = (key, value) => {
    setFormData({ ...formData, [key]: value })
  }

  const formSubmit = (e) => {
    e.preventDefault()
    dispatch(alertActions.clear())

    if (process)
      return

    if (!validator.isSemVer(formData.version)) {
      dispatch(alertActions.error("Invalid version"))
      return
    }
    if (validator.isEmpty(formData.type)) {
      dispatch(alertActions.error("Invalid Type"))
      return
    }
    if (!formData.file) {
      dispatch(alertActions.error("Select valid file"))
      return
    }

    setProcess(true)
    const data = new FormData()
    data.append('file', formData.file)
    data.append('version', formData.version)
    data.append('packageType', formData.type)

    WebAPI
      .post('/UpgradeManager/Create', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(({ data: res }) => {
        if (res.status){
          props.reload()
        }
        else
          dispatch(alertActions.error(res.message))
      })
      .finally(() => setProcess(false))
  }

  return (
    <Container className="contaier-modal" style={{ padding: 10 }}>
      <FormGroup>
        <AlertMessage />
      </FormGroup>
      <FormGroup>
        <Label for="file">
          Package
        </Label>
        <Input
          id="file"
          type="file"
          onChange={(e) => formChanged('file', e.target.files[0])}
        />
      </FormGroup>
      <FormGroup>
        <Label for="version">
          Version
        </Label>
        <Input
          id="version"
          type="text"
          value={formData.version}
          onChange={(e) => formChanged('version', e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label for="type">
          Channel
        </Label>
        <Input
          id="type"
          type="select"
          value={formData.type}
          onChange={(e) => formChanged('type', e.target.value)}
        >
          {channel.map(i => <option value={i.Channel}>{i.Channel}</option>)}
        </Input>
      </FormGroup>
      <FormGroup>
        <Button
          onClick={formSubmit}
          isLoading={process}
          color="success"
        >
          Submit
        </Button>
      </FormGroup>
    </Container>
  )
}