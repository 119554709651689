import { useState, useEffect } from "react";
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search, CSVExport } from "react-bootstrap-table2-toolkit";

import { useDispatch, useSelector } from "react-redux";
import store from "store";
import _ from "lodash";
import semver from "semver";

import EndpointFilter from "./popover";
import fetchFilteredEndpoints from "./helper";
import { useHistory } from "react-router-dom";
import WebAPI from 'services/WebAPI'
import { alertActions } from 'redux/_actions'
import AlertMessage from 'components/Alert'
import './style.css'
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SelectSearch from "react-select-search";
const semverSort = require("semver/functions/sort");

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;
const ENDPOINT_KEY = "DeviceId";

export default function EndpointsTable(props) {
  const [filterPopover, toggleFilterPopover] = useState(false);
  const [Filters, setFilters] = useState({
    version: [],
    activation: [],
    deviceType: [],
    status: [],
    license: [],
  });

  const [rowData, setRowData] = useState([]);
  const [endPointWholeList, setEndPointList] = useState([]);
  const [selectedEndpoint, setSelectedEndpoint] = useState([]);
  const [isUninstall, setIsUninstall] = useState(false);
  const [uninstall, setUninstall] = useState(true)
  const [partner, setPartner] = useState(0) 
  const [partnerFilter, setPFilter] = useState('')
  const [customerFilter, setCFilter] = useState('')
  const [ move, setMove ] = useState(false)
  const [moveToCustomer, setMoveToCustomer ] = useState(null)

  const { theme } = useSelector((state) => state.authentication.user) || {
    theme: "white",
  };
  const user = useSelector((s) => s.authentication.user);
  const dispatch = useDispatch()
  const {endpoints, intialFilter} = props
  console.log('endpoints,intialFilter', endpoints,intialFilter)
  useEffect(() => {
    setEndPointList(endpoints);
    let _filters = store.get(`app.endpointFilters.${user.email}`);
    if(!intialFilter){
      _filters=Filters
      store.set(`app.endpointFilters.${user.email}`, _filters)
    }
    if (_filters) {
      const newRowData = fetchFilteredEndpoints(endpoints, _filters);
      setRowData(newRowData);
      setFilters(_filters);
    } else {
      setRowData(endpoints);
    }
  }, [endpoints, intialFilter]);

  const history = useHistory();

  const handleConfirm = async() => {
    try {
      const req={UnistallDeviceIdList: selectedEndpoint, IsUnistall: uninstall}
      const resp=  await WebAPI.post('/Endpoints/MassUninstall',req)
      if(resp.data.status){
        dispatch(alertActions.success(`Successfully reitred ${uninstall ? "and uninstalled" : ""}`))
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }
      else
      dispatch(alertActions.error(resp.data.message))
    } catch (error) {
      console.log('error', error)
    } finally{
      setTimeout(() => {
        dispatch(alertActions.clear())
        setIsUninstall(false);
      }, 2000);
    }
  };

  const handleOnSelect = (row, isSelect) => {
    const endpoint = row[ENDPOINT_KEY];
    if (isSelect) {
      setSelectedEndpoint([...selectedEndpoint, endpoint]);
    } else {
      setSelectedEndpoint(
        selectedEndpoint.filter((x) => x !== row[ENDPOINT_KEY])
      );
    }
  };

  const handleSelectAll = (isSelect, rows) => {
    const newEP = rowData
      .filter((i) => !selectedEndpoint.includes(i[ENDPOINT_KEY]))
      .map((x) => x[ENDPOINT_KEY]);
    if (isSelect) {
      setSelectedEndpoint([...selectedEndpoint, ...newEP]);
    } else {
      setSelectedEndpoint([]);
    }
  };

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      history.push(`/endpoints/endpoint_details/${row.DeviceId}`);
    },
  };

  const { dashFilter: { failedUpdates = "false" } } = props

  const columns = [
    {
      dataField: "DeviceId",
      text: "ID",
      sort: true,
      searchable: true,
      headerStyle: { width: "70px", padding: "15px 5px" },
    },
    {
      dataField: "CustomerName",
      text: "Customer",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      //csvExport:false,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "CompanyName",
      text: "Company",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "HostName",
      text: "Hostname",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "OnlineStatus",
      text: "Status",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      //csvExport:false,
      formatter: function (cell, row) {
        return (
          <span
            style={{
              color: cell === "Offline" ? "#e4535a" : "#1E8DCC",
              fontWeight: theme === "white" ? "normal" : "bold",
            }}
          >
            {cell}
          </span>
        );
      },
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "DeviceType",
      text: "Type",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px", width: 80 },
    },
    {
      dataField: "ActivationStatus",
      text: "Activation",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      formatter: function (cell, row) {
        return (
          <span
            style={{
              color: cell === "Not Available" ? "#e4535a" : "#1E8DCC",
              fontWeight: theme === "white" ? "normal" : "bold",
            }}
          >
            {cell}
          </span>
        );
      },
      hidden:failedUpdates==="true" ? true : false,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "UpdateServiceStatus",
      text: "Failed Updates",
      sort: true,
      classes: "common-table-column-style",
      searchable: false,
      hidden: failedUpdates==="true" ? false : true,
      headerStyle: { padding: "15px 5px", width: "300px" },
    },
    {
      dataField: "Version",
      text: "Version",
      classes: "common-table-column-style",
      sort: true,
      sortFunc: (a, b, order) => {
        a = a === "" ? "0.0.0" : a;
        b = b === "" ? "0.0.0" : b;
        if (order === "asc") {
          return semver.compare(a, b) === 1 ? 1 : -1;
        } else {
          return semver.compare(a, b) === 1 ? -1 : 1;
          // desc
        }
      },
      searchable: true,
      headerStyle: { padding: "15px 5px", width: 100 },
      //csvExport:false,
    },
    {
      dataField: "LicenseType",
      text: "License",
      classes: "common-table-column-style",
      sort: true,
      searchable: true,
      hidden:failedUpdates==="true" ? true : false,
      //csvExport:false,
      headerStyle: { padding: "15px 5px", width: 100 },
    },
    {
      dataField: "LastReportedTime",
      text: "Last Contact",
      classes: "common-table-column-style",
      sort: true,
      searchable: false,
      hidden:failedUpdates==="true" ? true : false,
      //csvExport:false,
      style: { whiteSpace: "normal" },
      formatter: function (cell, row) {
        return new Date(cell).toLocaleString();
      },
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "PartnerName",
      hidden: true,
      searchable: user.role === 'admin' ? true : false,
      csvExport:false
    },
  ];

  const paginationOptions = {
    custom: true,
    withFirstAndLast: true,
    totalSize: rowData?.length,
  };

  const applyFilter = (filters) => {
    const newRowData = fetchFilteredEndpoints(endPointWholeList, filters);
    setRowData(newRowData || []);
    store.set(`app.endpointFilters.${user.email}`, filters);
    setFilters(filters);
  };

  const clearFilter = () => {
    setRowData(endPointWholeList);
    const _filter = {
      version: [],
      activation: [],
      deviceType: [],
      status: [],
      license: [],
    };
    setFilters(_filter);
    store.set(`app.endpointFilters.${user.email}`, _filter);
    toggleFilterPopover(false);
    document.body.click();
  };

  const handleMove = async() => {
    const {reload} = props
    const req={CustomerId: parseInt(moveToCustomer), DeviceIdList:selectedEndpoint}
    WebAPI
      .post("/Customers/SwapCustomerEndpoints",req)
      .then(({ data: res }) => {
        if (res.status) {
          dispatch(alertActions.success("Sucessfully moved"))
          setMove(false)
          reload()
          setSelectedEndpoint([])
          setMoveToCustomer(null)
          setTimeout(() => {
            dispatch(alertActions.clear())
          }, 2500);
        }
      })
      .catch((err)=>{
        console.log('err', err)
      })
  }

  const handleActions = (id, props) => {
    console.log("id", id);
    switch (id) {
      case 1: {
        setIsUninstall(true);
        break;
      }
      case 2: {
        setMove(true);
        break;
      }
      case 3:{
        props.csvProps.onExport()
        break;
      }
      default:
        break;
    }
  };

  const { loading, customerList } = props;

  const popover = (
    <Popover
      target="filterpopover"
      show={filterPopover}
      className="endpoint-popover"
    >
      <EndpointFilter
        Filters={Filters}
        data={endPointWholeList}
        applyFilter={applyFilter}
        clearFilter={clearFilter}
      />
    </Popover>
  );

  const filter_applied = !_.values(Filters).every(_.isEmpty);

  return (
    <div>
      <AlertMessage />
      <ToolkitProvider
        keyField="DeviceId"
        data={
          rowData.filter(
            (i) =>
              (partner !== 0 && i.PartnerName === partner) ||
              (partner === 0 && i)
          ) || []
        }
        columns={columns}
        search
        remote
        exportCSV={{
          fileName: "endpoint_list.csv",
          exportAll: selectedEndpoint.length > 0 ? false : true,
          onlyExportSelection: selectedEndpoint.length > 0 ? true : false,
        }}
      >
        {(props) => (
          <PaginationProvider pagination={paginationFactory(paginationOptions)}>
            {({ paginationProps, paginationTableProps }) => (
              <div>
                <Row style={{ marginBottom: 10 }}>
                  <Col
                    xs="4"
                    className="local-text"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    Entries per page :
                    <SizePerPageDropdownStandalone
                      className="common-page-size-selector ml-2"
                      {...paginationProps}
                    />
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom"
                      overlay={popover}
                    >
                      <Button
                        id="filterpopover"
                        onClick={() => toggleFilterPopover(!filterPopover)}
                        outline
                        className={filter_applied ? "filter-focus" : ""}
                      >
                        <i class="bi bi-sliders"></i>
                      </Button>
                    </OverlayTrigger>
                    <Input
                      id="exampleSelect"
                      name="Actions"
                      value={0}
                      type="select"
                      style={{
                        width: 150,
                        overflowY: "auto",
                        overflowX: "hidden",
                        maxHeight: "60vh",
                      }}
                      onChange={(e) =>
                        handleActions(parseInt(e.target.value), props)
                      }
                    >
                      <option value={0}>Actions</option>
                      <option
                        value={1} 
                        disabled={selectedEndpoint.length === 0}
                      >
                        Bulk Uninstall
                      </option>
                      <option
                        value={2} 
                        disabled={selectedEndpoint.length === 0}
                      >
                        Move Endpoints
                      </option>
                      <option value={3}>Export As CSV</option>
                    </Input>
                  </Col>
                  <Col xs="8" className="d-flex justify-content-end">
                    {user.role === "admin" && (
                      <div>
                        <SelectSearch
                          options={[
                            { name: "Partners", value: parseInt(0) },
                            ...[...new Set(rowData.map((i) => i?.PartnerName))]
                              .filter(
                                (i) =>
                                  (partnerFilter &&
                                    i
                                      .toLowerCase()
                                      .includes(partnerFilter.toLowerCase())) ||
                                  (!partnerFilter && i)
                              )
                              .map((r) => {
                                return { name: r, value: r };
                              }),
                          ]}
                          value={partner}
                          name="Partner"
                          placeholder="Choose Partner"
                          search
                          getOptions={(val) => setPFilter(val)}
                          onChange={(e) => setPartner(e)}
                        />
                      </div>
                    )}
                    <SearchBar
                      style={{ marginLeft: 10 }}
                      className="table-search-input"
                      {...props.searchProps}
                    />
                  </Col>
                </Row>
                <BootstrapTable
                  hover
                  bordered={false}
                  rowEvents={rowEvents}
                  noDataIndication={
                    loading ? (
                      <Spinner type="grow" color="primary" />
                    ) : (
                      "No Data"
                    )
                  }
                  headerWrapperClasses="table-header-light"
                  selectRow={{
                    mode: "checkbox",
                    clickToSelect: true,
                    selected: selectedEndpoint,
                    onSelect: handleOnSelect,
                    onSelectAll: handleSelectAll,
                  }}
                  bodyClasses="clickable-table-row"
                  {...props.baseProps}
                  {...paginationTableProps}
                  rowStyle={
                    {
                      //backgroundColor: '#fff',
                    }
                  }
                />
                <Row className="mt-2">
                  <Col>
                    <PaginationTotalStandalone {...paginationProps} />
                  </Col>
                  <Col>
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </div>
            )}
          </PaginationProvider>
        )}
      </ToolkitProvider>
      <Modal isOpen={isUninstall} toggle={() => setIsUninstall(false)}>
        <ModalHeader
          className="confirm-modal-header"
          toggle={() => setIsUninstall(false)}
        >
          Are you sure to delete this endpoint?
        </ModalHeader>
        <ModalBody className="confirm-modal-body">
          {selectedEndpoint.length !== 0
            ? `As a result of this action, ${selectedEndpoint.length} endpoint(s) will be uninstalled.`
            : "Select atleast one endpoint!"}
          <Form>
            <FormGroup check inline style={{ marginTop: 10 }}>
              <Input
                type="checkbox"
                checked={uninstall}
                onChange={() => setUninstall(!uninstall)}
              />
              <Label check>Uninstall Endpoint(s)</Label>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter className="confirm-modal-footer">
          <Button
            color={selectedEndpoint.length !== 0 ? "danger" : "ghost"}
            disabled={selectedEndpoint.length === 0}
            size="sm"
            onClick={handleConfirm}
          >
            <i className="far fa-trash-alt" style={{ marginRight: 5 }} />
            Continue
          </Button>
          <Button size="sm" outline onClick={() => setIsUninstall(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={move}
        toggle={() => {
          setMove(false);
          setMoveToCustomer(null);
        }}
      >
        <ModalHeader>Move Endpoints To:</ModalHeader>
        <ModalBody style={{ textAlign: "center" }}>
          {loading && <Spinner />}
          {!loading && (
            <FormGroup>
              <Row>
                <Col xs="4" style={{padding:"5px"}}>
                  <Label for="exampleSelect">Select Company</Label>
                </Col>
                <Col xs="8">
                  <SelectSearch
                    options={[
                      { name: "Company", value: parseInt(0) },
                      ...customerList
                        .filter((i) =>
                          i.CompanyName.toLowerCase().includes(
                            customerFilter.toLowerCase()
                          )
                        )
                        .map((i) => ({ name: i.CompanyName, value: i.Id })),
                    ]}
                    value={moveToCustomer}
                    name="Company"
                    placeholder="Choose Company"
                    search
                    getOptions={(val) => setCFilter(val)}
                    onChange={(e) => setMoveToCustomer(parseInt(e))}
                  />
                </Col>
              </Row>
              <Button
                color="warning"
                style={{ marginTop: "10px", float: "right" }}
                onClick={handleMove}
              >
                Move
              </Button>
            </FormGroup>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}
