import { Container, Row, Col, Spinner, Popover } from "reactstrap"
import Button from "components/Inputs/TButton";
import BootstrapTable from 'react-bootstrap-table-next';
import { useEffect, useState } from "react";
import WebAPI from 'services/WebAPI'
import { useHistory } from "react-router-dom";
import Upload from './Upload'
import Channel from './channel'
import ChangeChannel from "./changeChannel";


export default function Version() {
  const history = useHistory()

  const [versions, setVersions] = useState([])
  const [isChange, setChange] =useState(null)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    loadVersions()
  }, [])

  const loadVersions = () => {
    setLoading(true)
    WebAPI.get('/UpgradeManager/Versions')
      .then(({ data: res }) => {
        if (res.status)
          setVersions(res.data)
      })
      .finally(() => setLoading(false))
  }
  const columns = (currentItem, deleting) => [
    {
      dataField: 'Version',
      text: 'Version',
      classes: 'common-table-column-style',
    },
    {
      dataField: 'Type',
      text: 'Channel',
      classes: 'common-table-column-style',
      formatter: (cell) => cell ? cell.charAt(0).toUpperCase() + cell.slice(1) : cell
    },
    {
      dataField: 'Id',
      isDummyField: true,
      formatter: (cell, row, rowId) => (
        <div style={{ display: 'flex' }}>
          <Button
            outline
            className="common-btn-outlined"
            color="warning"
            size="sm"
            onClick={()=>{setChange(row)}}
            isLoading={currentItem === row.Id && updating}
          >
            Change Channel
          </Button>
          <Button
            outline
            className="common-danger-btn"
            color="danger"
            size="sm"
            onClick={() => handleDelete(row.Id)}
            isLoading={currentItem === row.Id && deleting}
          >
            Remove
          </Button>
        </div>
      )
    }
  ]


  const [uploadOpen, setUploadOpen] = useState(false)
  const [channelOpen, setChannelOpen] = useState(false)

  const [currentItem, setCurrentItem] = useState(0)
  const [deleting, setDeleting] = useState(false)

  const reload = () => {
    setUploadOpen(false)
    setCurrentItem(0)
    setVersions([])
    loadVersions()
  }
  const handleDelete = (id) => {
    if (deleting)
      return
    setDeleting(true)
    setCurrentItem(id)
    WebAPI
      .post('/UpgradeManager/Delete/' + id)
      .then(({ data: res }) => {
        if (res.status) {
          reload()
        }
      })
      .finally(() => setDeleting(false))
  }

  const [updating,setUpdating] = useState(false)
  const handleChange = (id, state) => {
    if (updating)
      return
    setUpdating(true)
    setCurrentItem(id)
    WebAPI
      .post('/UpgradeManager/ChangeState/' + id, { type : state})
      .then(({ data: res }) => {
        if (res.status) {
          reload()
        }
      })
      .finally(() => setUpdating(false))
  }


  return (
    <Container className="container-main" fluid>
      <div className="common-headers">Versions</div>
      <Row>
        <Col>
          <Row className="mb-2">
            <Col sm={{ offset: 6, size: 6 }}>
              <div style={{ display: 'flex', float: 'right' }}>
              <Button
                  onClick={() => setChannelOpen(true)}
                  outline
                >
                  <i class="fas fa-bars mr-1" /> Channels
                </Button>
                <Button
                  id="add_btn"
                  onClick={() => setUploadOpen(true)}
                  color="primary"
                >
                  <i class="fas fa-upload mr-1"></i> Upload
                </Button>
              </div>
            </Col>
          </Row>
          <Row>
            <BootstrapTable
              keyField="Id"
              data={versions}
              columns={columns(currentItem, deleting)}
              noDataIndication={loading ? <Spinner type="grow" color="primary" /> : "No Data"}
            />
          </Row>
        </Col>
      </Row>
      <Popover
        placement="bottom"
        isOpen={uploadOpen}
        target="add_btn"
        toggle={() => setUploadOpen(!uploadOpen)}
      >
        <Upload
          reload={reload}
        />
      </Popover>
      <Channel open={channelOpen} OnClose={()=>setChannelOpen(!channelOpen)} />
      <ChangeChannel handleSave={handleChange} open={isChange} OnClose={()=>{setChange(null)}} />
    </Container>
  )
}