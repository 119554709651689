import { result } from "lodash";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

import WebAPI from "services/WebAPI";

import Visualization from "./visualization";

const Dashboard = () => {
  const [recentAlerts, setRecentAlerts] = useState([]);
  const [versionHealth, setVersionHealth] = useState([]);
  const [workstationDetails, setWorkstationDetails] = useState([]);
  const [serverDetails, setServerDetails] = useState([]);
  const [worstationHealth, setWorkstationHealth] = useState([]);
  const [serverHealth, setServerHealth] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchVersionHealth();
    fetchMostRecentAlert();
    fetchWSDetails();
    fetchServerDetails();
    fetchWorkstationHealth();
    fetchServerHealthDetails();
  }, []);

  const fetchVersionHealth = async () => {
    try {
      const resp = await WebAPI.get("Dashboard/GetVersionHealth");
      if (resp.data.status) setVersionHealth(resp.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  const fetchMostRecentAlert = async () => {
    try {
      setLoading(true)
      const resp = await WebAPI.get("Dashboard/GetMostRecentAlert");
      if (resp.data.status) setRecentAlerts(resp.data.data);
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false)
  };

  const fetchServerDetails = async () => {
    try {
      const resp = await WebAPI.get("Dashboard/GetDashboardServerDetails");
      if (resp.data.status) setServerDetails(resp.data.data);
    } catch (error) {
      console.log("error server Details", error);
    }
  };

  const fetchWSDetails = async () => {
    try {
      const resp = await WebAPI.get("Dashboard/GetDashboardWorkstationDetails");
      if (resp.data.status) setWorkstationDetails(resp.data.data);
    } catch (error) {
      console.log("errorDSH", error);
    }
  };
  //working
  const fetchWorkstationHealth = async () => {
    try {
      const resp = await WebAPI.get("Dashboard/GetWorkStationHealthDetails");
      if (resp.data.status) setWorkstationHealth(resp.data.data[0]);
    } catch (error) {
      console.log("error WH", error);
    }
  };
  //working
  const fetchServerHealthDetails = async () => {
    try {
      const resp = await WebAPI.get("Dashboard/GetServerHealthDetails");
      if (resp.data.status) setServerHealth(resp.data.data[0]);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Container className="container-main" fluid>
      <div className="common-headers">Dashboard</div>
      <Row>
        <div className="col">
          <Visualization
            loading={loading}
            versionHealth={versionHealth}
            recentAlerts={recentAlerts}
            serverDetails={serverDetails}
            workstationDetails={workstationDetails}
            serverHealth={serverHealth}
            workstationHealth={worstationHealth}
          />
        </div>
      </Row>
    </Container>
  );
};

export default Dashboard;
