import { alertConstants, userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from '../_actions'

function request() { return { type: userConstants.LOGIN_PROGRESS } }
function request2FA() { return { type: userConstants.LOGIN_2FA_PROGRESS } }
function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
function twoFA() { return { type: userConstants.LOGIN_2FA } }

function login(username, password, rememberUser) {
    return dispatch => {
        dispatch(request())
        dispatch(alertActions.clear())
        userService.login(username, password, rememberUser).then(result => {
            if (result.status) {
                if (result.twoFAactive) {
                    dispatch(twoFA())
                } else {
                    dispatch(alertActions.success("Login success!"))
                    getAndSetUser(dispatch)
                }
            } else {
                dispatch({ type: alertConstants.ERROR, message: 'Invalid Login' })
                dispatch({ type: userConstants.LOGIN_FAILURE })
            }
        });
    };


}

function twoFASubmit(code, rememberUser, rememberMachine) {
    return dispatch => {
        dispatch(request2FA())
        dispatch(alertActions.clear())
        userService.submit2FA(code, rememberUser, rememberMachine).then(result => {
            if (result.status) {
                dispatch(alertActions.success("Login success!"))
                getAndSetUser(dispatch)
            } else {
                dispatch({ type: alertConstants.ERROR, message: 'Invalid Token' })
                dispatch(twoFA())
            }
        })

    }
}

function getAndSetUser(dispatch, loggedIn) {
    userService.getUser().then(user => {
        if (user) {
            dispatch(success(user))
        }
        else {
            dispatch({ type: userConstants.LOGOUT })
        }
    })
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

export const userActions = {
    login,
    logout,
    twoFASubmit,
    getAndSetUser
};