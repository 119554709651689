import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback
} from "reactstrap";
import validator from 'validator';
import Button from 'components/Inputs/TButton'
import { useSelector } from 'react-redux'

import WebAPI from 'services/WebAPI'
import AlertMessage from "components/Alert";

const CustomerForm = (props) => {

  const user = useSelector(store => store.authentication.user)

  const [partners, setPartners] = useState([])
  useEffect(() => {
    WebAPI.get('Partner/List')
      .then(({ data: res }) => {
        setPartners(res.data)
      })
  }, [])


  const [formState, setFormState] = useState({
    FirstName: "",
    LastName: "",
    CompanyName: "",
    UserEmail: "",
    Address: "",
    AccountType: "Company",
    PartnerId: 0,
  });

  const [invalidFormState, setInvalidFormState] = useState({
    FirstName: false,
    LastName: false,
    CompanyName: false,
    UserEmail: false,
    Address: false,
    AccountType: false,
    PartnerId: false
  });

  useEffect(() => {
    if(props.editMode)
      setFormState(props.customer)
  }, [props.customer])

  const handleSave = (e) => {
    let flag = 0
    e.preventDefault();
    Object.entries(formState).forEach(([a, b]) => {
      if (a === 'PartnerId') {
        if (b < 1) {
          flag = 1
          invalidFormState[a] = true
          setInvalidFormState({ ...invalidFormState })
        }
      } else {
        if (validator.isEmpty(b)) {
          flag = 1
          invalidFormState[a] = true
          setInvalidFormState({ ...invalidFormState })
        }
      }
    });
    if (flag === 0) props.shareFormData(formState)
  }

  const handleFormValueChange = ({ target: { id, value } }) => {
    if (id === 'PartnerId')
      value = parseInt(value, 10)
    invalidFormState[id] = false
    formState[id] = value
    setFormState({ ...formState })
    setInvalidFormState({ ...invalidFormState })
  }

  return (
    <Form role="form" onSubmit={handleSave}>
      <FormGroup>
        <AlertMessage />
      </FormGroup>
      <FormGroup>
        <Label for="FirstName">First Name</Label>
        <Input
          id="FirstName"
          value={formState.FirstName}
          invalid={invalidFormState.FirstName}
          onChange={handleFormValueChange}
          type="text"
        />
        <FormFeedback>Please enter a first name</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="LastName">Last Name</Label>
        <Input
          id="LastName"
          value={formState.LastName}
          invalid={invalidFormState.LastName}
          onChange={handleFormValueChange}
          type="text"
        />
        <FormFeedback>Please enter a last name</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="CompanyName">Company Name</Label>
        <Input
          id="CompanyName"
          value={formState.CompanyName}
          invalid={invalidFormState.CompanyName}
          onChange={handleFormValueChange}
          type="text"
        />
        <FormFeedback>Please enter a company name</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="UserEmail">Contact Email</Label>
        <Input
          type="email"
          id="UserEmail"
          value={formState.UserEmail}
          invalid={invalidFormState.UserEmail}
          onChange={handleFormValueChange}
        />
        <FormFeedback>Please enter a valid UserEmail</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="Address">Contact Address</Label>
        <Input
          id="Address"
          value={formState.Address}
          invalid={invalidFormState.Address}
          onChange={handleFormValueChange}
          type="textarea"
        />
        <FormFeedback>Please enter a contact Address</FormFeedback>
      </FormGroup>
      <FormGroup>
        <Label for="AccountType">Account Type</Label>
        <Input
          id="AccountType"
          value={formState.AccountType}
          invalid={invalidFormState.AccountType}
          onChange={handleFormValueChange}
          type="select"
        >
          <option>Company</option>
          <option>Personal</option>
        </Input>
        <FormFeedback>Please select a account type</FormFeedback>
      </FormGroup>
      {user.role !== 'Admin' && (
        <FormGroup>
          <Label for="PartnerId">Partner</Label>
          <Input
            id="PartnerId"
            value={formState.PartnerId}
            invalid={invalidFormState.PartnerId}
            onChange={handleFormValueChange}
            type="select"
          >
            <option value={0}>Select</option>
            {partners.map(o => (<option key={o.PartnerId} value={o.PartnerId}>{o.EntityName}</option>))}
          </Input>
          <FormFeedback>Please select a partner </FormFeedback>
        </FormGroup>
      )}
      <Row>
        <Button
          className="mt-3 ml-3 common-btn"
          type="submit"
          isLoading={props.loading}
        >
          {props.editMode ? "Save" : "Create"}
        </Button>
      </Row>
    </Form>
  )
};

export default CustomerForm;