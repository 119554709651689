import { useEffect, useState } from "react";
import AlertMessage from "components/Alert";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import WebAPI from "services/WebAPI";
import { Helmet } from "react-helmet";

import { Form, Input, Label, FormGroup } from "reactstrap";
import Button from "components/Inputs/TButton";
import { useDispatch } from "react-redux";
import { alertActions } from "redux/_actions";

export default function AcronisApiGen() {
  const dispatch = useDispatch();

  const [key, setKey] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchKey();
  }, []);

  const fetchKey = async () => {
    setIsLoading(true);
    try {
      const resp = await WebAPI.get("/Acronics/GetAPIKey");
      console.log("respget", resp);
      if (resp.data.status) setKey(resp.data.result);
      else {
        dispatch(alertActions.error("Key doesn't Exist!!"));
        setTimeout(() => {
          dispatch(alertActions.clear());
        }, 2500);
      }
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const resp = await WebAPI.get("/Acronics/GenerateAPIKey");
      console.log("respgenerate", resp);
      if (resp.data.status) {
        dispatch(alertActions.success("Key generated sucessfully"));
        setKey(resp.data.result);
      } else {
        dispatch(alertActions.error("Failed to generate!"));
      }
      setTimeout(() => {
        dispatch(alertActions.clear());
      }, 2500);
    } catch (err) {
      console.log("err", err);
    }
    setIsLoading(false);
  };

  return (
    <Container
      className="container-main container-fluid"
      style={{ marginLeft: 0 }}
    >
      <Helmet>
        <title>{"Acronis API"}</title>
      </Helmet>
      <Row>
        <Col style={{ marginTop: 10 }}>
          <Card className="shadow">
            <CardBody>
              <AlertMessage />
              <h3>Generate API</h3>
              <Form
                role="form"
                onSubmit={() => handleSave()}
                style={{ marginTop: "20px" }}
              >
                <FormGroup>
                  <Label
                    for="key"
                    onClick={() => {
                      navigator.clipboard.writeText(key);
                      dispatch(alertActions.success("copied!"));
                    }}
                  >
                    Copy Key
                  </Label>
                  <div style={{ display: "flex" }}>
                    <Input
                      id="key"
                      value={key}
                      type="text"
                      disabled
                      style={{ width: "80%" }}
                    />
                    <Button
                      color="info"
                      onClick={() => {
                        navigator.clipboard.writeText(key);
                        dispatch(alertActions.success("copied!"));
                      }}
                      outline
                      style={{ opacity: "0.7", marginLeft: "10px" }}
                    >
                      Copy
                    </Button>
                  </div>
                </FormGroup>
                <FormGroup style={{ marginTop: "10px" }}>
                  <Button type="submit" color="primary" isLoading={isLoading}>
                    {key ? "Regenerate" : "Generate"}
                  </Button>
                </FormGroup>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}
