import Dashboard from "screens/dashboard";
import PartnerList from "screens/partner/list";
import PartnerAdd from "screens/partner/add";
import PartnerEdit from "screens/partner/edit";
import PartnerCustomers from "screens/partner/customers";
import CustomerList from "screens/customer/list";
import CustomerListByPartner from "screens/customer/list_partner";
import CustomerAdd from "screens/customer/add";
import CustomerEdit from "screens/customer/edit";
import Login from "screens/login";
import ForegetPass from 'screens/login/forgot_password';
import Reset from 'screens/login/reset';
import ChangePartner from "screens/partner/changePartner";
import CustomerDetails from "screens/customer/view";
import LicenseAdd from "screens/customer/license/add";
import Preferences from "screens/preferences";
import ChangePassword from "screens/preferences/change_password";
import LogoUpload from "screens/preferences/logo_upload";
import AcronisApiGen from "screens/preferences/acronis";
import { TwoFactorSettings } from "screens/preferences/two_factor";
import Reports from "screens/reports";
import Integrations from "screens/integration"

import Notification from 'screens/customer/notification'
import ReportSettings from 'screens/reports/reports/index'
import CustomerSettings from 'screens/customer/settings'


import EndpointList from "screens/endpoints/list";
import AttackList from "screens/attacks";
import EndpointListC from "screens/endpoints/list_by_customer";
import EndpointListL from "screens/endpoints/list_by_license";
import EndpointListP from "screens/endpoints/list_by_partner";
import EndpointDetails from "screens/endpoints/Details"
import { EndpointSettings } from "screens/endpoints/Settings";
import Landing from "screens/Landing";

///

import UserList from "screens/users/list";
import UserDetails from "screens/users/view";
import UserAdd from "screens/users/add";
import UserEdit from "screens/users/edit";
import Version from "screens/upgrades/Versions";
import GlobalSettings from "screens/others/global_settings";
//import Reporing from "screens/reporting";

var routes = [
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/forget_pass",
    component: ForegetPass,
    layout: "/auth"
  },
  {
    path: "/reset",
    component: Reset,
    layout: "/auth"
  },

  // dashboard
  {
    path: "/dashboard",
    component: Dashboard,
    layout: "/admin",
    parent: "Dashboard",
    roles: [ "admin" ],
  },
  
  // partners
  {
    path: "/partners/",
    component: PartnerList,
    layout: "/admin",
    // parent is for active left menu item
    parent: "Partners",
    // roles is for managing rbac of each page
    roles: ["admin"],
  },
  {
    path: "/partners/edit_partner/:id",
    component: PartnerEdit,
    layout: "/admin",
    parent: "Partners",
    roles: ["admin"],
  },
  {
    path: "/partners/add_partner",
    component: PartnerAdd,
    layout: "/admin",
    parent: "Partners",
    roles: ["admin"],
  },
  // ==>Validate following page<==
  {
    path: "/Partner/PartnerCustomers",
    component: PartnerCustomers,
    layout: "/admin",
    parent: "Partners",
    roles: ["admin"],
  },
  // ==>Validate following page<==
  {
    path: "/Partner/PartnerChange/:id",
    component: ChangePartner,
    layout: "/admin",
    parent: "Partners",
    roles: ["admin"],
  },
  {
    path: "/partners/list_customers/:id",
    component: CustomerListByPartner,
    layout: "/admin",
    parent: "Partners",
    roles: ["admin"],
  },
  // Customer
  {
    path: "/customers",
    component: CustomerList,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/customer_details/:id",
    component: CustomerDetails,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/new_license/:id",
    component: LicenseAdd,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/add_customer",
    component: CustomerAdd,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/edit_customer/:id",
    component: CustomerEdit,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/notification_settings/:id",
    component: Notification,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/customer_settings/:id",
    component: CustomerSettings,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/global_agent_settings",
    component: GlobalSettings,
    layout: "/admin",
    roles: ["admin"],
  },
  {
    path: "/customers/list_endpoints/:id",
    component: EndpointListC,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },
  {
    path: "/customers/endpoints_under_license/:id",
    component: EndpointListL,
    layout: "/admin",
    parent: "Customers",
    roles: ["admin", "partner"],
  },

  // Endpoints
  {
    path: "/endpoints",
    component: EndpointList,
    layout: "/admin",
    parent: "Endpoints",
    roles: ["admin", "partner", "customer"],
  },
  {
    path: "/endpoints/endpoint_details/:id",
    component: EndpointDetails,
    layout: "/admin",
    parent: "Endpoints",
    roles: ["admin", "partner", "customer"],
  },
  {
    path: "/endpoints/endpoint_settings/:id",
    component: EndpointSettings,
    layout: "/admin",
    parent: "Endpoints",
    roles: ["admin", "partner", "customer"],
  },
  // ==>Validate following page<==
  {
    path: "/Endpoints/ListFromPartner/:id",
    component: EndpointListP,
    layout: "/admin",
    parent: "Partners",
    roles: ["admin"],
  },

  // Profile
  {
    path: "/preferences/change_password",
    component: ChangePassword,
    layout: "/admin",
    roles: ["admin", "partner", "customer"],
  },
  {
    path: "/preferences/change_2FA",
    component: TwoFactorSettings,
    layout: '/admin',
    roles: ["admin", "partner", "customer"]
  },
  {
    path: '/preferences/logo_upload',
    component: LogoUpload,
    layout: '/admin',
    roles: ["admin", "partner"]
  },
  {
    path: '/preferences',
    component: Preferences,
    layout: "/admin",
    roles: ["admin", "partner", "customer"],
  },

  // Reports
  {
    path: "/reports",
    component: Reports,
    layout: "/admin",
    parent: "Reports",
    roles: ["admin", "partner"],
  },
  {
    path: "/reports/report_settings",
    component: ReportSettings,
    layout: "/admin",
    parent: "Reports",
    roles: ["admin", "partner"],
  },

 // Reporting
/*   {
    path: "/reporting",
    component: Reporing,
    layout: "/admin",
    parent: "Reporting",
    roles: ["admin", "partner"],
  }, */

  // Users

  {
    path: "/users/user_details/:id",
    component: UserDetails,
    layout: "/admin",
    parent: "Users",
    roles: ["admin", "partner", "customer"],
  },
  {
    path: "/users/add_user",
    component: UserAdd,
    layout: "/admin",
    parent: "Users",
    roles: ["admin", "partner", "customer"],
  },
  {
    path: "/users/edit_user/:id",
    component: UserEdit,
    layout: "/admin",
    parent: "Users",
    roles: ["admin", "partner", "customer"],
  },
  {
    path: "/users",
    component: UserList,
    layout: "/admin",
    parent: "Users",
    roles: ["admin", "partner", "customer"],
  },

  // Upgrades
  {
    path: "/upgrade_manager",
    component: Version,
    layout: "/admin",
    roles: ["admin"],
  },

  // Handle Landig
  {
    path: "/",
    component: Landing,
    layout: "/admin",
  },
  // Alerts
  {
    path: "/alerts",
    component: AttackList,
    layout: "/admin",
    parent: "Alerts",
    roles: ["admin", "partner", "customer"],
  },
  //Integrations
  {
    path: "/integrations",
    component: Integrations,
    layout: "/admin",
    roles: [ "partner" ],
  },
  {
    path: '/integrations/acronis',
    component: AcronisApiGen,
    layout: '/admin',
    parent: "Integration",
    roles: [ "partner" ]
  }
];

export default routes;
