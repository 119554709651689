import React from "react";
import { Col, Container, Row } from "reactstrap";
import AcronisApiGen from "./generate";
import AcronisApiInput from "./api";

export default function Acronis() {
  return (
    <Container
      className="container-main container-fluid"
      style={{ marginLeft: 0 }}
    >
      <div className="common-headers">Acronis API</div>
      <Row>
        <Col md={6}>
          <AcronisApiGen />
        </Col>
        {/* <Col md={6}>
          <AcronisApiInput />
        </Col> */}
      </Row>
    </Container>
  );
}
