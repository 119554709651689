import { useState, useEffect } from "react";
import { Button, Col, Container, Row, Popover } from "reactstrap";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  YAxis,
  LineChart,
  XAxis,
  Cell,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ActiveLicense = (props) => {
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setdata(props.data);
  }, [props]);

  const data01 = [
    {
      name: "Desktop",
      value:
        data.map((i) => i?.DesktopLicenses).reduce((a, b) => a + b, 0) || 0,
    },
    {
      name: "Server",
      value: data.map((i) => i?.ServerLicense).reduce((a, b) => a + b, 0) || 0,
    },
  ];

  /* const data02 = data
    .map((i) => i.timeStamp)
    .map((i) => {
      const t = moment(i).seconds(0).milliseconds(0).toISOString();
      console.log("t", t);
      const _data = {
        name: moment(i).format("DD MMM YYYY"),
        endpoint: data.filter(
          (row) =>
            moment(row?.timeStamp).seconds(0).milliseconds(0).toISOString() ===
            t
        ).length,
        amt: data.length,
      };
      console.log("_data", _data);
      return _data;
    }); */

  const data03 = [...new Set(data.map((i) => i?.version))].map((i) => {
    const _data = {
      name: i,
      Desktop:
        data
          .filter((row) => row?.version === i)
          .map((row) => row?.DesktopLicenses)
          .reduce((a, b) => a + b, 0) || 0,
      Server:
        data
          .filter((row) => row?.version === i)
          .map((row) => row?.ServerLicense)
          .reduce((a, b) => a + b, 0) || 0,
    };

    return _data;
  });

  return (
    <>
      <Container className="container-main" fluid>
        <Row style={{ margin: 10 }}>
          <div className="col">
            <div style={{ display: "flex", height: 400, width: "100%", marginBottom:10  }}>
              <div style={{ flex: 1 }}>
                <div style={{ width: "100%", height: "100%" }}>
                  <h4>Ratio of Active Desktop to Server</h4>
                  <ResponsiveContainer>
                    <PieChart>
                      {" "}
                      <Legend
                        layout="horizontal"
                        verticalAlign="top"
                        align="center"
                      />
                      <Pie
                        data={data01 || []}
                        labelLine={false}
                        label={renderCustomizedLabel}
                        outerRadius={150}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {data01.map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={COLORS[index % COLORS.length]}
                          />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ height: "100%", width: "70%"}}>
                  <h4>Active license endpoints</h4>
                  <ResponsiveContainer>
                    <BarChart data={data03}>
                      <CartesianGrid strokeDasharray="3 3" horizontal={false} />
                      <XAxis
                        dataKey="name" /* label={{ value: "Versions", dy: 10 }} */
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="Desktop" fill="#8884d8" />
                      <Bar dataKey="Server" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ActiveLicense;
