import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row, ButtonGroup } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, 
  { PaginationProvider, SizePerPageDropdownStandalone, PaginationTotalStandalone, PaginationListStandalone } 
from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import tableData from "utils/getDummyTableData";

const { SearchBar } = Search;

const PartnerCustomers = () => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowData, setRowData] = useState([]);

  let history = useHistory();

  useEffect(() => {
    const newProducts = tableData.products
    setRowData(newProducts)
  });

  const handleOnSelect = (row, isSelect) => {
    const newSelection = isSelect ?
      [...selectedRow, row.PartnerId] : selectedRow.filter(x => x !== row.PartnerId)
    setSelectedRow(newSelection)
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.PartnerId);
    isSelect ? setSelectedRow(ids) : setSelectedRow([])
  }

  const handlePartnerDelete = () => {
    console.log("delete entries", selectedRow)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      console.log("target",e.target.cellIndex)
      //history.push(`/Partner/PartnerEdit/${row.PartnerId}`)
    },
  };

  const handlePartnerChange = (row) => {
    history.push(`/Partner/PartnerChange/${row.PartnerId}`)
  }

  const viewDevices = (row) => {
    console.log(row.EntityName)
  }

  const columnActions = (cell, row, rowIndex) => {
    return (<div>
      <Button 
        outline 
        size="sm" 
        color="primary"
        onClick={()=>handlePartnerChange(row)}
      >
        Change Partner
      </Button>{' '}
      <Button 
        outline 
        size="sm" 
        color="primary"
        onClick={()=>viewDevices(row)}
      >
        View Devices
      </Button>
    </div>)
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedRow,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const columns = [{
    dataField: 'PartnerId',
    text: 'ID',
    searchable: true,
    headerStyle: { width: '70px' },
  },
  {
    dataField: 'Email',
    text: 'Customer Email',
    searchable: true
  },
  {
    dataField: 'EntityName',
    text: 'Partner Name',
    searchable: true
  }, {
    dataField: 'FirstName',
    text: 'First Name',
    searchable: true
  }, {
    dataField: 'LastName',
    text: 'Last Name',
    searchable: true
  },
  {
    text: 'Actions',
    searchable: false,
    headerStyle: { width: '270px' },
    formatter: columnActions
  }];

  const paginationOptions = {
    custom: true,
    withFirstAndLast : true,
    totalSize: rowData.length
  };

  return (
    <>
      <Container className="container-main" fluid>
        <div className="common-headers">Partner Worldwide</div>
        <Row>
          <div className="col">
            <ToolkitProvider
              keyField="PartnerId"
              data={rowData}
              columns={columns}
              search
            >
              {props => (
                <PaginationProvider
                  pagination={paginationFactory(paginationOptions)}
                >
                  {
                    ({
                      paginationProps,
                      paginationTableProps
                    }) => (
                      <div>
                        <Row style={{ paddingBottom: '10px' }}>
                          <Col className="local-text">
                            <ButtonGroup className="mr-2" size="sm" aria-label="activefilter">
                              <Button>Active</Button>
                              <Button variant="secondary">Inactive</Button>
                            </ButtonGroup>
                            Entries per page : 
                            <SizePerPageDropdownStandalone
                              className="common-page-size-selector"
                              {...paginationProps}
                            />
                            {selectedRow.length > 0 &&
                              <Button
                                className="common-btn-delete"
                                onClick={handlePartnerDelete}
                                outline
                              >
                                <i className="far fa-trash-alt" />
                              </Button>
                            }
                          </Col>
                          <Col xs="3" className="mr-5" >
                            <SearchBar
                              className="table-search-input"
                              {...props.searchProps}
                            />
                          </Col>
                        </Row>
                        <BootstrapTable
                          hover
                          bordered={false}
                          selectRow={selectRow}
                          //rowEvents={rowEvents}
                          noDataIndication="Data not available"
                          headerWrapperClasses="table-header-light"
                          {...props.baseProps}
                          {...paginationTableProps}
                          rowStyle={{
                            //backgroundColor: '#fff',
                          }}
                        />
                        <Row>
                          <Col>
                            <PaginationTotalStandalone
                              {...paginationProps}
                            />
                          </Col>
                          <Col>
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </div>
                    )}
                </PaginationProvider>
              )}
            </ToolkitProvider>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PartnerCustomers;