import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Button,
  ButtonGroup,
  Spinner,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { useEffect, useState } from "react";
import WebAPI from "services/WebAPI";
import reportsList from "./reports.json";
import AppConfig from "config";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ActiveLicense from "./visualization/activeLicenseChart";
import Reporting from "./offlineReport";
import EndpointSettingReport from "./endpointSetting";

const { ExportCSVButton } = CSVExport;

function getReportProps(reportId) {
  return reportsList[reportId] || { columns: [{}] };
}

export default function Reports() {
  const [report, setReport] = useState({ columns: [{}] });

  const [reportTitle, setReportTitle] = useState("");
  const [reportData, setReportData] = useState([]);
  const [reportId, setReportId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [visitedBtn, setVisited] = useState("");

  const history = useHistory();
  const id = useParams();

  const loadReport = (reportId = "") => {
    setIsLoading(true);
    const reportProps = getReportProps(reportId);

    WebAPI({
      method: "get",
      url: `${reportProps.url}`,
      baseURL: AppConfig.apiHost,
    })
      .then((result) => {
        setReportData(result.data.data);
        setReportTitle(reportProps.title);
      })
      .finally(() => setIsLoading(false));
    setReport(reportProps);
    setReportId(reportId);
  };

  useEffect(() => {
    loadReport("OFFLINE_REPORT");
  }, []);

  const { theme = "white" } = useSelector((s) => s.authentication.user) || {};

  return (
    <Container className="container-main" fluid>
      <div
        style={{
          display: "flex",
          width: "100%",
          minWidth: "100%",
          marginTop: 10,
        }}
      >
        <div className="common-headers">Reports</div>
        <div style={{ float: "right", width: "100%" }}>
          <Button
            outline
            style={{ float: "right" }}
            className="common-btn-outlined"
            color="primary"
            type="button"
            size="sm"
            onClick={() => history.push("/reports/report_settings")}
          >
            Report Settings
          </Button>
        </div>
      </div>
      <Row>
        <Card className="shadow" style={{ width: "100%" }}>
          <CardBody>
            <Row>
              <Col>
                <ButtonGroup>
                  <Button
                    color="primary"
                    outline={reportId !== "ACTIVE_LICENSE"}
                    className={
                      reportId === "ACTIVE_LICENSE"
                        ? "reports-btn-grp--cdccdvisited"
                        : ""
                    }
                    onClick={() => loadReport("ACTIVE_LICENSE")}
                  >
                    Active Paid Licenses
                  </Button>
                  
                  <Button
                    color="primary"
                    outline={reportId !== "OFFLINE_REPORT"}
                    className={
                      reportId === "OFFLINE_REPORT"
                        ? "reports-btn-grp-cdvdfvvisited"
                        : ""
                    }
                    onClick={() => setReportId("OFFLINE_REPORT")}
                  >
                    Agent Offline Report
                  </Button>

                  <Button
                    color="primary"
                    outline={reportId !== "ENDPOINT_SETTINGS"}
                    className={
                      reportId === "ENDPOINT_SETTINGS"
                        ? "reports-btn-grp-cdvdfvvisited"
                        : ""
                    }
                    onClick={() => setReportId("ENDPOINT_SETTINGS")}
                  >
                    Endpoint Settings Report
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      {reportId === "OFFLINE_REPORT" && <Reporting />}
      {reportId === "ENDPOINT_SETTINGS" && <EndpointSettingReport />}
      {reportId === "ACTIVE_LICENSE" && (
        <Row>
          <div style={{ marginTop: 10 }}>
            <ToolkitProvider
              keyField="id"
              data={reportData}
              columns={report.columns}
              noDataIndication="Data not available"
              exportCSV={{
                fileName: `${
                  report.title
                } - ${new Date().toLocaleDateString()}.csv`,
              }}
            >
              {(props) => (
                <div style={{ marginBottom: 10 }}>
                  <div
                    className="allign-center"
                    style={{
                      marginBottom: 10,
                      justifyContent: "space-between",
                      padding: "0px 5px",
                    }}
                  >
                    <h2 style={{ padding: "10px 0 0 10px" }}>{reportTitle}</h2>
                    <ExportCSVButton {...props.csvProps}>
                      Export as CSV
                    </ExportCSVButton>
                  </div>
                  {reportId === "ACTIVE_LICENSE" && !isLoading && (
                    <ActiveLicense data={reportData} />
                  )}
                  <div style={{ marginTop: 15, paddingTop:10 }}>
                    <BootstrapTable
                      {...props.baseProps}
                      noDataIndication={
                        isLoading ? (
                          <Spinner type="grow" color="primary" />
                        ) : (
                          "No Data"
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </ToolkitProvider>
          </div>
        </Row>
      )}
    </Container>
  );
}
