import { result } from "lodash";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Col, Container, Row } from "reactstrap";

import WebAPI from 'services/WebAPI'

import CustomerTable from './table'

const CustomerList = () => {
  const [rowData, setRowData] = useState([]);
  const [active, setActive] = useState(true)
  const [loading, setLoading] = useState(false)

  let history = useHistory();

  useEffect(() => {
    loadCustomers()
  }, [active]);

  const loadCustomers = () => {
    setLoading(true)
    setRowData([])
    WebAPI
      .get(`/Customers/List?active=${active ? 'true' : 'false'}`)
      .then(({ data: res }) => {
        if (res.status)
          setRowData(res.data)
      })
      .finally(() => setLoading(false))
  }

  const OnRowClick = (row) => {
    history.push('/customers/customer_details/' + row.Id)
  }

  const OnDelete = (ids) => {
    console.log(ids)
  }



  return (
    <Container className="container-main" fluid>
      <div className="common-headers">Customers</div>
      <Row>
        <div className="col">
          <CustomerTable customers={rowData} active={active} setActiveState={setActive} OnRowClick={OnRowClick} onDelete={OnDelete} loading={loading} />
        </div>
      </Row>
    </Container>
  );
};

export default CustomerList;