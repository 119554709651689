import React from "react";
import { Modal } from "reactstrap";
import _ from "lodash";
import moment from "moment";

const FormRow = ({ title, children }) => (
  <div style={{ display: "flex", width: "100%", fontSize: 12 }}>
    <p style={{ fontWeight: "bold", width: 100, fontSize: 12 }}>{title}</p>
    <p style={{ marginLeft: 5, marginRight: 10, fontSize: 12 }}>:</p>
    <p style={{ flex: 1, fontSize: 12 }}>{children}</p>
  </div>
);

function AttackView({ attack, onClose }) {
  return (
    <Modal isOpen={attack} toggle={() => onClose()}>
      <div className="modal-header" style={{ fontWeight: "bold" }}>
        Alert Details
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            onClose();
          }}
        >
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body" style={{ paddingTop: 0 }}>
        <FormRow title="Time">
          {attack
            ? moment(_.get(attack, "Attack.Time")+(_.get(attack, "Attack.Time")[_.get(attack, "Attack.Time").length-1]==="Z"?"":"Z")).format(
                "MMMM Do YYYY, h:mm:ss a"
              )
            : ""}
        </FormRow>
        <FormRow title="Process">{_.get(attack, "Attack.ProcessName")}</FormRow>
        <FormRow title="Process Id">
          {_.get(attack, "Attack.ProcessId")}
        </FormRow>
        <FormRow title="Last File Name">
          {_.get(attack, "Attack.LastFileName")}
        </FormRow>
        <FormRow title="Device">{_.get(attack, "Device.Hostname")}</FormRow>
        <FormRow title="Ip Address">
          {_.get(attack, "Attack.IpAddress")}
        </FormRow>
        <FormRow title="User">{_.get(attack, "Attack.User")}</FormRow>
        <FormRow title="Customer">
          {_.get(attack, "Customer.CompanyName")}
        </FormRow>
      </div>
    </Modal>
  );
}

export default AttackView;
