import { Redirect, useHistory } from "react-router-dom"

import { useSelector } from 'react-redux'

function RequireAuth({ children }) {
  const isAuthenticated = useSelector(store => store.authentication.loggedIn)
  const history = useHistory()
  const nextUrl = encodeURIComponent(history.location.pathname + history.location.search)
  return isAuthenticated === true
    ? children : <Redirect to={`/auth/login?next_url=${nextUrl}`} replace />;
}

export default RequireAuth