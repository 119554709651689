import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row } from "reactstrap";
import WebAPI from "services/WebAPI";
import EndpointsTable from "./EndpointTable";

const EndpointList = () => {
  const search = useLocation().search;
  const device = new URLSearchParams(search).get("device");
  const version = new URLSearchParams(search).get("version")?.split(",");
  const status = new URLSearchParams(search).get("status");
  const firewall = new URLSearchParams(search).get("firewall");
  const health = new URLSearchParams(search).get("health");
  const failedUpdates = new URLSearchParams(search).get("failedUpdates");
  const [endPointWholeList, setEndPointList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkFilter, setCheckFilter] = useState(true);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    loadEndpoints();
    loadCustomers();
  }, []);

  const loadEndpoints = () => {
    setLoading(true);
    WebAPI.get("/Endpoints/List")
      .then(({ data: res }) => {
        if (res.status) {
          setEndPointList(res.data);
        }
      })
      .catch((err) => console.log("error", err))
      .finally(() => setLoading(false));
  };
  
  const loadCustomers = () => {
    setCustomerList([]);
    WebAPI.get(`/Customers/List?active=true`)
      .then(({ data: res }) => {
        if (res.status) setCustomerList(res.data);
      })
      .catch((err)=>console.log('err', err))
  };

  useEffect(() => {
    if (device || version || status || firewall || health || failedUpdates)
      setCheckFilter(false);
    else setCheckFilter(true);
  }, [device, failedUpdates, firewall, health, status, version]);

  return (
    <>
      <Container className="container-main" fluid>
        <div className="common-headers">Endpoints</div>
        <Row>
          <div className="col">
            <EndpointsTable
              intialFilter={checkFilter}
              reload={loadEndpoints}
              customerList={customerList}
              dashFilter={{ failedUpdates }}
              endpoints={endPointWholeList
                .filter(
                  (i) => (device && i.DeviceType === device) || (!device && i)
                )
                .filter(
                  (i) =>
                    (status && i.OnlineStatus === status) ||
                    (version && version?.includes(i.Version)) ||
                    (firewall &&
                      i.FirewallStatus ===
                        (firewall === "true" ? true : false)) ||
                    (health &&
                      i.HealthStatus === (health === "true" ? true : false)) ||
                    (failedUpdates === "true" &&
                      (i.UpdateServiceStatus === "Not Available" ||
                        i.UpdateServiceStatus.includes(
                          "Update failed while updating from"
                        ))) ||
                    (!version &&
                      !status &&
                      !firewall &&
                      !health &&
                      !failedUpdates &&
                      i)
                )}
              loading={loading}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EndpointList;
