import { endpointFiltersConstants } from '../_constants';
const initialState = { filters: {} }

export function endpointFilters(state = initialState, action) {
    switch (action.type) {
        case endpointFiltersConstants.CHANGE:
            return {
                type: 'filter-change',
                filters: action.filters
            };
        default:
            return state
    }
}