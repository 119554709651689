import { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  Label,
  CardBody,
  Container,
  FormGroup,
  FormFeedback,
} from "reactstrap";

import Button from 'components/Inputs/TButton'
import AlertMessage from 'components/Alert'
import { alertActions } from 'redux/_actions'
import genreateProductKey from 'utils/getSerialKey'
import WebAPI from 'services/WebAPI'

const LicenseAdd = () => {
  let history = useHistory();

  const dispatch = useDispatch()

  const { id } = useParams();

  const { role = "Partner" } = useSelector(s => s.authentication.user) || {}

  const [formState, setFormState] = useState({
    Key: genreateProductKey(),
    Server: 99999,
    Desktop: 99999,
    ExpiryDate: "2099-12-31",
    Type: "Paid",
    OrderId: -1
  });
  const [invalidFormState, setInvalidFormState] = useState({
    Server: false,
    Desktop: false,
    ExpiryDate: false,
    OrderId: false,
    Type: false
  });

  const [loading, setLoading] = useState(false)

  const handleFormValueChange = ({ target: { id, value } }) => {
    invalidFormState[id] = false
    formState[id] = value
    setFormState({ ...formState })
    setInvalidFormState({ ...invalidFormState })
  }

  const handleSave = (e) => {
    e.preventDefault()
    if (formState.Desktop === formState.Server && formState.Desktop === 0) {
      dispatch(alertActions.error("Please enter a valid count for server and desktop"))
      return
    }
    if (loading)
      return
    setLoading(true)

    const { Server, Desktop, ...formState2 } = formState

    WebAPI
      .post('/Licenses/Create', { ...formState2, CustomerId: parseInt(id), Server: parseInt(Server), Desktop: parseInt(Desktop) })
      .then(({ data: res }) => {
        if (res.status)
          history.push('/customers/customer_details/' + id)
        else
          dispatch(alertActions.error(res.message))
      })
      .catch(() => dispatch(alertActions.error("Connection failed")))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.go(-1)}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          New License
        </div>
        <Row>
          <Col xs="5">
            <Card className="shadow">
              <CardBody>
                <Form role="form" onSubmit={handleSave}>
                  <FormGroup>
                    <AlertMessage />
                  </FormGroup>
                  <FormGroup>
                    <Label for="key">Key</Label>
                    <Input
                      id="key"
                      value={formState.Key}
                      type="text"
                      disabled
                    />
                  </FormGroup>
                  {role !== 'admin' || (
                    <>
                      <FormGroup>
                        <Label for="Server">Server</Label>
                        <Input
                          id="Server"
                          value={formState.Server}
                          invalid={invalidFormState.Server}
                          onChange={handleFormValueChange}
                          type="number"
                          min={0}
                        />
                        <FormFeedback>Please enter a number of Server</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="Desktop">Desktop</Label>
                        <Input
                          id="Desktop"
                          value={formState.Desktop}
                          invalid={invalidFormState.Desktop}
                          onChange={handleFormValueChange}
                          min={0}
                          type="number"
                        />
                        <FormFeedback>Please enter a number of Desktop</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="ExpiryDate">Expiration Date</Label>
                        <Input
                          id="ExpiryDate"
                          value={formState.ExpiryDate}
                          invalid={invalidFormState.ExpiryDate}
                          onChange={handleFormValueChange}
                          type="date"
                        />
                        <FormFeedback>Please select an expiration date</FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Label for="type">Type</Label>
                        <Input
                          id="Type"
                          value={formState.Type}
                          invalid={invalidFormState.Type}
                          onChange={handleFormValueChange}
                          type="select"
                        >
                          <option value="Paid">Paid</option>
                          <option value="Trial">Trial</option>
                          <option value="Partner">Partner</option>
                        </Input>
                        <FormFeedback>Please select a type</FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Label for="OrderId">Order ID</Label>
                        <Input
                          id="OrderId"
                          value={formState.OrderId}
                          invalid={invalidFormState.OrderId}
                          onChange={handleFormValueChange}
                          type="number"
                        />
                        <FormFeedback>Please enter a order ID</FormFeedback>
                      </FormGroup>
                    </>
                  )}

                  <Row>
                    <Button
                      className={"edit-btn mt-4 ml-3 common-btn"}
                      type="submit"
                      color="primary"
                      isLoading={loading}
                    >
                      Create
                    </Button>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default LicenseAdd;