import AlertMessage from "components/Alert";
import TButton from "components/Inputs/TButton";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {useSelector} from "react-redux";
import {
  Container,
  Button,
  Col,
  Row,
  CardBody,
  Card,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner, Form,FormGroup, Input,Label,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";

import { alertActions } from "redux/_actions/";

import WebAPI from "services/WebAPI";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import Logs from "./logs";
import moment from "moment";

function DetailsRow({ title, value }) {
  return (
    <Row className="border-b" style={{ padding: 5 }}>
      <Col>
        <strong>{title}</strong>
      </Col>
      <Col>{value}</Col>
    </Row>
  );
}

function FolderProtectionList({ id = 0 }) {
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    WebAPI.get(`Endpoints/FolderList/${id}`)
      .then(({ data: res }) => {
        if (res.status) setList(res.data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {(isLoading && (
        <center>
          <Spinner type="grow" color="primary" />
        </center>
      )) || (
        <div>
          <Row style={{ marginBottom: 2 }}>
            <Col>Total : {list.length}</Col>
          </Row>
          <Row>
            <Col>
              <BootstrapTable
                keyField="path"
                columns={[
                  { dataField: "path", text: "Path", filter: textFilter() },
                ]}
                data={list.map((i) => ({ path: i }))}
                pagination={paginationFactory({
                  sizePerPage: 5,
                  hideSizePerPage: true,
                })}
                filter={filterFactory()}
              />
            </Col>
          </Row>
        </div>
      )}
    </>
  );
}

const PopConfirm = ({ onOk, inProgress }) => {
  const [modal, SetModal] = useState(false);
  const [uninstall, setUninstall]= useState(true)
  const handleConfirm = () =>{
    //add new api for uninstall
    if (onOk) onOk(uninstall)
     SetModal(false)
  }

  return (
    <>
      <TButton
        color="danger"
        isLoading={inProgress}
        onClick={() => SetModal(!modal)}
      >
        Retire
      </TButton>
      <Modal isOpen={modal} toggle={() => SetModal(false)}>
        <ModalHeader
          className="confirm-modal-header"
          toggle={() => SetModal(false)}
        >
          Are you sure to continue?
        </ModalHeader>
        <ModalBody className="confirm-modal-body">
        The endpoint will be retired as a result of this action.
        
        <Form>
          <FormGroup
            check
            inline
            style={{marginTop:10}}
          >
            <Input type="checkbox" checked={uninstall} onChange={()=>setUninstall(!uninstall)}/>
            <Label check>
              Uninstall from Endpoint
            </Label>
          </FormGroup>
          </Form>

        </ModalBody>
        <ModalFooter className="confirm-modal-footer">
          <Button color="danger" size="sm" onClick={handleConfirm}>
            <i className="far fa-trash-alt" style={{ marginRight: 5 }} />
            Continue
          </Button>{" "}
          <Button size="sm" outline onClick={() => SetModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const PopUpdate = ({ onOk, inProgress }) => {
  const [isUpdate, SetUpdate] = useState(false);

const handleConfirm = () =>{
  if (onOk) onOk()
  SetUpdate(false)
}

return (
  <>
    <TButton outline isLoading={inProgress} onClick={()=>SetUpdate(!isUpdate)}>Force Update</TButton>
    <Modal isOpen={isUpdate} toggle={()=>SetUpdate(false)}>
      <ModalHeader
        className="confirm-modal-header"
        toggle={()=>SetUpdate(false)}
      >
        Are you sure to continue?
      </ModalHeader>
      <ModalBody className="confirm-modal-body">
      The endpoint will be force updated as a result of this action.
      </ModalBody>
      <ModalFooter className="confirm-modal-footer">
        <Button color="primary" size="sm" onClick={handleConfirm}>
          Continue
        </Button>
        <Button size="sm" outline onClick={()=>SetUpdate(false)}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  </>
);
};

export default function Details() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [details, setDetails] = useState({});
  const [isLog, setLog] = useState(false);

  const [folderProtectionModal, setFolderProtectionModel] = useState(false);

  const [loadingDetails, setLoadingDetails] = useState(false);

  const user = useSelector(store => store.authentication.user) 
  
  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = () => {
    dispatch(alertActions.clear());

    if (loadingDetails) return;
    setLoadingDetails(true);
    WebAPI.get(`/Endpoints/Details/${id}`)
      .then(({ data: res }) => {
        if (res.status) setDetails(res.data);
        else dispatch(alertActions.error(res.message));
      })
      .finally(() => setLoadingDetails(false));
  };

  const [refreshing, setRefreshing] = useState(false);
  const handleRefresh = () => {
    dispatch(alertActions.clear());
    if (refreshing) return;
    setRefreshing(true);
    WebAPI.post(`/Endpoints/Refresh/${id}`)
      .then(({ data: res }) => {
        if (res.status) getDetails();
        else dispatch(alertActions.error("Device not available"));
      })
      .finally(() => setRefreshing(false));
  };

  const [isRetireProgress, setRetireProgress] = useState(false)
  const [isUpdateProgress, setUpdateProgress] = useState(false)

  const handleRetire = (uninstall) => {
    dispatch(alertActions.clear())
    if(isRetireProgress)
      return
    setRetireProgress(true)
    WebAPI
      .post(`/Endpoints/Retire/${id}?uninstall=${uninstall}`)
      .then(({ data: res }) => {
        if (res.status)
          history.go(-1)
        else
          dispatch(alertActions.error('Device not available'))
      })
      .finally(() => setRetireProgress(false))
  }

  const handleUpdate = () => {
    dispatch(alertActions.clear())
    if(isUpdateProgress)
      return
    setUpdateProgress(true)
    WebAPI
      .get(`UpgradeManager/TriggerForceUpdateClient?deviceId=${id}`)
      .then(({ data: res }) => {
        if (res.status)
        dispatch(alertActions.success('Force updated Sucessfully'))
        else
          dispatch(alertActions.error('Device not available'))
        setTimeout(() => {
          dispatch(alertActions.clear())
        }, 2500);
      })
      .finally(() => setUpdateProgress(false))
  }


  return (
    <Container className="container-modal" fluid>
      <div className="common-headers">
        <Button
          className="mr-2 common-btn-back"
          outline
          onClick={() => history.go(-1)}
        >
          <i className="fas fa-chevron-left" />
        </Button>
        Endpoint Details
      </div>
      <Row>
        <Col>
          {isLog && (
            <Row>
              <Col>
                <AlertMessage />
              </Col>
            </Row>
          )}
          <Row>
            <Col style={{ display: "flex" }}>
              <h2>Summary</h2>
            </Col>
            <Row>
            <div style={{ float:"right", display:"flex", marginRight:30 }}>
              <PopUpdate inProgress={isUpdateProgress} onOk={handleUpdate} />
              {(user.role === "partner" || user.role === "admin") && (
                <Logs
                  isOnline={details.device?.OnlineStatus}
                  Log={(log) => setLog(log)}
                />
              )}
              <PopConfirm inProgress={isRetireProgress} onOk={handleRetire} />
            </div>
            </Row>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <Card>
                <CardBody>
                  <DetailsRow
                    title="Device ID"
                    value={details.device?.DeviceId}
                  />
                  <DetailsRow
                    title="Customer"
                    value={`${details.device?.CustomerName}`}
                  />
                  <DetailsRow
                    title="Hostname"
                    value={details.device?.HostName}
                  />
                  <DetailsRow
                    title="Online Status"
                    value={details.device?.OnlineStatus}
                  />
                  <DetailsRow
                    title="Activation Status"
                    value={details.device?.ActivationStatus}
                  />
                  <DetailsRow
                    title="Device Type"
                    value={details.device?.DeviceType}
                  />
                  <DetailsRow
                    title="License Type"
                    value={details.device?.LicenseType}
                  />
                  <DetailsRow title="Version" value={details.device?.Version} />
                  <DetailsRow
                    title="Serial"
                    value={details.device?.LicenseKey}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <h2>Running Services</h2>
            </Col>
            <Col>
              <TButton
                style={{ float: "right" }}
                color="primary"
                onClick={handleRefresh}
                isLoading={refreshing}
              >
                Refresh
              </TButton>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <Card>
                <CardBody>
                  <DetailsRow
                    title="Tray Icon"
                    value={details.services?.TrayIcon}
                  />
                  <DetailsRow
                    title="Update Service"
                    value={details.services?.UpdateService}
                  />
                  <DetailsRow
                    title="Last Updated"
                    value={moment(
                      details?.services?.LastUpdate +
                        (details?.services?.LastUpdate[
                          details?.services?.LastUpdate.length - 1
                        ] === "Z"
                          ? ""
                          : "Z")
                    ).format("DD/MM/YYYY, hh:mm:ss a")}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ marginTop: 20 }}></div>
          <Row>
            <Col>
              <h2>Agent Settings</h2>
            </Col>
            <Col>
              <TButton
                style={{ float: "right" }}
                color="primary"
                onClick={() =>
                  history.push(`/endpoints/endpoint_settings/${id}`)
                }
              >
                Change
              </TButton>
            </Col>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <Col>
              <Card>
                <CardBody>
                  <DetailsRow title="Email" value={details.settings?.Email} />
                  <DetailsRow
                    title="User Auto Protect"
                    value={
                      details.settings?.UserAutoProtect
                        ? "Active"
                        : "Not Active"
                    }
                  />
                  <DetailsRow
                    title="Driver Detection"
                    value={
                      details.settings?.DriverDetectionMode
                        ? "Active"
                        : "Not Active"
                    }
                  />
                  <DetailsRow
                    title="Native File Monitor"
                    value={
                      details.settings?.NativeProtection
                        ? "Active"
                        : "Not Active"
                    }
                  />
                  <DetailsRow
                    title="Extention Change Monitor"
                    value={
                      details.settings?.ExtMonitor ? "Active" : "Not Active"
                    }
                  />
                  <DetailsRow
                    title="Folder Protection"
                    value={
                      <span
                        style={{
                          color: "rgb(30, 141, 204)",
                          cursor: "pointer",
                        }}
                        role="presentation"
                        onClick={() => setFolderProtectionModel(true)}
                      >
                        Show
                      </span>
                    }
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        isOpen={folderProtectionModal}
        size="lg"
        toggle={() => setFolderProtectionModel(false)}
        UnmountOnClose
      >
        <div className="modal-header">
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setFolderProtectionModel(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody style={{ paddingTop: 0 }}>
          <FolderProtectionList id={id} />
        </ModalBody>
      </Modal>
    </Container>
  );
}
