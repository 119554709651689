import { useState, useEffect } from "react";
import { Button, Col, Row, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import { useSelector } from "react-redux";
import store from "store";
import _ from "lodash";
import semver from "semver";

/* import EndpointFilter from "./popover";
import fetchFilteredEndpoints from "./helper"; */
import { useHistory } from "react-router-dom";

import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
const semverSort = require("semver/functions/sort");

const { SearchBar } = Search;

export default function ReportingTable(props) {
  const [filterPopover, toggleFilterPopover] = useState(false);
  const [Filters, setFilters] = useState({
    version: [],
    activation: [],
    deviceType: [],
    status: [],
    license: [],
  });

  const [rowData, setRowData] = useState([]);
  const { ExportCSVButton } = CSVExport;

  useEffect(() => {
    const { endpoints } = props;
    setRowData(endpoints);
  }, [props]);

  const { theme } = useSelector((state) => state.authentication.user) || {
    theme: "white",
  };
  const user = useSelector((s) => s.authentication.user);

  const columns = [
    {
      dataField: "deviceId",
      text: "ID",
      sort: true,
      searchable: true,
      headerStyle: { width: "70px", padding: "15px 5px" },
    },
    {
      dataField: "companyName",
      text: "Company",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "hostName",
      text: "Hostname",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "version",
      text: "Version",
      classes: "common-table-column-style",
      sort: true,
      sortFunc: (a, b, order) => {
        a = a === "" ? "0.0.0" : a;
        b = b === "" ? "0.0.0" : b;
        if (order === "asc") {
          return semver.compare(a, b) === 1 ? 1 : -1;
        } else {
          return semver.compare(a, b) === 1 ? -1 : 1;
          // desc
        }
      },
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "timeStamp",
      text: "Last Contact",
      classes: "common-table-column-style",
      sort: true,
      searchable: false,
      style: { whiteSpace: "normal" },
      formatter: function (cell, row) {
        return new Date(cell).toLocaleString();
      },
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "PartnerName",
      hidden: true,
      csvExport:false
    },
  ];

  const paginationOptions = {
    custom: true,
    withFirstAndLast: true,
    totalSize: rowData?.length,
  };

  const { loading } = props;

  const filter_applied = !_.values(Filters).every(_.isEmpty);

  return (
    <ToolkitProvider
      keyField="DeviceId"
      data={rowData || []}
      columns={columns}
      search
      remote
      exportCSV={{
        fileName: "offline_report.csv",
      }}
    >
      {(props) => (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <Row style={{ marginBottom: 10, marginTop: 10 }}>
                <Col
                  xs="4"
                  className="local-text"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Entries per page :
                  <SizePerPageDropdownStandalone
                    className="common-page-size-selector ml-2"
                    {...paginationProps}
                  />
                </Col>
                <Col xs="8" className="d-flex justify-content-end">
                  <Row>
                    <SearchBar
                      className="table-search-input"
                      {...props.searchProps}
                    />
                    <ExportCSVButton {...props.csvProps}>
                      Export as CSV
                    </ExportCSVButton>
                  </Row>
                </Col>
              </Row>
              <BootstrapTable
                hover
                bordered={false}
                noDataIndication={
                  loading ? <Spinner type="grow" color="primary" /> : "No Data"
                }
                headerWrapperClasses="table-header-light"
                bodyClasses="clickable-table-row"
                {...props.baseProps}
                {...paginationTableProps}
                rowStyle={
                  {
                    //backgroundColor: '#fff',
                  }
                }
              />
              <Row className="mt-2">
                <Col>
                  <PaginationTotalStandalone {...paginationProps} />
                </Col>
                <Col>
                  <PaginationListStandalone {...paginationProps} />
                </Col>
              </Row>
            </div>
          )}
        </PaginationProvider>
      )}
    </ToolkitProvider>
  );
}
