import React, { useEffect, useState } from "react";
import { Input, Label, FormGroup } from "reactstrap";
import Button from "components/Inputs/TButton";
import { Link } from "react-router-dom";
import _ from "lodash";
import { useLocation } from "react-router-dom";

import WebApi from "services/WebAPI";

function Reset() {
  const [mail, setMail] = useState("");
  const [pass1, setPass1] = useState("");
  const [pass2, setPass2] = useState("");
  const [code, setCode] = useState();
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmit] = useState(false);

  const location = useLocation();
  
  useEffect(() => {
    const id = location.search.replace("?code=", "");
    setCode(id);
  }, []);

  const handleSubmit = () => {
    if (!mail || !pass1 || !pass2 || pass1 !== pass2) return;
    setLoading(true);
    const req = {
      Code: code,
      Email: mail,
      Password: pass1,
    };
    WebApi.post(`ResetPasswordAPI/ResetPassword?Code=${code}&Email=${mail}&Password=${pass1}`, req)
      .then(({ data: res }) => {
        if(res.status)
        setSubmit(true);
      })
      .finally(() => setLoading(false));
  };

  if (submitted)
    return (
      <>
        <p style={{ fontSize: 14, marginBottom: 20 }}>
          Your password has been reset. Please{" "}
          <Link to="/auth/login" replace style={{ fontSize: 14 }}>
            click here to login
          </Link>
        </p>
      </>
    );

  return (
    <>
      <FormGroup>
        <Label>Email</Label>
        <Input
          type="text"
          value={mail}
          onChange={(e) => setMail(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>password</Label>
        <Input
          type="password"
          value={pass1}
          onChange={(e) => setPass1(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Label>Confirm password</Label>
        <Input
          type="password"
          value={pass2}
          onChange={(e) => setPass2(e.target.value)}
        />
      </FormGroup>
      <FormGroup>
        <Button
          isLoading={isLoading}
          onClick={handleSubmit}
          type="submit"
          color="primary"
        >
          Reset
        </Button>
      </FormGroup>
    </>
  );
}

export default Reset;
