import { useState, useEffect } from "react";
import { Button, Col, Container, Row, Popover } from "reactstrap";
import WebAPI from "services/WebAPI";
import ReportingTable from "./table";
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  BarChart,
  Bar,
  YAxis,
  LineChart,
  XAxis,
  Cell,
  Line,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import semver from "semver";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const Reporting = () => {
  const [desktop, setDesktop] = useState([]);
  const [server, setServer] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchdata();
  }, []);
  const fetchdata = async () => {
    setLoading(true);
    try {
      const resp = await WebAPI.get(
        "/Report/FetchOfflineDesktopEndpointsReport"
      );
      const resp2 = await WebAPI.get(
        "/Report/FetchOfflineServerEndpointsReport"
      );
      if (resp.data.status) {
        const data = [];
        resp.data?.result.forEach((i) => {
          data.push({ ...i, type: "desktop" });
        });
        setDesktop(data);
      }

      if (resp2.data.status) {
        const data = [];
        resp2.data?.result.forEach((i) => {
          data.push({ ...i, type: "server" });
        });
        setServer(data);
      }
    } catch (error) {
      console.log("error", error);
    }
    setLoading(false);
  };

  const data01 = [
    { name: "Desktop", value: desktop.length || 0 },
    { name: "Server", value: server.length || 0 },
  ];

  /* const data02 = [...desktop, ...server]
    .map((i) => i.timeStamp)
    .map((i) => {
      const data = {
        name: moment(i).format("DD MMM YYYY"),
        server: server.filter((row) => row.timeStamp === i).length,
        desktop: desktop.filter((row) => row.timeStamp === i).length,
        amt: [...server, ...desktop].length,
      };
      return data;
    }); */

  const data03 = [...new Set([...desktop, ...server].map((i) => i.version))]
    .sort((a, b) => {
      return semver.compare(a || "0.0.0", b || "0.0.0") === 1 ? 1 : -1;
    })
    .map((i) => {
      const data = {
        name: i,
        server: server.filter((row) => row.version === i).length,
        desktop: desktop.filter((row) => row.version === i).length,
      };

      return data;
    });

  return (
    <>
      <Container className="container-main" fluid>
        <div className="common-headers">Agent Offline Report</div>
        {!loading && (
          <Row>
            <div className="col">
              <div
                style={{
                  display: "flex",
                  height: 400,
                  width: "100%",
                  marginTop: 10,
                }}
              >
                <div style={{ flex: 1 }}>
                  <div style={{ width: "100%", height: "100%" }}>
                    <h5>Ratio of Offline Desktop & Servers</h5>
                    <ResponsiveContainer>
                      <PieChart>
                        <Legend
                        //payload={data01}
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Pie
                          data={data01 || []}
                          labelLine={false}
                          label={renderCustomizedLabel}
                          outerRadius={150}
                          fill="#8884d8"
                          dataKey="value"
                        >
                          {data01.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                        <Tooltip />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <div style={{ height: "100%", width: "100%" }}>
                    <h5 style={{ marginLeft: 15 }}>
                      Offline Endpoints v/s Versions
                    </h5>
                    <ResponsiveContainer>
                      <BarChart data={data03}>
                        <CartesianGrid
                          strokeDasharray="3 3"
                          horizontal={false}
                        />
                        <XAxis
                          dataKey="name"
                          label={{ value: "Versions", dy: 10 }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend
                          layout="horizontal"
                          verticalAlign="top"
                          align="center"
                        />
                        <Bar dataKey="server" fill="#8884d8" />
                        <Bar dataKey="desktop" fill="#82ca9d" />
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        )}
        <Row style={{ marginTop: "2%" }}>
          <ReportingTable
            endpoints={[...desktop, ...server] || []}
            loading={loading}
          />
        </Row>
      </Container>
    </>
  );
};

export default Reporting;
