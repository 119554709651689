import { useSelector } from "react-redux";
import { Container, Row, Col, Card, CardBody, Button, ButtonGroup } from "reactstrap";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";


const SettingsBlock = styled.div`
box-sizing: border-box;
border-radius: 3px;
border:1px solid ${props => props.theme === 'dark' ? '#4E5054' : '#E8E8E8'};
background-color: ${props => props.theme === 'dark' ? '#2F3032' : '#FFFFFF'};
height:80px;
margin-bottom:10px;
`

const Settings = ({ button, iconSvg, title, subtitle }) => {
  const { theme = 'white' } = useSelector(store => store.authentication.user)
  return (
    <SettingsBlock theme={theme}>
      <div style={{ width: 60, height: 60, borderRadius: 6, background: theme === 'dark' ? 'rgba(30, 141, 204, 0.2)' : '#DFF3FF', margin: 10, padding: 13, display: 'inline-block' }}>
        {iconSvg}
      </div>
      <div style={{ display: 'inline-block' }}>
        <div style={{ position: "absolute" }}>
          <span style={{ color: theme==='dark'?'#1E8DCC':'#2D6DA4', fontWeight: 'bold', fontSize: '.8rem', display: 'block', marginTop: -20 }}>{title}</span>
          <span style={{ color: theme==='dark'?'#ffffff':'#777777', fontWeight: 'normal', fontSize: '.8rem', display: 'block' }}>{subtitle}</span>
        </div>
      </div>
      <div style={{ display: 'inline-block', float: "right", margin: 18 }}>
        {button}
      </div>
    </SettingsBlock>
  )
}

export default function Integrations() {

  
  const history = useHistory()
  const { theme = 'white', role } = useSelector(store => store.authentication.user)
  return (
    <Container className="container-main container-fluid" style={{ marginLeft: 0 }}>
      <div className="common-headers">
        Integration
      </div>
      <Helmet>
        <title>
          {"Integration"}
        </title>
      </Helmet>
      <Row>
        <Col md={8}>
          <Card className=" shadow">
            <CardBody>
              <Settings
                button={<Button color="primary" onClick={() => history.push('/integrations/acronis')}>Configure</Button>}
                iconSvg={(
                  <svg width="34" height="34" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.93047 13.2107L6.66782 10.3728H6.73089L7.45854 13.2107H5.93047ZM8.17164 16H9.66089L7.56041 9H5.93047L3.82999 16H5.20767L5.65396 14.2876H7.73505L8.17164 16Z" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"}/>
                    <path d="M10.5389 9V16H11.9166V13.7782H13.0323C14.541 13.7782 15.5015 12.8517 15.5015 11.3964C15.5015 9.92654 14.5701 9 13.1003 9H10.5389ZM11.9166 10.1303H12.751C13.6533 10.1303 14.1044 10.5475 14.1044 11.3867C14.1044 12.2308 13.6533 12.6431 12.751 12.6431H11.9166V10.1303Z" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"}/>
                    <path d="M21.1675 16V14.8164H19.717V10.1836H21.1675V9H16.8889V10.1836H18.3393V14.8164H16.8889V16H21.1675Z" fill={theme==='dark'?"#1E8DCC":"#2E6DA4"}/>
                  </svg>
                )}
                title="Acronis API"
                subtitle="Configure Acronis API here"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )

}