import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash";
import {
  Col,
  Row,
  Form,
  Input,
  Label,
  Button,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { endpointFilterActions } from "redux/_actions";


const EndpointFilter = ({ data, Filters, applyFilter, clearFilter }) => {

  const [versionList, setVersionList] = useState([]);
  const [activationList, setActivationList] = useState([]);
  const [deviceTypeList, setDeviceTypeList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [licenseList, setLicenseList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    const newList = {
      versionList: _.keys(_.countBy(data, function (rD) { return rD.Version })),
      activationList: _.keys(_.countBy(data, function (rD) { return rD.ActivationStatus })),
      deviceTypeList: _.keys(_.countBy(data, function (rD) { return rD.DeviceType })),
      statusList: _.keys(_.countBy(data, function (rD) { return rD.OnlineStatus })),
      licenseList: _.keys(_.countBy(data, function (rD) { return rD.LicenseType }))
    }
    setVersionList(newList.versionList)
    setActivationList(newList.activationList)
    setDeviceTypeList(newList.deviceTypeList)
    setStatusList(newList.statusList)
    setLicenseList(newList.licenseList)
  }, [data]);

  const handleChange = ({ target }) => {
    const newFilters = Filters
    if(target.checked){
      newFilters[target.id].push(target.name)
    }
    else{
      newFilters[target.id] = newFilters[target.id].filter(v => v != target.name)
    }
    applyFilter(newFilters)
  }

  return (
    <Form role="form">
      <PopoverHeader>
        <Row>
          <Col xs="9">
            <p className="endpoint-popover-header">Filter List</p>
          </Col>
          <Col xs="2">
            <Button
              onClick={clearFilter}
              outline
              className="common-btn-outlined"
              color="secondary"
              type="button"
            >
              Clear
            </Button>
          </Col>
        </Row>
      </PopoverHeader>
      <PopoverBody>
        <Row>
          <Col>
            <Label for="version"><p className="endpoint-popover-subheader">Version</p></Label>
            <div className="ep-content">
              {versionList.map(i => <div>
                <Input type="checkbox" name={i} checked={Filters.version.includes(i)} id="version" onChange={handleChange} /> {i}
              </div>)}
            </div>
          </Col>
          <Col>
            <Label for="activation"><p className="endpoint-popover-subheader">Activation</p></Label>
            <div className="ep-content">
              {activationList.map(i => <div>
                <Input type="checkbox" name={i} checked={Filters.activation.includes(i)} id="activation" onChange={handleChange} /> {i}
              </div>)}
            </div>
          </Col>
          <Col>
            <Label for="devicetype"><p className="endpoint-popover-subheader">Device Type</p></Label>
            <div className="ep-content">
              {deviceTypeList.map(i => <div>
                <Input type="checkbox" name={i} checked={Filters.deviceType.includes(i)} id="deviceType" onChange={handleChange} /> {i}
              </div>)}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Label for="status"><p className="endpoint-popover-subheader">Status</p></Label>
            <div className="ep-content">
              {statusList.map(i => <div>
                <Input type="checkbox" name={i} checked={Filters.status.includes(i)} id="status" onChange={handleChange} /> {i}
              </div>)}
            </div>
          </Col>
          <Col>
            <Label for="license"><p className="endpoint-popover-subheader">License</p></Label>
            <div className="ep-content">
              {licenseList.map(i => <div>
                <Input type="checkbox" name={i} checked={Filters.license.includes(i)} id="license" onChange={handleChange} /> {i}
              </div>)}
            </div>
          </Col>
          <Col />
        </Row>
      </PopoverBody>
    </Form>
  )
}

export default EndpointFilter;