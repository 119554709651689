import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  Label,
  Input,
  Button,
  FormGroup,
  Form,
} from "reactstrap";
import WebAPI from "services/WebAPI";

export default function ChangeChannel({ open, OnClose, handleSave }) {
  const [row, setRow] = useState([]);
  const [channel, setChannel]= useState('')

  useEffect(() => {
    if(open){
      loadChannels();
    }
  }, [open]);

  const loadChannels = () => {
    WebAPI.get("/UpgradeManager/FetchAllPackageChannels").then(
      ({ data: res }) => {
        if (res.status) setRow(_.sortBy(res.result,'Priority'));
      }
    );
  };

  const _handleSave = () => {
    if(open.Id && channel){
      handleSave(open.Id, channel)
      OnClose()
    }
  }

  return (
    <div>
      <Modal isOpen={open} toggle={OnClose}>
        <ModalHeader toggle={OnClose}>Change Channel</ModalHeader>
        <Form inline style={{padding:10, marginLeft:20}}>
          <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
            <Label for="exampleSelect" className="mr-sm-2">
             select Channel
            </Label>
            <Input value={channel} onChange={(e)=>setChannel(e.target.value)} style={{width:250}} type="select" name="select" id="exampleSelect">
              {row.map((i) => (
                <option disabled={!open||open.Type===i.Channel}>{i.Channel}</option>
              ))}
            </Input>
          </FormGroup>
          <Button outline color='primary' disabled={!channel} onClick={_handleSave}><i class="fas fa-save" /></Button>
        </Form>
      </Modal>
    </div>
  );
}
