const fetchFilteredEndpoints = (data,filters) => {
  let versionList = []
  let activationList = []
  let deviceTypeList = []
  let licenseList = []
  let statusList = []

  versionList = filters.version.length === 0 ? data : []
  filters.version.forEach(i => {
    versionList.push(...data.filter(el => el.Version === i))
  });

  activationList = filters.activation.length === 0 ? versionList : []
  filters.activation.forEach(i => {
    activationList.push(...versionList.filter(el => el.ActivationStatus === i))
  });

  deviceTypeList = filters.deviceType.length === 0 ? activationList : []
  filters.deviceType.forEach(i => {
    deviceTypeList.push(...activationList.filter(el => el.DeviceType === i))
  });
  
  licenseList = filters.license.length === 0 ? deviceTypeList : []
  filters.license.forEach(i => {
    licenseList.push(...deviceTypeList.filter(el => el.LicenseType === i))
  });

  statusList = filters.status.length === 0 ? licenseList : []
  filters.status.forEach(i => {
    statusList.push(...licenseList.filter(el => el.OnlineStatus === i))
  });
  return statusList
}

export default fetchFilteredEndpoints;