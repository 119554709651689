import {
  Container,
  Row,
  Card,
  CardBody,
  Col,
  Input,
  Label,
} from "reactstrap";

import { CSVExport } from "react-bootstrap-table2-toolkit";
import { useState } from "react";
import NotActivatedEnpointReport from "./notActivated";
import ServiceStatusEndpointReport from "./serviceStatus";

export default function EndpointSettingReport() {
  const [reportId, setReportId] = useState("SERVICE_STATUS");
  return (
    <Container className="container-main" fluid>
      <Row>
        <Card
          className="shadow"
          style={{ width: "100%", maxWidth: "100%", marginTop: 10 }}
        >
          <CardBody>
            <Row>
              <Col>
                <Label for="reportType">Report Type</Label>
                <Input
                  type="select"
                  name="Report Type"
                  id="reportType"
                  onChange={(e) => setReportId(e.target.value)}
                  value={reportId}
                >
                  <option value="SERVICE_STATUS">Service Status Report</option>
                  <option value="NOT_ACTIVATED">
                    Not-Activated Enpoint Report
                  </option>
                </Input>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>
      {reportId === "NOT_ACTIVATED" && <NotActivatedEnpointReport />}
      {reportId === "SERVICE_STATUS" && <ServiceStatusEndpointReport />}
    </Container>
  );
}
