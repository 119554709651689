import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Col, Container, Row, Spinner } from "reactstrap";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory,
{ PaginationProvider, SizePerPageDropdownStandalone, PaginationTotalStandalone, PaginationListStandalone }
  from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { useSelector } from 'react-redux'

const { SearchBar } = Search;

const CustomerTable = (props) => {
  const [selectedRow, setSelectedRow] = useState([]);
  const [rowData, setRowData] = useState([]);

  let history = useHistory();

  const prepareCustomerData = (customers) => {
    return customers.map(e => {
      return { ...e, Contact: `${e.FirstName} ${e.LastName}` }
    })
  }

  useEffect(() => {
    setRowData(prepareCustomerData(props.customers) || []);
    handleOnSelectAll(false, [])
  }, [props.customers]);

  const handleOnSelect = (row, isSelect) => {
    const newSelection = isSelect ?
      [...selectedRow, row.Id] : selectedRow.filter(x => x !== row.Id)
    setSelectedRow(newSelection)
  }

  const handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.Id);
    isSelect ? setSelectedRow(ids) : setSelectedRow([])
  }

  const handleInvactivate = () => {
    if (props.onDelete)
      props.onDelete(selectedRow)
  }

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (props.OnRowClick) {
        props.OnRowClick(row)
      }
    },
  };

  const setActiveState = (state) => {
    if (props.setActiveState)
      props.setActiveState(state)
  }

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: false,
    selected: selectedRow,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll
  };

  const columns = [
    {
      dataField: 'Id',
      text: 'ID',
      sort: true,
      classes: 'common-table-column-style',
      searchable: true,
      headerStyle: { width: '70px' },
    },
    {
      dataField: 'CompanyName',
      text: 'Company',
      classes: 'common-table-column-style',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'Contact',
      text: 'Contact',
      classes: 'common-table-column-style',
      sort: true,
      searchable: true
    },
    {
      dataField: 'ContactEmail',
      text: 'Email',
      classes: 'common-table-column-style',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'PartnerName',
      text: 'Partner',
      classes: 'common-table-column-style',
      sort: true,
      searchable: true,
    },
    {
      dataField: 'LicenseType',
      text: 'License Type',
      classes: 'common-table-column-style',
      sort: true,
      searchable: true,
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: rowData.length
  };

  const { active, loading } = props

  const user = useSelector(s => s.authentication.user)

  return (

    <ToolkitProvider
      keyField="Id"
      data={rowData}
      columns={columns}
      search
    >
      {props => (
        <PaginationProvider
          pagination={paginationFactory(paginationOptions)}
        >
          {
            ({
              paginationProps,
              paginationTableProps
            }) => (
              <div>
                <Row>
                  <Col md="4" className="local-text" style={{ display: 'flex', alignItems: 'center' }}>
                    <ButtonGroup className="mr-2">
                      <Button size="sm" color="primary" outline={!active} onClick={() => setActiveState(true)}>Active</Button>
                      <Button size="sm" color="primary" outline={active} onClick={() => setActiveState(false)}>Inactive</Button>
                    </ButtonGroup>
                    Entries per page :
                    <SizePerPageDropdownStandalone
                      className="common-page-size-selector ml-2"
                      {...paginationProps}
                    />
                    {selectedRow.length > 0 &&
                      <Button
                        className="common-btn-delete"
                        onClick={handleInvactivate}
                        outline
                      >
                        <i class={`fas fa-toggle-${active ? 'off' : 'on'}`} />
                      </Button>
                    }
                  </Col>
                  <Col>
                    <Row>
                      <Col className="d-flex justify-content-end">
                        <SearchBar
                          className="ml-4 table-search-input"
                          {...props.searchProps}
                        />
                        <Button
                          className="ml-2 common-btn"
                          onClick={() => history.push("/customers/add_customer")}
                        >
                          Add New Customer
                        </Button>
                        {user.role === 'admin' && (
                          <Button
                            className="common-btn"
                            onClick={() => history.push('/customers/global_agent_settings')}
                          >
                            Global Settings
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <BootstrapTable
                  hover
                  bordered={false}
                  selectRow={selectRow}
                  rowEvents={rowEvents}
                  noDataIndication={loading ? <Spinner type="grow" color="primary" /> : "No Data"}
                  headerWrapperClasses="table-header-light"
                  bodyClasses="clickable-table-row"
                  {...props.baseProps}
                  {...paginationTableProps}
                  rowStyle={{
                    //backgroundColor: '#fff',
                  }}
                />
                <Row style={{ marginTop: 10 }}>
                  <Col>
                    <PaginationTotalStandalone
                      {...paginationProps}
                    />
                  </Col>
                  <Col>
                    <PaginationListStandalone
                      {...paginationProps}
                    />
                  </Col>
                </Row>
              </div>
            )}
        </PaginationProvider>
      )}
    </ToolkitProvider>
  );
};

export default CustomerTable;