import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  CardBody,
  Container,
  Form,
  Input,
  Label,
  FormGroup,
  FormFeedback,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import WebAPI from "services/WebAPI";
import AlertMessage from "components/Alert";
import Button from "components/Inputs/TButton";

const EndpointSettingReport = () => {
  let history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [dates, setDates] = useState([]);

  const [formState, setFormState] = useState({
    reportId: 0,
    reportActive: true,
    NotificationEmails: "",
    serverCycle: "weekly",
    desktopCycle: "daily",
    serverWeeklyDay: 4,
    desktopWeeklyDay: 1,
    serverMonthlydate: 1,
    desktopMonthlydate: 1,
    reportTime: "02:30",
  });

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    Dates();
    //fetchData()
  }, []);

  const fetchData=async()=>{
    try {
      const resp= await WebAPI.get('/Report/FetchPartnerScheduledReports?partnerId=1')
      console.log('resp', resp)
    } catch (error) {
      console.log('error', error)
    }
  }

  const Dates = () => {
    let date = [];
    for (let i = 1; i < 29; i += 1) {
      date.push(i);
    }
    setDates(date);
  };

  const [invalidFormState, setInvalidFormState] = useState({
    reportActive: false,
    NotificationEmails: false,
  });

  const handleFormValueChange = ({ target: { id, value, checked } }) => {
    if (id === "reportActive") value = checked;
    invalidFormState[id] = false;
    formState[id] = value;
    setFormState({ ...formState });
    setInvalidFormState({ ...invalidFormState });
  };

  const prepareTime = (type) => {
    let time = new Date();
    const d = new Date();console.log('d', d)
    d.setDate(d.getDate() - d.getDay());
    const t = formState.reportTime.split(":");

    switch (type) {
      case "server":{
        if (formState.serverCycle === "daily") {
          d.setHours(t[0], t[1]);
          time = d;
          break
        }
        else if(formState.serverCycle === "weekly"){console.log('first', formState.serverWeeklyDay,d, d.getDay())
          d.setDate(d.getDate() + formState.serverWeeklyDay);
          d.setHours(t[0], t[1]);
          time=d
          break
        }
        else if(formState.serverCycle === "monthly"){
          time= new Date(d.getFullYear(),d.getMonth(),formState.serverMonthlydate, t[0], t[1])
        }
        break;
      }
      case "desktop":{
        if (formState.desktopCycle === "daily") {
          d.setHours(t[0], t[1]);
          time = d;
        }
        else if(formState.desktopCycle === "weekly"){
          d.setDate(d.getDate() + formState.desktopWeeklyDay);
          d.setHours(t[0], t[1]);
          time=d
        }
        else if(formState.desktopCycle === "monthly"){
          time= new Date(d.getFullYear(), d.getMonth(), formState.desktopMonthlydate,t[0], t[1])
        }
        break;
      }
      default:
        return 
    }
    console.log('time', time)
    return time;
  };

  const handleSave = async () => {
    /* try {
      const serverReq = {
        ReportId: formState.reportId,
        ReportType: "OfflineServer",
        Interval: formState.serverCycle,
        PartnerId: 1,
        DefaultTime: prepareTime("server"),
        NotificationEmailList: formState.NotificationEmails,
        ToggleEmailReport: formState.reportActive,
      };
      const desktopReq = {
        ReportId: formState.reportId,
        ReportType: "OfflineDesktop",
        Interval: formState.desktopCycle,
        PartnerId: 1,
        DefaultTime: prepareTime("desktop"),
        NotificationEmailList: formState.NotificationEmails,
        ToggleEmailReport: formState.reportActive,
      };
      const serverResp = await WebAPI.post("/Report/SaveScheduledReports", serverReq);
      console.log("resp", serverResp);
      const desktopResp = await WebAPI.post("/Report/SaveScheduledReports", desktopReq);
      console.log("desktop resp", desktopResp);
    } catch (err) {
      console.log("err", err);
    } */
  };

  return (
    <>
      <Container className="container-modal" fluid>
        <Row>
          <Col xs="8">
            <Card className="shadow">
              <CardBody>
                <Form role="form" onSubmit={() => {}}>
                  <FormGroup>
                    <AlertMessage />
                  </FormGroup>
                  <FormGroup>
                    
                  <Label for="NotificationEmails">
                      Report Type
                    </Label>
                    <Dropdown
                      isOpen={dropdownOpen}
                      toggle={toggle}
                      direction={'down'}
                      style={{marginLeft:15}}
                    >
                      <DropdownToggle caret>Select</DropdownToggle>
                      <DropdownMenu>
                        
                      </DropdownMenu>
                    </Dropdown>
                  </FormGroup>
                  <FormGroup>
                    <Label for="NotificationEmails">
                      Emails (comma seperated list)
                    </Label>
                    <Input
                      id="NotificationEmails"
                      value={formState.NotificationEmails}
                      invalid={invalidFormState.NotificationEmails}
                      onChange={handleFormValueChange}
                      type="text"
                    />
                    <FormFeedback>
                      Please enter a emails for reports
                    </FormFeedback>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      id="reportActive"
                      checked={formState.reportActive}
                      onChange={handleFormValueChange}
                      type="checkbox"
                    />
                    {""}
                    <Label for="reportActive" check>
                      Enabled
                    </Label>
                  </FormGroup>
                 
                  <Row style={{marginTop:10}}>
                    <Col xs={4}>
                      <FormGroup>
                        <Label for="desktopCycle">
                          Report cycle Interval
                        </Label>
                        <Input
                          id="desktopCycle"
                          value={formState.desktopCycle}
                          onChange={handleFormValueChange}
                          type="select"
                        >
                          {" "}
                          <option value={"daily"}>Daily</option>
                          <option value={"weekly"}>Weekly</option>
                          <option value={"monthly"}>Monthly</option>
                        </Input>
                        <FormFeedback>
                          Please select a cycle period
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                    {formState.desktopCycle === "weekly" && (
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="desktopWeeklyDay">Select a day</Label>
                          <Input
                            id="desktopWeeklyDay"
                            value={formState.desktopWeeklyDay}
                            onChange={handleFormValueChange}
                            type="select"
                          >
                            {" "}
                            <option value={1}>Monday</option>
                            <option value={2}>Tuesday</option>
                            <option value={3}>Wednesday</option>
                            <option value={4}>Thursday</option>
                            <option value={5}>Friday</option>
                            <option value={6}>Saturday</option>
                            <option value={0}>Sunday</option>
                          </Input>
                          <FormFeedback>
                            Please select a day
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                    {formState.desktopCycle === "monthly" && (
                      <Col xs={4}>
                        <FormGroup>
                          <Label for="desktopMonthlydate">Select a date</Label>
                          <Input
                            id="desktopMonthlydate"
                            value={formState.desktopMonthlydate}
                            onChange={handleFormValueChange}
                            type="select"
                          >
                            {dates.map((i) => (
                              <option value={i}>{i}</option>
                            ))}
                          </Input>
                          <FormFeedback>
                            Please select a date 
                          </FormFeedback>
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col xs={4}>
                      <FormGroup>
                        <Label for="reportTime">Time</Label>
                        <Input
                          type="time"
                          name="time"
                          id="reportTime"
                          value={formState.reportTime}
                          onChange={handleFormValueChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Button
                        className=" mt-4 common-btn"
                        type="submit"
                        isLoading={loading}
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EndpointSettingReport;
