
import Configuration from 'components/Configuration'
import { useParams, useHistory } from 'react-router-dom'

import { Container,Button } from 'reactstrap'

export default function CustomerSettings() {
  const { id } = useParams()
  const history = useHistory()

  return (
    <Container className="container-modal" fluid>
      <div className="common-headers">
        <Button
          className="common-btn-back mr-2"
          outline
          onClick={() => history.go(-1)}
        >
          <i className="fas fa-chevron-left" />
        </Button>
        Agent Default Settings
      </div>
      <Configuration id={id} endpointSettings={false} />
    </Container>
  )

}