import { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Col,
  Row,
  Card,
  Button,
  CardBody,
  Container
} from "reactstrap";
import { useDispatch } from 'react-redux'

import { alertActions } from 'redux/_actions/'
import WebAPI from 'services/WebAPI'

import CustomerForm from "./form";

const CustomerAdd = () => {
  let history = useHistory();
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(false)

  const saveCustomer = (customerData) => {
    if (loading)
      return
    setLoading(true)
    WebAPI
      .post('/Customers/Create/', customerData)
      .then(({ data: res }) => {
        if (res.status) {
          history.push('/customers/customer_details/' + res.data.CustomerId)
        }else {
          dispatch(alertActions.error(res.message))
        }
      })
      .catch(() => dispatch(alertActions.error("Connection error")))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            className="common-btn-back mr-2"
            outline
            onClick={() => history.go('-1')}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Add Customer
        </div>
        <Row>
          <Col xs="6">
            <Card className="shadow">
              <CardBody>
                <CustomerForm shareFormData={saveCustomer} loading={loading} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default CustomerAdd;