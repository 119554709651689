import React, { useEffect } from 'react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserHistory } from 'history';

import { IntercomProvider, useIntercom } from 'react-use-intercom';

import AdminLayout from 'layouts/Admin'
import AuthLayout from 'layouts/Auth'
import { alertActions, userActions } from './redux/_actions';
import RequireAuth from 'helpers/requireauth';
import TestPage from 'screens/test';
import Require2FA from 'helpers/Require2FA';


const INTERCOM_APP_ID = 'lf2jzawl';

const IntercomMask = ({ children }) => {
  const { boot, shutdown } = useIntercom()
  const user = useSelector(store => store.authentication.user)

  useEffect(() => {
    bootOrClose()
  }, [user])

  const bootOrClose = () => {
    if (user) {
      boot({
        appId: INTERCOM_APP_ID,
        email: user.email
      })
    } else {
      shutdown()
    }

  }

  return children
}

const IntercomWrapper = ({ children }) => {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <IntercomMask>{children}</IntercomMask>
    </IntercomProvider>
  )
}



const history = createBrowserHistory()

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    history.listen((location, action) => {
      dispatch(alertActions.clear());
    });
  }, [dispatch])

  useEffect(() => {
    userActions.getAndSetUser(dispatch)
    /*
    setInterval(() => {
      userActions.getAndSetUser(dispatch)
    }, 5 * 1000);
    */
  }, [dispatch])
  return (
    <IntercomWrapper>
      <Router history={history}>
        <Switch>
          <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          <Route path="/testing" render={(props) => <TestPage {...props} />} />
          <Route path="/" render={(props) => <RequireAuth><Require2FA><AdminLayout {...props} /></Require2FA></RequireAuth>} />
        </Switch>
      </Router>
    </IntercomWrapper>
  );
}

export { App };