import { useState, useEffect } from "react";
import { Button, Col, Container, Row, Popover } from "reactstrap";
import { useParams, useHistory, Link } from "react-router-dom";
import WebAPI from 'services/WebAPI'
import EndpointsTable from "./EndpointTable";


const EndpointListByCustomer = () => {

  const { id } = useParams();

  const [endPointWholeList, setEndPointList] = useState([]);
  const [loading, setLoading] = useState(false)
  const [customerList, setCustomerList] = useState([])

  useEffect(() => {
    loadEndpoints()
    loadCustomers()
  }, []);

  const loadEndpoints=()=>{
    setLoading(true)
    WebAPI
      .get('/Endpoints/ListFromCustomer/' + id)
      .then(({ data: res }) => {
        if (res.status) {
          setEndPointList(res.data)
        }
      })
      .catch((err)=>console.log('err', err))
      .finally(()=>setLoading(false))
  }

  const loadCustomers = () => {
    setCustomerList([]);
    WebAPI.get(`/Customers/List?active=true`)
      .then(({ data: res }) => {
        if (res.status) setCustomerList(res.data);
      })
      .catch((err)=>console.log('err', err))
  };

  return (
    <>
      <Container className="container-main" fluid>
        <div className="common-headers">Endpoints of Customer</div>
        <Row>
          <div className="col">
            <EndpointsTable
              reload={loadEndpoints}
              customerList={customerList}
              dashFilter={{ failedUpdates: "false" }}
              endpoints={endPointWholeList}
              loading={loading}
            />
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EndpointListByCustomer;