import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import { useDispatch } from 'react-redux'
import Button from 'components/Inputs/TButton'
import { alertActions } from 'redux/_actions/'
import AlertMessage from "components/Alert";
import WebAPI from 'services/WebAPI'


const UserDetails = () => {
  const [user, setUser] = useState({});

  let history = useHistory();
  const dispatch = useDispatch();

  const { id } = useParams()

  useEffect(() => {
    getUser(id)
  }, [id]);

  const getUser = (id) => {
    WebAPI
      .get(`/Users/GetDetails/${id}`)
      .then(({ data: res }) => {
        if (res.status)
          setUser(res.data)
        else {
          setUser({})
          dispatch(alertActions.error(res.message))
        }
      })
  }

  const getUserType = (user) => {
    if (!user.Customer && !user.Partner)
      return 'Administrator'
    if (!user.Customer)
      return 'Partner'
    return 'Customer'
  }
  const [locking, setLocking] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [mfaSwitching, setMFASwitching] = useState(false);
  const handleLock = () => {
    if (locking)
      return;
    setLocking(true)
    WebAPI
      .post(`/Users/ToggleLock/${id}`)
      .then(() => getUser(id))
      .finally(() => setLocking(false))
  }

  const handleDelete = () => {
    if (deleting)
      return;
    setDeleting(true)
    WebAPI
      .post(`/Users/Delete/${id}`)
      .then(({ data: res }) => {
        if(res.status)
          history.push('/users')
      })
      .finally(() => setDeleting(false))
  }

  const handleMFASwitch = () => {
    if (mfaSwitching)
      return;
    setMFASwitching(true)
    WebAPI
      .post(`/Users/DisableMFA/${id}`)
      .then(() => getUser(id))
      .finally(() => setMFASwitching(false))
  }

  return (
    <Container className="container-main" fluid>
      <div className="common-headers">
        <Button
          className="common-btn-back mr-2"
          outline
          onClick={() => history.push('/users')}
        >
          <i className="fas fa-chevron-left" />
        </Button>
        {user.Email}
      </div>
      <Row>
        <Col xs="6">
          <div className="sub-header">
            User Details
          </div>
        </Col>
        <Col xs="6">
          <Button
            className="edit-btn common-btn"
            onClick={() => history.push(`/users/edit_user/${id}`)}
          >
            Edit Details
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs="12" style={{ marginTop: '20px' }}>
          <Card style={{ padding: '10px' }}>
            <AlertMessage />
            <Row>
              <Col xs="3">
                <p className="card-sub-head">Email</p>
              </Col>
              <Col xs="6">
                <p className="card-sub-value">{user.Email}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <p className="card-sub-head">Type</p>
              </Col>
              <Col xs="6">
                <p className="card-sub-value">{getUserType(user)}</p>
              </Col>
            </Row>
            {getUserType(user) === "Partner" && (
              <Row>
                <Col xs="3">
                  <p className="card-sub-head">Partner Name</p>
                </Col>
                <Col xs="6">
                  <p className="card-sub-value">
                    {!user.Partner ? "" : user.Partner?.EntityName}
                  </p>
                </Col>
              </Row>
            )}

            {getUserType(user) === "Customer" && (
              <Row>
                <Col xs="3">
                  <p className="card-sub-head">Customer Name</p>
                </Col>
                <Col xs="6">
                  <p className="card-sub-value">
                    {user.Customer?.FirstName} {user.Customer?.LastName}
                  </p>
                </Col>
              </Row>
            )}

            <Row>
              <Col xs="3">
                <p className="card-sub-head">Two Factor Enabled</p>
              </Col>
              <Col xs="6">
                <p className="card-sub-value">{user.TwoFactorEnabled ? "Yes" : "No"}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <p className="card-sub-head">Access Failed Count</p>
              </Col>
              <Col xs="6">
                <p className="card-sub-value">{user.AccessFailedCount}</p>
              </Col>
            </Row>
            <Row>
              <Col xs="3">
                <p className="card-sub-head">Lockout Status</p>
              </Col>
              <Col xs="6">
                <p className="card-sub-value">{!user.LockoutEnabled ? "No" : "Locked"}</p>
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button
                outline
                className="common-btn-outlined"
                color="primary"
                type="button"
                onClick={() => handleLock()}
                isLoading= {locking}
              >
                {user.LockoutEnabled ? "Unlock" : 'Lock'}
              </Button>
              <Button
                outline
                className="common-btn-outlined"
                color="primary"
                type="button"
                onClick={()=>handleDelete()}
                isLoading= {deleting}
              >
                Delete
              </Button>
              <Button
                outline
                className="common-btn-outlined"
                color="primary"
                type="button"
                onClick={()=>handleMFASwitch()}
                isLoading= {mfaSwitching}
              >
                Remove MFA
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserDetails;