import { useState, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit";

import _ from "lodash";


const { SearchBar } = Search;

export default function ReportingTable(props) {

  const [rowData, setRowData] = useState([]);
  const { ExportCSVButton } = CSVExport;

  useEffect(() => {
    const { endpoints } = props;
    setRowData(endpoints);
  }, [props]);


  const columns = [
    {
      dataField: "DeviceId",
      text: "ID",
      sort: true,
      searchable: true,
      headerStyle: { width: "70px", padding: "15px 5px" },
    },
    {
      dataField: "LastUpdate",
      text: "Last Updated",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "TrayIcon",
      text: "Tray Icon",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
    {
      dataField: "UpdateService",
      text: "Service Update",
      sort: true,
      classes: "common-table-column-style",
      searchable: true,
      headerStyle: { padding: "15px 5px" },
    },
  ];

  const paginationOptions = {
    custom: true,
    withFirstAndLast: true,
    totalSize: rowData?.length,
  };

  const { loading } = props;

  return (
    <ToolkitProvider
      keyField="DeviceId"
      data={rowData || []}
      columns={columns}
      search
      remote
      exportCSV={{
        fileName: "service_status.csv",
      }}
    >
      {(props) => (
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <Row style={{ marginBottom: 10 }}>
                <Col
                  xs="4"
                  className="local-text"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  Entries per page :
                  <SizePerPageDropdownStandalone
                    className="common-page-size-selector ml-2"
                    {...paginationProps}
                  />
                </Col>
                <Col xs="8" className="d-flex justify-content-end">
                  <Row>
                    <SearchBar
                      className="table-search-input"
                      {...props.searchProps}
                    />
                    <ExportCSVButton {...props.csvProps}>
                      Export as CSV
                    </ExportCSVButton>
                  </Row>
                </Col>
              </Row>
              <BootstrapTable
                hover
                bordered={false}
                noDataIndication={
                  loading ? <Spinner type="grow" color="primary" /> : "No Data"
                }
                headerWrapperClasses="table-header-light"
                bodyClasses="clickable-table-row"
                {...props.baseProps}
                {...paginationTableProps}
                rowStyle={
                  {
                    //backgroundColor: '#fff',
                  }
                }
              />
              <Row className="mt-2">
                <Col>
                  <PaginationTotalStandalone {...paginationProps} />
                </Col>
                <Col>
                  <PaginationListStandalone {...paginationProps} />
                </Col>
              </Row>
            </div>
          )}
        </PaginationProvider>
      )}
    </ToolkitProvider>
  );
}
