import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { alertActions } from 'redux/_actions/'
import WebAPI from 'services/WebAPI'
import { 
  Col, 
  Row, 
  Card, 
  Button,
  CardBody, 
  Container
} from "reactstrap";
import UserForm from "./form";

const UserAdd = () => {
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const saveUser = (data) => {

    if (loading)
      return
    setLoading(true)
    if(data.PartnerId === null)
      delete(data.PartnerId)
    if(data.CustomerId === null)
      delete(data.CustomerId)
    WebAPI
      .post(`/Users/Create`, data)
      .then(({ data: res }) => {
        if (res.status) {
          history.push('/users/user_details/' + res.data.Id)
        } else {
          dispatch(alertActions.error(res.message))
        }
      })
      .finally(() => setLoading(false))
  }


  return(
    <>
      <Container className="container-modal" fluid>
        <div className="common-headers">
          <Button
            outline
            className="common-btn-back mr-2"
            onClick={()=>history.push('/users')}
          >
            <i className="fas fa-chevron-left" />
          </Button>
          Add User
        </div>
        <Row>
          <Col xs="6">
            <Card className="shadow">
              <CardBody>
                <UserForm loading={loading} shareFormData={saveUser} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
};

export default UserAdd;